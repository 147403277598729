import {ReplaySubject} from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return new ReplaySubject(1);
  }

  app.factory('bankidBtn', controller);
})();
