import user from '../../app/service/domain/user.js';
import popup from '../../app/service/domain/popup.js';
import state from '../../app/service/domain/state.js';
(function () {
    'use strict';

    const directive = {
        name: 'setInitialLimits'
    };

    controller.$inject = [ 'user' , 'popup', 'state'];

    function controller(_user, _popup, _state) {
        function link(scope) {
            scope.$on('$stateChangeStart', (event, toState) => {
                if (_user.status &&
                    !_user.profile.is_fdb_made &&
                    _user.profile.requiredFields &&
                    _user.profile.requiredFields.length &&
                    toState.url === '/missed-data'
                ) {
                    _state.goto('initialLimits');
                    event.preventDefault();
                }
            });
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );
})();
