(function () {
  'use strict';

  const directive = { name: 'setStep' };

  controller.$inject = [];

  function controller() {

    function link( scope ){
      scope.formField = {
        name: { disabled: Boolean(scope.person.name) },
        surname: { disabled: Boolean(scope.person.surname) },
        email: { disabled: Boolean(scope.person.email) },
        phone: { disabled: Boolean(scope.person.phone) },
        city: { disabled: Boolean(scope.person.city) },
        postcode: { disabled: Boolean(scope.person.postcode) },
        address: { disabled: Boolean(scope.person.address) },
        gender: { disabled: Boolean(scope.person.gender) },
        birthday: { disabled: Boolean(scope.person.birthday) },
      };

      scope.setStep = (step) => {
        scope.registrationStep = step;
      };
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );

})();

