import '../../service/helper.js';
import '../../service/domain/account.js';
import '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'swedenProfileRealityCheck' };

  controller.$inject = ['$scope', 'helper', 'account', 'user'];

  function controller($scope, _helper, _account, _user) {
    $scope.editMode = false;
    $scope.preloader = false;
    $scope.inputShow = _user.profile.reality_check.value.substr(1) > 0;

    $scope.formData = {
      reality_check: parseInt(_user.profile.reality_check.value.substr(1)),
    };

    $scope.resetChange = () => {
      $scope.editMode = false;
      $scope.formData.reality_check = parseInt(_user.profile.reality_check.value.substr(1));
    };

    $scope.submit = _helper.form.validator((form) => {
      const o = {
        reality_check: '1' + parseInt($scope.formData.reality_check),
      };

      $scope.preloader = true;
      _account
        .svRealitySettings(o)
        .then((a) => {
          $scope.editMode = false;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
