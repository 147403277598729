import config from '../../app/service/configs/config.js';


(function () {
    const directive = { name: 'splideSlider' };

    controller.$inject = ['config', '$compile'];
    const IGNORE = [
      'top-rated',
      'live-casino',
      'arcade',
      'game-of-the-week'
    ];
    function controller(_config, $compile) {

        function link(scope, element, attrs) {
            if (typeof Splide !== 'function') return;

            const o = scope.$eval(attrs[directive.name]);
            const instanceKey = o.instance || 'splide';
            const collectionKey = o.collectionKey || 'collection';
            const config = o.config;
            const slideClone = element[0].querySelector('.splide__slide').cloneNode(true);
            const slidesWrapper = element[0].querySelector('.splide__list');
            const className = o.className || '.splide';

            scope.$watchCollection(collectionKey, (collection) => {
                if (collection && collection.length) {
                    renewSlider(collection.filter(el => !IGNORE.includes(el.name)));
                }
            });

            function renewSlider(collection) {
                if (scope[instanceKey]) destroySlider();

                buildSlider(collection);
            }

            function buildSlider(collection) {
                repeatSlides(collection);

                scope[instanceKey] = initSwiper(collection);
            }

            function initSwiper(collection) {

                const splide = new Splide(className, config);

                splide.on('mounted', function () {
                    bindCollectionToSlides(collection.filter(el => el.name !== 'top-rated'), splide);
                } );

                splide.mount();

                return splide;
            }

            function bindCollectionToSlides(collection, splide) {
                splide.Components.Slides.forEach(item => {
                    const indexFromCollection = item.isClone ? item.slideIndex : item.index;
                    const slideScope = scope.$new();
                    slideScope.slide = collection[indexFromCollection];
                    $compile(item.slide)(slideScope);
                });
            }

            function repeatSlides(collection) {
                const repeatedSlides = document.createDocumentFragment();

                collection.forEach(() => {
                    repeatedSlides.appendChild(slideClone.cloneNode(true));
                });

                slidesWrapper.innerHTML = '';
                slidesWrapper.appendChild(repeatedSlides);

                // slidesWrapper.replaceChildren(repeatedSlides);
            }

            function destroySlider() {
                scope[instanceKey].destroy(true);
                scope[instanceKey] = null;
            }

            scope.$on('$destroy', destroySlider);
        }

        return {
            restrict: 'A',
            link,
        };
    }

    app.directive(directive.name, controller);
})();
