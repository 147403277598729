(function() {
    'use strict';

    const component = { name: 'swedenInitialLimits' };

    controller.$inject = [ '$scope', 'account', 'helper', 'state', '$element'];

    function controller( $scope, _account, _helper, _state, $element ){
        const dayInput = $element[0].querySelector('#day');
        const weekInput = $element[0].querySelector('#week');
        const monthInput = $element[0].querySelector('#month');
        const updateFields = () => {
            if ($scope.formData.week.deposit.amount && $scope.formData.day.deposit.amount > $scope.formData.week.deposit.amount) {
                $scope.formData.day.deposit.amount = $scope.formData.week.deposit.amount;
            }

            if($scope.formData.month.deposit.amount && $scope.formData.day.deposit.amount > $scope.formData.month.deposit.amount) {
                $scope.formData.day.deposit.amount = $scope.formData.month.deposit.amount;
            }

            if($scope.formData.month.deposit.amount && $scope.formData.week.deposit.amount > $scope.formData.month.deposit.amount) {
                $scope.formData.week.deposit.amount = $scope.formData.month.deposit.amount;
            }
        }

        $scope.preloader = false;

        $scope.formData = {
            day: {
                deposit: {
                    amount: '',
                    min: 100,
                    isFirstEdited: false,
                    exceeds: ''
                },
            },
            week: {
                deposit: {
                    amount: '',
                    min: 100,
                    isFirstEdited: false,
                    exceeds: ''
                },
            },
            month: {
                deposit: {
                    amount: '',
                    min: 100,
                    isFirstEdited: false,
                    exceeds: ''
                },
            },
        };

        $scope.suggestion = {
            day: {
                deposit: { amount: '' },
            },
            week: {
                deposit: { amount: '' },
            },
            month: {
                deposit: { amount: '' },
            },
        };

        $scope.$watch('formData.day.deposit.amount', (data) => {
            // watch week input from day input -- check if day value exceeds week value
            if (data && $scope.formData.week.deposit.amount) {
                $scope.formData.day.deposit.exceeds = data > $scope.formData.week.deposit.amount ? 'weekly' : '';
            }

            // watch month input from day input -- check if day value exceeds month value
            if (data && $scope.formData.month.deposit.amount) {
                $scope.formData.day.deposit.exceeds = data > $scope.formData.month.deposit.amount ? 'monthly' : '';
            }

            // show suggestion limit to user only if user change the first edited input
            if (!$scope.formData.week.deposit.isFirstEdited && !$scope.formData.month.deposit.isFirstEdited) {
                if (data) {
                    $scope.formData.day.deposit.isFirstEdited = true;
                }
                // calc weekly limit suggestion on the basis of daily limit
                $scope.suggestion.week.deposit.amount = $scope.formData.day.deposit.amount * 7;
                // calc monthly limit suggestion on the basis of daily limit
                $scope.suggestion.month.deposit.amount = $scope.formData.day.deposit.amount * 30;
            }
        });

        $scope.$watch('formData.week.deposit.amount', (data) => {
            // watch day input from week input
            if (data && $scope.formData.day.deposit.amount) {
                $scope.formData.day.deposit.exceeds = data < $scope.formData.day.deposit.amount ? 'weekly' : '';
            }

            // watch month input from week input
            if (data && $scope.formData.month.deposit.amount) {
                $scope.formData.week.deposit.exceeds = data > $scope.formData.month.deposit.amount ? 'monthly' : '';
            }

            // show suggestion limit to user only if user change the first edited input
            if (!$scope.formData.day.deposit.isFirstEdited && !$scope.formData.month.deposit.isFirstEdited) {
                if (data) {
                    $scope.formData.week.deposit.isFirstEdited = true;
                }
                // calc daily limit suggestion on the basis of weekly limit
                $scope.suggestion.day.deposit.amount = $scope.formData.week.deposit.amount;
                // calc monthly limit suggestion on the basis of weekly limit
                $scope.suggestion.month.deposit.amount = $scope.formData.week.deposit.amount * 4;
            }
        });

        $scope.$watch('formData.month.deposit.amount', (data) => {
            // watch day input from month input
            if (data && $scope.formData.day.deposit.amount) {
                $scope.formData.day.deposit.exceeds = data < $scope.formData.day.deposit.amount ? 'monthly' : '';
            }

            // watch week input from month input
            if (data && $scope.formData.week.deposit.amount) {
                $scope.formData.week.deposit.exceeds = data < $scope.formData.week.deposit.amount ? 'monthly' : '';
            }

            // show suggestion limit to user only if user change the first edited input
            if (!$scope.formData.day.deposit.isFirstEdited && !$scope.formData.week.deposit.isFirstEdited) {
                if (data) {
                    $scope.formData.month.deposit.isFirstEdited = true;
                }
                // calc daily limit suggestion on the basis of monthly limit
                $scope.suggestion.day.deposit.amount = $scope.formData.month.deposit.amount;
                // calc weekly limit suggestion on the basis of monthly limit
                $scope.suggestion.week.deposit.amount = $scope.formData.month.deposit.amount;
            }
        });

        $scope.submit = _helper.form.validator( form => {
            $scope.preloader = true;
            const params = [
                { type: 'day', group: 'deposit', amount: $scope.formData.day.deposit.amount },
                { type: 'week', group: 'deposit', amount: $scope.formData.week.deposit.amount },
                { type: 'month', group: 'deposit', amount: $scope.formData.month.deposit.amount }
            ];
            _account
                .multiLimits( params )
                .then( a => { _state.goback() } )
                .catch( e => { } )
                .finally( () => { $scope.preloader = false; } )
        } );

        dayInput.addEventListener('blur', updateFields);
        weekInput.addEventListener('blur', updateFields);
        monthInput.addEventListener('blur', updateFields);

        $scope.$on('$destroy', () => {
            dayInput.removeEventListener('blur', updateFields);
            weekInput.removeEventListener('blur', updateFields);
            monthInput.removeEventListener('blur', updateFields);
        })
    }

    app.component( component.name, {
        controller,
        template: app.getTU( component.name )
    });
})();

