import '../../service/domain/account.js';
import '../../service/helper.js';
import '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'swedenProfileWagerLimitDay' };
  const group = 'wager';
  const type = 'day';

  controller.$inject = ['$scope', 'account', 'helper', 'user'];

  function controller($scope, _account, _helper, _user) {
    $scope.editMode = false;
    $scope.preloader = false;
    $scope.formData = {
      amount: _user.profile.limits?.[group]?.[type].amount || null,
    };
    $scope.limit = _user.profile.limits?.[group]?.[type] || null;

    $scope.cancel = () => {
      $scope.editMode = false;
      $scope.formData.amount = _user.profile.limits?.[group]?.[type].amount || null;
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      const o = {
        group,
        type,
        amount: parseInt($scope.formData.amount),
      };
      _account
        .limits(o)
        .then(({ data: { result } }) => {
          $scope.formData.amount = result.limits[group][type].amount;
          $scope.limit = result.limits[group][type];
          $scope.editMode = false;
        })
        .catch(() => {
          $scope.formData.amount = _user.profile.limits?.[group]?.[type].amount || null;
          $scope.limit = _user.profile.limits?.[group]?.[type] || null;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
