(function () {
  'use strict';

  const directive = {
    name: 'loopBanners'
  };

  function controller(){
    const delay = 4000;
    const pause = 20000;

    function link( scope, element, attrs ){
      const start = () => {
        scope.interval = setInterval(() => {
          scope.filter = !scope.filter * 1;
        }, delay);
      }

      const stop = () => {
        clearInterval(scope.interval);
      }

      element[0].addEventListener('mouseenter', stop);
      element[0].addEventListener('mouseleave', start);

      start();

      scope.pauseLoop = () => {
        stop();
        clearTimeout(scope.timeout);
        scope.timeout = setTimeout(() => {
          start();
        }, pause);
      };

      scope.$on('$destroy', () => {
        clearInterval(scope.interval);
        clearTimeout(scope.timeout);

        element[0].removeEventListener('mouseenter', stop);
        element[0].removeEventListener('mouseleave', start);
      });
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );
})();
