(function () {
  'use strict';

  const component = { name: 'swedenRealityCheckModal' };

  controller.$inject = ['$scope'];

  function controller($scope) {}

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
