(function () {
  'use strict';

  controller.$inject = [
    '$window',
    '$scope',
    'paysystem'
  ];

  const cache = {}

  function controller(
    $window,
    $scope,
    _paysystem
  ) {
    const pgw = $window.pgw_widget;

    const PGW_URL = {
      ORIGIN: 'https://app.pgway.com/',
      PATHNAME: 'api/widget/publicPaymentMethods'
    }
    $scope.collection = [];
    $scope.type = '';

    async function fetchPayMethods (params = {}) {
      const defaults = {
        country: 'SE',
        currency: 'SEK',
        type: 'deposit',
        merchantKey: 'a5bf52ce-8739-450c-a344-e611251440af'
      }

      params = Object.assign({}, defaults, params)

      const qParams = JSON.stringify(params)

      if(cache[qParams]) {
        $scope.collection = cache[qParams]

        return
      }

      const { data } = await pgw.getPaymentMethods(params);

      $scope.collection = data.map(
        (item) => Object.assign(
          item, {
            purpose: params.type
          }
        )
      )

      cache[qParams] = $scope.collection
    }

    this.$onChanges = () => {
      const config = {};

      if (this.type) {
        config.type = this.type;
        $scope.type = this.type;
      }
      if (this.currency) config.currency = this.currency;
      if (this.country) config.country = this.country;
      if (this.category && config.type === 'deposit') {
        config.payment_category = this.category;
      }
      if (config.country && !config.currency) {
        return;
      }

      fetchPayMethods(config)

      $scope.preloader = true;
    };
  }

  new Controller('pgwBox', controller, {
    type: '<',
    currency: '<',
    country: '<',
    category: '<',
  });
})();

(function () {
  'use strict';
  new Batman('pgwBankingBox1', 'pgwBox');
})();
