import '../../service/domain/user.js';
import '../../service/domain/state.js';
import '../../service/domain/popup.js';
import '../../service/domain/account.js';
(function () {
  'use strict';

  const component = { name: 'swedenAccountStatusPopup' };

  controller.$inject = ['$scope', 'user', 'state', 'popup', 'account'];

  function controller($scope, _user, _state, _popup, _account) {
    const sessionLimit = _user.profile.session_limit.value || '';
    const realityCheck = _user.profile.reality_check.value || '';

    $scope.account = {
      depositLimitDay: _user.profile.limits.deposit?.day.amount || '',
      depositLimitWeek: _user.profile.limits.deposit?.week.amount || '',
      depositLimitMonth: _user.profile.limits.deposit?.month.amount || '',
      lossesLimit: _user.profile.limits.losses?.day.amount || '',
      wagerLimit: _user.profile.limits.wager?.day.amount || '',

      loginTimeDayLimit: _user.profile.limits.login_time?.day.amount || '',
      loginTimeMonthLimit: _user.profile.limits.login_time?.month.amount || '',
      loginTimeWeekLimit: _user.profile.limits.login_time?.week.amount || '',

      sessionLength: {
        type: sessionLimit[0] || '',
        value: sessionLimit.substr(1),
      },
      realityCheck: {
        type: realityCheck[0] || '',
        value: realityCheck.substr(1),
      },
      profit: 0,
    };

    _account
      .yearReport()
      .then(({ result }) => {
        $scope.account.profit = result.year_report.result;
      })
      .catch((e) => {});

    $scope.resolve = () => {
      _popup.close();
      _state.goto('depositLimits');
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
