(function () {
    'use strict';

    const directive = { name: 'nextPlayground' };

    function controller(){
        function link( scope, element ){

            element[0].addEventListener( 'click' , () => {
                const currentCity = scope.collection.find(s => s.status === 'in_progress');
                const currentCityIndex = scope.collection.findIndex(s => s.status === 'in_progress');
                const minCostPlayground = currentCity.list.filter(c => c.status === 'closed').sort((a, b) => a.price - b.price)[0];
                
                if (scope.city.alias !== currentCity.alias && currentCityIndex) {
                    scope.openCity(currentCityIndex);
                }

                scope.playground = {};
                scope.open(minCostPlayground.alias);
            })
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();