import '../../../service/domain/modal.js';
import '../../../service/helper.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/bankid/bankid.paynplay.js';
import '../../../service/domain/system.js';
import '../../../service/configs/registration.js';

const componentName = 'lindaBankidRegistrationModal';

class BankidRegistrationModal {
  static $inject = ['$scope', 'modal', 'helper', 'contentBuffer', 'bankidPaynPlay', 'system', 'registration'];

  constructor($scope, _modal, _helper, _contentBuffer, _bankidPaynPlay, _system, _registration) {
    const vm = this;
    const params = _contentBuffer.read('modal');

    $scope.hide_bonuses = params.is_registration_bonuses_shown;
    $scope.person = params.profile;
    $scope.preloader = false;

    $scope.formData = {
      name: $scope.person.name,
      surname: $scope.person.surname,
      email: $scope.person.email,
      phone: $scope.person.phone,
      city: $scope.person.city || '',
      postcode: $scope.person.postcode,
      address: $scope.person.address,
      gender: $scope.person.gender || 'M',
      pep: false,
      gdpr_data: false,
      accept: false,
      gdpr_marketing: false,
      birthday: $scope.person.birthday,
      enable_trigger_codes: true,
      order_id: params.token
    };

    vm.$onInit = () => {
      preparePhoneField();
      _registration.addPromotionsToScope($scope);
      _registration.addAvatarsToScope($scope);
    };

    function submitVerificationData() {
      $scope.preloader = true;

      if ($scope.formData.enable_trigger_codes !== true || $scope.hide_bonuses) {
        delete $scope.formData.trigger_codes;
      }
      delete $scope.formData.enable_trigger_codes;

      _bankidPaynPlay
        .auth($scope.formData)
        .then(() => {
          _modal.answer = true;
        })
        .catch(() => {
          if ($scope.formData.trigger_codes) {
            $scope.formData.enable_trigger_codes = true;
          }
        })
        .finally(() => {
          $scope.preloader = false;
        });
    }

    $scope.submit = _helper.form.validator(() => {
      submitVerificationData();
    });

    const preparePhoneField = () => {
      $scope.preloader = true;

      _system
        .countries()
        .then((answer) => {
          const country = answer.result.filter((item) => item.code === answer.default)[0] || {};

          if (!$scope.formData.phone && typeof country.prefix === 'string') {
            $scope.formData.phone = `+${parseInt(country.prefix.replace(/\-/g, ''), 10)}`;
          }
        })
        .catch((error) => console.error(error))
        .finally(() => ($scope.preloader = false));
    };
  }
}

app.component(componentName, {
  controller: BankidRegistrationModal,
  binding: {},
  template: app.getTU(componentName),
});

