const $_PGW = {
  enabled: true,
  layoutMode: 'row',
  hideCloseButton: true,
  cssVars: {
    "--pgw-font-family": "Roboto",
    "--pgw-background-primary": "lightgray 0% 0% / 50px 50px repeat,#F2F2F2",
    "--pgw-surface-primary": "#FFFFFF",
    "--pgw-surface-secondary": "#FFFFFF",
    "--pgw-surface-secondaryHover": "#EBEBEB",
    "--pgw-surface-accent": "#FFF6F2",
    "--pgw-surface-accentHover": "#FDE1D5",
    "--pgw-surface-buttonPrimary": "linear-gradient(180deg, #FF8960 0%, #E7531F 100%)",
    "--pgw-surface-buttonPrimaryHover": "linear-gradient(180deg, #F77A4E 0%, #DF4A16 100%)",
    "--pgw-surface-buttonPrimaryPress": "linear-gradient(180deg, #F26F42 0%, #D2400C 100%)",
    "--pgw-surface-buttonPrimaryDisabled": "linear-gradient(180deg, #FFB28E 0%, #F89566 100%)",
    "--pgw-surface-buttonSecondary": "#313131",
    "--pgw-surface-buttonSecondaryHover": "#4D4949",
    "--pgw-surface-buttonSecondaryPress": "#C3CFE8",
    "--pgw-surface-buttonSecondaryDisabled": "#060505",
    "--pgw-surface-buttonTertiary": "#DCDCDC",
    "--pgw-surface-buttonTertiaryHover": "#CECECE",
    "--pgw-surface-buttonTertiaryPress": "#C4C4C4",
    "--pgw-surface-buttonTertiaryDisabled": "#F2F2F2",
    "--pgw-surface-inputPrimary": "#FFFFFF",
    "--pgw-surface-inputSecondary": "#F2F2F2",
    "--pgw-surface-inputTertiary": "#EBEBEB",
    "--pgw-surface-tab": "#AAAAAA",
    "--pgw-surface-tabHover": "#E1A8FE",
    "--pgw-surface-tabPress": "#460069",
    "--pgw-surface-negative": "#D7000D",
    "--pgw-surface-positiveAlpha": "#00AE311A",
    "--pgw-surface-skeleton": "#E5E5E5",
    "--pgw-surface-userchoiсePrimary": "linear-gradient(180deg, #7212A2 0%, #460069 100%);",
    "--pgw-surface-userchoiсeSecondary": "#03F295",
    "--pgw-surface-userchoiсeTertiary": "#FFFFFF",
    "--pgw-surface-userchoiсeIndicatorPrimary": "#FFFFFF",
    "--pgw-surface-userchoiсeIndicatorPrimaryHover": "#F2F2F2",
    "--pgw-surface-userchoiсeIndicatorSecondary": "#460069",
    "--pgw-surface-userchoiсeIndicatorSecondaryHover": "#710AA5",
    "--pgw-text-primary": "#111111",
    "--pgw-text-secondary": "#444444",
    "--pgw-text-tertiary": "#CFCFCF",
    "--pgw-text-buttonPrimary": "#FFFFFF",
    "--pgw-text-buttonPrimaryDisabled": "#EBEBEB",
    "--pgw-text-buttonSecondary": "#FFFFFF",
    "--pgw-text-buttonSecondaryDisabled": "#EBEBEB",
    "--pgw-text-positive": "#00AE31",
    "--pgw-text-warning": "#F9AB37",
    "--pgw-text-negative": "#D7000D",
    "--pgw-text-constant": "#FFFFFF",
    "--pgw-icon-primary": "#111111",
    "--pgw-icon-secondary": "#444444",
    "--pgw-icon-tertiary": "#CFCFCF",
    "--pgw-icon-buttonPrimary": "#FFFFFF",
    "--pgw-icon-buttonPrimaryDisabled": "#FFFFFF",
    "--pgw-icon-buttonSecondary": "#FFFFFF",
    "--pgw-icon-positive": "#00AE31",
    "--pgw-icon-warning": "#F9AB37",
    "--pgw-icon-negative": "#D7000D",
    "--pgw-stroke-primary": "#E6E6E6",
    "--pgw-stroke-secondary": "#E6E6E6",
    "--pgw-stroke-inputPrimary": "#EBEBEB",
    "--pgw-stroke-inputSecondary": "#E1E1E1",
    "--pgw-stroke-accent": "#FF5300",
    "--pgw-stroke-accentHover": "#DF4A16",
    "--pgw-stroke-positive": "#00E03F",
    "--pgw-stroke-positiveAlpha": "#00AE314D",
    "--pgw-stroke-warning": "#F9AB37",
    "--pgw-stroke-negative": "#FF0312",
    "--pgw-stroke-userchoiсe": "#460069",
    "--pgw-stroke-userchoiсeHover": "#710AA5",
    "--pgw-divider-primary": "#DBDBDB",
    "--pgw-border-radius-s": "0px",
    "--pgw-border-radius-m": "0px",
    "--pgw-border-radius-l": "0px",
    "--pgw-border-radius-xl": "4px",
  },
};

export { $_PGW };

