import '../../service/translations.js';
import '../../service/configs/config.js';
import '../../service/rx/bankidBtn.js';

class PopupBankidDirective {
  static $inject = ['$document', 'translations', 'config', 'bankidBtn'];
  constructor($document, translations, config, bankidBtn$) {
    this.$document = $document;
    this.translations = translations;
    this.config = config;
    this.bankidBtn$ = bankidBtn$;
  }
  link() {
    this.bankidBtn$.next(true);
  }
}

app.directive('popupBankid', () => {
  return {
    restrict: 'A',
    controller: PopupBankidDirective,
    link: function (scope, element, attrs, ctrl) {
      ctrl.link(scope, element, attrs, ctrl);
    },
  };
});
