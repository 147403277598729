const router = [
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk ></linda-app>'
  },
  {
    name: 'app.root.l1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>'
  },
  {
    name:'app.root.l1.home',
    url:'/',
    template: '<linda-homepage scroll-to-top></linda-homepage>',
    params: {
      page: 'home'
    }
  },
  {
    name: 'app.root.l4',
    abstract: true,
    template: '<linda-view-layer-four></linda-view-layer-four>'
  },
  {
    name: 'app.root.fastdeposit',
    url: '/fast-deposit',
    template: '<linda-login-popup go-to-homepage-after-login />',
    params: {
      type: 'page'
    }
  },
  {
    name: 'app.root.l1.sportpage',
    abstract:true,
    template: '<linda-sport-page scroll-to-top></linda-sport-page>'
  },
  {
    name: 'app.root.l1.sportpage.home',
    url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids&page',
    template: '',
    params: {
      page: 'overview'
    }
  },
  {
    name: 'app.root.l1.sportpage.live',
    url: '/live?sportids&catids&champids&tabs&eventid&betslipoddids&page',
    template: '',
    params: {
      page: 'live'
    }
  },
  
  // {
  //     name: 'app.root.l1.sportpage.toto',
  //     url: '/toto',
  //     template: '',
  //     params: {
  //         page: 'toto'
  //     }
  // },
  // {
  //     name: 'app.root.l1.livecasino',
  //     url: '/casino/live-casino',
  //     template: '<batman-game-hall1 scroll-to-top></batman-game-hall1>',
  //     params: {
  //         name: 'live-casino',
  //         page: 'categories',
  //         theme: 'casino'
  //     }
  // },
  //-LIVE-CASINO-//
  {
    name: 'app.root.l1.c1',
    abstract: true,
    template: '<batman-game-hall3></batman-game-hall3>',
    params: {
      theme: 'live-casino',
      page: 'categories'
    }
  },
  {
    name: 'app.root.l1.c1.livecasino',
    url: '/live-casino',
    template: '<batman-game-hall4 scroll-to-top></batman-game-hall4>',
    params: {
      name: 'live-casino',
      page: 'categories',
    }
  },
  {
    name: 'app.root.l1.c1.category_live',
    url: '/live-casino/:name',
    template: '<batman-game-hall5 scroll-to-top></batman-game-hall5>',
    params: {
      page: 'categories'
    }
  },
  //-CASINO-//
  {
    name: 'app.root.l1.c',
    abstract: true,
    template: '<ui-view></ui-view>',
    params: {
      theme: 'casino',
      page: 'categories'
    }
  },
  {
    name: 'app.root.l1.c.casino',
    url: '/casino',
    template: '<linda-casino-section scroll-to-top></linda-casino-section>'
  },
  {
    name: 'app.root.l1.c.category',
    url: '/casino/:name',
    template: '<batman-game-hall1 scroll-to-top></batman-game-hall1>',
    params: {
      page: 'categories'
    }
  },
  {
    name: 'app.root.l1.c.provider',
    url: '/casino-providers/:name',
    template: '<batman-game-hall2 scroll-to-top></batman-game-hall2>',
    params: {
      page: 'provider'
    }
  },
  {
    name: 'app.root.l1.c.favourite',
    url: '/games/my-games',
    template: '<batman-game-box-favourites6 scroll-to-top></batman-game-box-favourites6>',
  },
  {
    name: 'app.root.l1.c.continue',
    url: '/games/continue-playing',
    template: '<batman-recently-played-list6 scroll-to-top></batman-recently-played-list6>',
  },
  {
    name: 'app.root.l1.promo',
    abstract: true,
    template: '<ui-view></ui-view>'
  },
  {
    name: 'app.root.l1.promo.list',
    url:'/promotion',
    template: '<betinia-promo-list scroll-to-top to-top-on-state-change></betinia-promo-list>'
  },
  {
    name: 'app.root.l1.promo.page',
    url: '/promotion/:name',
    template: '<linda-promo-page scroll-to-top></linda-promo-page>',
  },
  {
    name: 'app.root.l1.payments',
    url: '/payments',
    template: '<batman-banking-page1 scroll-to-top></batman-banking-page1>',
    params: {
      headerClass: 'has-btn-outline-full',
    }
  },
  // {
  //   name: 'app.root.l1.guide',
  //   url: '/guide',
  //   template: '<linda-view-layer-three scroll-to-top></linda-view-layer-three>'
  // },

  // INFO PAGES
  {
    name: 'app.root.l1.info',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>'
  },
  {
    name: 'app.root.l1.info.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top></linda-sitemap>',
    params: {
      name: 'sitemap',
      theme: 'violet'
    }
  },
  {
    name: 'app.root.l1.info.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
      theme: 'violet'
    }
  },
  {
    name: 'app.root.l1.info.cookie-policy',
    url: '/cookies-policy',
    template: '<linda-info-page class="info-page" scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
      theme: 'violet'
    },
  },
  {
    name: 'app.root.l1.info.about',
    url: '/about-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
      theme: 'violet'
    }
  },
  {
    name: 'app.root.l1.info.contact',
    url: '/contact-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
      theme: 'violet'
    }
  },
  {
    name: 'app.root.l1.info.terms',
    url: '/rules',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
      theme: 'violet'
    }
  },
  {
    name: 'app.root.l1.info.faq',
    url: '/faq',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
      theme: 'violet'
    }
  },
  {
    name: 'app.root.l1.info.complaint',
    url: '/complaint',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'complaint',
      theme: 'violet'
    }
  },
  {
    name: 'app.root.l1.info.responsible-gaming',
    url: '/responsible-gaming',
    template: '<batman-info-page3 scroll-to-top></batman-info-page3>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
      theme: 'violet'
    }
  },
  //UI ELEMENTS
  {
    name: 'app.root.l1.ui2',
    url: '/ui2',
    template: '<batman-ui-wrapper2></batman-ui-wrapper2>'
  },
  {
    name: 'app.root.l1.ui3',
    url: '/ui3',
    template: '<batman-ui-wrapper3></batman-ui-wrapper3>'
  },
  {
    name: 'app.root.l1.ui4',
    url: '/ui4',
    template: '<batman-ui-wrapper4></batman-ui-wrapper4>'
  },
  // ---- TEST LOGIN
  {
    name: 'app.root.l1.ui8',
    url: '/ui8',
    template: '<batman-ui-wrapper8></batman-ui-wrapper8>'
  },
  {
    name: 'app.root.l1.404',
    url: '/404',
    template: '<not-found></not-found>'
  },
  // PROFILE
  {
    name: 'app.root.l1.account',
    abstract: true,
    private: true,
    template: '<ui-view></ui-view>'
  },
  {
    name: 'app.root.l1.account.board',
    url: '/account/board',
    template: '<linda-profile-page scroll-to-top></linda-profile-page>'
  },
  {
    name: 'app.root.l1.account.info',
    url: '/account/info',
    template: '<linda-profile-settings scroll-to-top></linda-profile-settings>'
  },
  {
    name: 'app.root.l1.account.bonuses',
    url: '/account/bonuses',
    template: '<linda-profile-bonus-list scroll-to-top></linda-profile-bonus-list>',
    params: {
      theme: 'bonuses'
    }
  },
  {
    name: 'app.root.l1.account.history',
    url: '/account/balance-history',
    template: '<linda-cashbox-balance-information scroll-to-top></linda-cashbox-balance-information>'
  },
  // IDENTIFY VERIFICATION PROFILE PAGE
  {
    name: 'app.root.l1.account.verification',
    url: '/account/verification',
    template: '<linda-document-verification-page verificationinfo="verificationinfo"></linda-document-verification-page>',
  },
  {
    name: 'app.root.playgrounds',
    url: '/playgrounds',
    template: `<linda-view-layer-six scroll-to-top />`
  },
  {
    name: 'app.root.l1.colossus',
    url: '/colossus',
    template: '<linda-view-layer-five scroll-to-top></linda-view-layer-five>',
    params:{
      theme: 'colossus'
    }
  },
  {
    name: 'app.root.l1.guide',
    url: '/guide',
    template: '<linda-view-layer-three scroll-to-top></linda-view-layer-three>',
    params: {
      theme: 'guide'
    }
  },
  {
    name: 'app.root.game',
    abstract: true,
    template: '<linda-game-page scroll-to-top ></linda-game-page>',
    gamePage: true
  },
  {
    name: 'app.root.game.real',
    url: '/play/:name',
    template: '',
    private: true,
    params: {
      mode: 'real'
    }
  },
  {
    name: 'app.root.game.demo',
    url: '/game/:name',
    template: '<linda-demo-game-footer  game="game"></linda-demo-game-footer>',
    params: {
      mode: 'demo'
    }
  },
  //PAYMENT CALLBACK
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback />',
    params: {
      type: 'deposit',
      noMinWidth: true
    }
  },
  {
    name: 'app.root.withdraw-paymentcb',
    url: '/withdraw-payment/:act',
    template: '<linda-payment-callback />',
    params: {
      type: 'withdrawal',
      noMinWidth: true
    }
  },
  //MISSED DATA
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    private: true,
    template: '<linda-missed-data-form  scroll-to-top />'
  },
  //INITIAL LIMIT PAGES
  {
    name: 'app.root.deposit-page',
    url: '/deposit-page',
    template: '<sweden-initial-limit-page scroll-to-top ></sweden-initial-limit-page>',
    private: true
  },
  {
    name: 'app.root.initial-limits',
    url: '/initial-limits',
    private: true,
    template: '<sweden-initial-limit-page  scroll-to-top />'
  },
  {
    name: 'app.root.time-page',
    url: '/time-page',
    template: '<sweden-initial-time-limit-page scroll-to-top ></sweden-initial-time-limit-page>',
    private: true
  },
  //application page
  {
    name: 'app.root.l1.application',
    url: '/application',
    template: '<batman-info-page5 to-top-on-state-change / >',
    params: {
      pageName: 'info',
      name: 'application'
    }
  },
  // {
  //   name: 'app.root.l1.missions',
  //   url: '/missions',
  //   template: '<linda-view-layer-eight scroll-to-top />'
  // },
];

export { router };
