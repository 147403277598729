import '../../../service/domain/documentVerification.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/documentVerification.js';
import '../../../service/domain/user.js';
import {filter} from 'rxjs/operators';

(function () {
  const component = { name: 'lindaDocumentVerificationPage' };

  controller.$inject = ['$scope', 'documentVerification', 'popup', 'verificationInfo$', 'user'];

  function controller($scope, _documentVerification, _popup, _verificationInfo$, _user) {
    $scope.preloader = false;
    $scope.documentList = [];

    this.$onInit = () => {
      getStartedParams();
    };

    const subscription1 = _verificationInfo$
      .pipe(filter((item) => item.event === 'onfidoResult'))
      .subscribe((info) => {
        callAboutResult(info.data);
      });

    const subscription2 = _verificationInfo$
      .pipe(filter((item) => item.event === 'moveStatus'))
      .subscribe((item) => {
        moveStatus(item.data);
      });

    // to do: remove method 'verifyDocuments' after changing method to 'verify' on all projects
    $scope.verifyDocuments = (id, verificationProvider, type) => {
      if (verificationProvider === 'ONFIDO') {
        $scope.preloader = true;
        _documentVerification.setDocuments.upload.options(id).then(({ result }) => {
          _popup.open({
            name: 'document-verification',
            content: { result, verificationProvider },
          });
          $scope.preloader = false;
        });
      }

      if (verificationProvider === 'BACK_OFFICE') {
        _popup.open({
          name: 'document-verification',
          content: { id, verificationProvider, type },
        });
      }
    };

    $scope.verify = (document) => {
      const { requestedDocumentId, verificationProvider } = document;
      if (verificationProvider === 'ONFIDO') {
        $scope.preloader = true;
        _documentVerification.setDocuments.upload.options(requestedDocumentId).then(({ result }) => {
          _popup.open({
            name: 'document-verification',
            content: { result, verificationProvider },
          });
          $scope.preloader = false;
        });
      }

      if (verificationProvider === 'BACK_OFFICE') {
        _popup.open({
          name: 'document-verification',
          content: document,
        });
      }
    };

    const moveStatus = (documentId) => {
      $scope.documentList.forEach((item) => {
        if (item.requestedDocumentId === documentId) {
          return (item.status = 'PENDING');
        }
        return;
      });
    };

    const callAboutResult = (data) => {
      $scope.preloader = true;

      _documentVerification.setDocuments.upload.done(data).then(() => {
        moveStatus(data.requestedDocumentId);

        $scope.preloader = false;
      });
    };

    const getStartedParams = () => {
      $scope.preloader = true;

      if (!_user.profile.verificationStatus) {
        $scope.preloader = false;

        return;
      }

      _documentVerification.getDocuments
        .list()
        .then((answer) => {
          $scope.documentList = answer.result;
        })
        .then(() => {
          $scope.preloader = false;
        });
    };

    this.$onDestroy = () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
