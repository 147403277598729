(function () {
  'use strict';

  const component = { name: 'swedenProfileLossesLimit' };

  controller.$inject = [];

  function controller() {
    /*
       “group”: “deposit”,
       “type”: “month”,
       “amount”: 10000000
       */
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
