const aliases = {
    home: {
        page: true,
        name: 'app.root.l1.home'
    },
    games: {
        page: true,
        name: 'app.root.l1.c.casino'
    },
    login: {
        page: false,
        name: 'login'
    },
    registration: {
        page: false,
        name: 'login'
    },
    realGame: {
        page: true,
        name: 'app.root.game.real'
    },
    promo: {
        page: true,
        name: 'app.root.l4.promo.list'
    },
    profile: {
        page: true,
        name: 'app.root.l1.account.info'
    },
    bonuses: {
      page: true,
      name: 'app.root.l1.account.bonuses',
      private: true,
    },
    paynplay: {
      page: false,
      name: 'bankid'
    },
    deposit: {
        page: false,
        name: 'cashbox',
        private: true
    },
    fastRegistration: {
        page: true,
        name: 'app.root.l1.paynplay'
    },
    shop:{
        page:true,
        name:'app.root.l1.shop'
    },
    bet: {
        page: true,
        name: 'app.root.l1.sportpage.live'
    },
    sport: {
        page: true,
        name: 'app.root.l1.sportpage.home'
    },
    live: {
        page: true,
        name: 'app.root.l1.sportpage.live'
    },
    
    sportTournament: {
        page: true,
        name: 'app.root.l1.sporttournament'
    },
    playgrounds: {
        page: true,
        name: 'app.root.playgrounds'
    },
    colossus: {
        page: true,
        name: 'app.root.l1.colossus'
    },
    responsibleGaming: {
        page: true,
        name: 'app.root.l1.account.limit.deposit-limit'
    },
    initialLimits: {
        page: true,
        name: 'app.root.initial-limits'
    },
    initialLimitPage: {
        page: true,
        name: 'app.root.deposit-page'
    },
    initialTimeLimitPage: {
        page: true,
        name: 'app.root.time-page'
    },
    balanceHistory: {
        page: true,
        name: 'app.root.l1.account.history'
    },
    history: {
        page: true,
        name: 'app.root.l1.account.history'
    },
    missedData: {
        page: true,
        name: 'app.root.missedData'
    },
    notFound: {
        page: true,
        name: 'app.root.l1.404'
    },
    liveCasino: {
        page: true,
        name: 'app.root.l1.c1.livecasino'
    },
    casino: {
        page:true,
        name: 'app.root.l1.c.casino'
    },
    verification: {
        page: true,
        name: 'app.root.l1.account.verification',
    }
};


export {aliases};
