import '../../service/domain/http.js';

export const authApi = 'authApi';

class AuthApi {
  static $inject = ['http'];

  constructor(http) {
    this.http = http;
  }

  auth(options){
    return this.http.postData('bankAuth', null, options);
  }
}

app.service(authApi, AuthApi)
