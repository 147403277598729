import modal from '../../app/service/domain/modal.js';

(function(){

  'use strict';

  controller.$inject = [ 'modal' ];

  function controller( _modal ){

    function link( scope ) {
      scope.$on('$stateChangeSuccess', () => {
        _modal.answer = false;
      });
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( 'betiniaModalRejectOnStateChange', controller );

})();

