(function () {
    'use strict';

    const directive = { name: 'betiniaBonusDateFormating' };

    controller.$inject = [];

    function controller() {
        function link(scope, element, attrs) {

            let arr = attrs[directive.name].split(/\W+/);

            let bonusTime = arr.splice(3, 2).join(':');
            let bonusDate = arr.splice(0, 3).reverse().join('.');

            element[0].innerHTML = bonusDate + ' ' + bonusTime;

        }

        return {
            restrict: 'A',
            link,
        };
    }

    app.directive(directive.name, controller);
})();
