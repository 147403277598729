import user from '../../app/service/domain/user.js';
import popup from '../../app/service/domain/popup.js';
import state from '../../app/service/domain/state.js';
(function () {

    'use strict';

    const directive = { name: 'betiniaSmartBtn' };

    controller.$inject = [ 'user' , 'popup' , 'state' , '$state' ];
    function controller( _user , _popup , _state , $state){

        function link( scope, element, attrs ){

            const config = scope.$eval( attrs[directive.name] );

            element[0].addEventListener( 'click' , (e) => {

                if(!_user.status){
                  _popup.open({name: 'login', content: {tab: '1'}});
                }else{
                    switch( config.direction ) {
                        case 'popup':
                            _popup.open({name: config.alias, content: config.content});
                            break;
                        case 'page':
                            $state.go(config.alias);
                            break;
                        default:
                            break;
                    }
                }
            })
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
