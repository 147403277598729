(function () {
  'use strict';

  const directive = { name: 'setLocalStorage' };

  controller.$inject = [];

  function controller() {

    function link( scope, element, attrs ){
      const config = scope.$eval( attrs[directive.name] );
      const clickHandler = () => {
        localStorage.setItem('loginCurrentTab', config.value);
      };
      element[0].addEventListener('click', clickHandler);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('click', clickHandler);
      });
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );

})();
