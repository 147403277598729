(function () {
  'use strict';

  /*
  block:         defines vertical alignment. One of 'start', 'center', 'end', or 'nearest'.      Default is 'start'
  inline:        defines horizontal alignment. One of 'start', 'center', 'end', or 'nearest'.    Default is 'nearest'
  classToScroll: class of active element.                                                        Default is 'is-active'
  itemClass:     class of elements which can scroll                                              Default is 'scroll-item'
  */

  const directive = { name: 'scrollToActiveItem' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const observer = new MutationObserver(scroll);

      const {
        block: block = 'start',
        inline: inline = 'nearest',
        classToScroll: targetClass = 'is-active',
        itemClass: itemContainerClass = 'scroll-item'
      } = scope.$eval(attrs[directive.name]);

      function scroll (mutations) {
        for(const mutation of mutations) {
          if (mutation.target.classList.contains(targetClass) &&
            mutation.target.classList.contains(itemContainerClass)) {

            mutation.target.scrollIntoView({ behavior: 'smooth', block: block, inline: inline });
          }
        }
      }

      scope.$on( '$destroy', () => {
        observer.disconnect();
      });
      observer.observe(element[0], {
        childList: true,
        attributes: true,
        subtree: true
      });
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );
})();
