import '../../service/domain/http.js';
import '../../service/domain/user.js';
import '../../service/configs/bankid.js';
import '../analytics/analytics.js';
import '../configs/analytic-events.js';
import '../../service/api/auth-api.js';
import '../../service/analytics/p161.js';
import '../../service/domain/modal.js';

export const bankidPaynPlay = 'bankidPaynPlay';

class BankidPaynPlay {
  static $inject = ['http', 'user', 'bankid.config', 'analytics', 'analyticsEvents', 'authApi', 'p161', 'modal'];

  constructor(http, user, bankid_config, analytics, analyticsEvents, authApi, p161, modal) {
    this.http = http;
    this.user = user;
    this.bankid_config = bankid_config;
    this.analytics = analytics;
    this.analyticsEvents = analyticsEvents;
    this.authApi = authApi;
    this.p161 = p161;
    this.modal = modal;
  }

  getAdFormCookieID = () => window.Adform?.['_uid'];

  openErrorModal = (errors) => {
    const error = errors?.filter(data => this.bankid_config.errorCodes.includes(data.code));
    if(!error.length) return;

    this.modal.open('error', { code: error[0].code });
  }

  requestParams(params){
    const localStorageParams = JSON.parse(window.localStorage.getItem('params') || '{}');
    localStorageParams.paymethod = 'bankid';
    localStorageParams.currency = this.bankid_config.currency;
    localStorageParams.affiliate = {};
    localStorageParams.locale = this.bankid_config.locale;
    localStorageParams.amount = 0;
    return Object.assign({}, localStorageParams, params);
  }
  auth(options){
    const data = this.requestParams(options);
    const adformcookieid = this.getAdFormCookieID();

    if (adformcookieid) {
      data.adformcookieid = adformcookieid;
    }
    return this.authApi.auth(data).then((data) => {
      this.user.profile = data.result;
      this.p161.track('registration');
      this.analytics.send(this.analyticsEvents.bankidAuthSuccess, 'success', data.result.login);
    }).catch((err) => {
      this.openErrorModal(err.message);
      this.analytics.send(this.analyticsEvents.bankidAuthFail, err.messages[0].code?.toString(), 'Not_Login');
    })
  }
}

app.service(bankidPaynPlay, BankidPaynPlay)
