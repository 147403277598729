// - For working with altenar proxy scripts needed to add in nginx config:
// - https://wiki.gosystem.io/display/MD/B2C+Altenar+proxifying+scripts+for+selected+localizations

(function () {
  'use strict';

  if (!$_CONFIG.sport) return;

  let countryCode = '';
  const isDevEnv = checkIfDevEnv();

  getSystemCountryCode()
    .then(({ result }) => {
      countryCode = result.country.code.toUpperCase();
      init();
    })
    .catch(() => {});

  async function getSystemCountryCode() {
    const apiDomain = window.location.host;
    const currentLang = window.location.pathname.split('/')[1];
    const response = await fetch(`//${apiDomain}/${currentLang}/api/v2/system/info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch(() => {});
    return response;
  }

  function init() {
    const sportSDK = setSportSDK();
    const widgetSDK = setWidgetSDK();
    appendAltenarWidgetScript(widgetSDK);
    appendSportsdk(sportSDK);
  }

  function checkIfDevEnv() {
    const envs = ['.m0l0', '.local', 'vesto.xyz', 'stage.', 'dev.'];
    const { host } = window.location;
    return window.$_DRAGON?.env === 'stage' ? true : !!envs.find((mask) => host.includes(mask));
  }

  function setWidgetSDK() {
    const prodCdnWidgetSDK = `https://sb2wsdk-altenar2.biahosted.com/altenarWSDK.js`;
    const devCdnWidgetSDK = `https://sb2wsdk-altenar2-stage.biahosted.com/altenarWSDK.js`;

    if (isDevEnv) {
      return devCdnWidgetSDK;
    }

    return prodCdnWidgetSDK;
  }

  function setSportSDK() {
    const defaultSportId = $_CONFIG.sportId || 'sport_id_is_not_configured';
    const localSportId = $_CDN.sportConfig?.localizationIds?.[countryCode];
    const sportId = localSportId || defaultSportId;

    const prodCdnSportSDK = `https://sb2integration-altenar2.biahosted.com/api/Integration/${sportId}`;
    const devCdnSportSDK = `https://sb2integration-altenar2-stage.biahosted.com/api/Integration/${sportId}`;

    if (isDevEnv) {
      return devCdnSportSDK;
    }

    return prodCdnSportSDK;
  }

  function appendAltenarWidgetScript(widgetSDK) {
    window.AltenarWidgetScriptLoaded = false;
    const element = document.createElement('script');
    element.src = `${widgetSDK}?build=${$_CONFIG.build}`;
    element.async = false;
    element.onload = () => {
      window.AltenarWidgetScriptLoaded = true;
    };
    document.head.appendChild(element);
  }

  function appendSportsdk(sportSDK) {
    const element = document.createElement('script');
    element.src = `${sportSDK}?build=${$_CONFIG.build}`;
    element.async = false;
    element.onload = () => {};
    document.head.appendChild(element);
  }
})();
