import modal from '../../../app/service/domain/modal.js';
import { gsap } from 'gsap';
import MobileLikeScroller from './betinia-map-scroller.js';

import betiniaCitiesServices from './betinia-cities-service.js';

(function () {
  'use strict';

  const component = {
    name: 'betiniaCities',
  };

  controller.$inject = ['$scope', '$timeout', 'config', 'modal', 'account', 'betiniaCitiesServices'];

  function controller($scope, $timeout, _config, _modal, _account, betiniaCitiesServices) {    
    const collection$ = betiniaCitiesServices.collection$;

    $scope.isSliderVisible = false;
    $scope.isLoaded = false;
    $scope.isLastPlayground = false;

    // coordinates in percent
    $scope.coords = {
      stockholm: {
        volleyball: {
          x: 29,
          y: 17,
        },
        hockey: {
          x: 73,
          y: 28,
        },
        basketball: {
          x: 21,
          y: 35,
        },
        tennis: {
          x: 74,
          y: 58,
        },
        football: {
          x: 47,
          y: 9,
        },
      },
      berlin: {
        'table-tennis': {
          x: 18,
          y: 13,
        },
        hockey: {
          x: 44,
          y: 48,
        },
        basketball: {
          x: 71,
          y: 50,
        },
        tennis: {
          x: 63,
          y: 19,
        },
        football: {
          x: 41,
          y: 19,
        },
      },
      warsaw: {
        volleyball: {
          x: 64,
          y: 11,
        },
        'table-tennis': {
          x: 60,
          y: 36,
        },
        hockey: {
          x: 45,
          y: 25,
        },
        basketball: {
          x: 58,
          y: 72,
        },
        tennis: {
          x: 20,
          y: 22,
        },
        football: {
          x: 28,
          y: 60,
        },
      },
      rome: {
        football: {
          x: 38,
          y: 15,
        },
        football2: {
          x: 72,
          y: 69,
        },
        volleyball: {
          x: 53,
          y: 67,
        },
        'table-tennis': {
          x: 43,
          y: 46,
        },
        hockey: {
          x: 72,
          y: 36,
        },
        basketball: {
          x: 25,
          y: 28,
        },
        tennis: {
          x: 54,
          y: 33,
        },
      },
      betinia: {
        'tennis-table': {
          x: 20,
          y: 13,
        },
        'motor-racing': {
          x: 45.5,
          y: 22,
        },
        'horse-racing': {
          x: 31.5,
          y: 36,
        },
        'dogs-racing': {
          x: 73,
          y: 64,
        },
        baseball: {
          x: 20,
          y: 68,
        },
        tennis: {
          x: 74,
          y: 12,
        },
        basketball: {
          x: 46,
          y: 62,
        },
        football: {
          x: 65,
          y: 40,
        },
      },
    };

    const SCALE_INDEX = 1.5;

    const $container = document.querySelector('.cities');
    const $mapContainer = document.querySelector('.cities-map');
    const $sliderContainer = document.querySelector('.cities-slider__in');

    const artsMap = [];

    const subscription = collection$.subscribe(({ current_level, collection }) => {
      $scope.collection = collection;
      $scope.level = current_level;
      $scope.isLoaded = false;

      if (collection) {
        createArtsMap($scope.collection, $scope.level);

        const defaultCity = $scope.collection.find((city) => city.status === 'in_progress') || $scope.collection[$scope.collection.length - 1];

        $scope.currentCity = defaultCity;

        $timeout(() => {
          new MobileLikeScroller($sliderContainer, 'x');
          new MobileLikeScroller($mapContainer, 'xy');

          centerMap($scope.currentCity.alias);
        });
        
        const currentCityArts = artsMap.find((item) => item.alias === $scope.currentCity.alias);
        loadCitiesBackgrounds(currentCityArts.arts)
          .then(() => {
            $scope.isLoaded = true;
          })
      }
    });

    $scope.openCity = (alias) => {
      animateSlide(alias);
      
      if ($scope.currentCity.alias === alias) {
        return;
      }

      $scope.currentCity = $scope.collection.find((city) => city.alias === alias);
      centerMap(alias);
    };

    $scope.closePlayground = () => {
      $scope.justBought = false;
      $scope.playground = null;

      const tl = gsap.timeline();

      tl.to(
        $mapContainer,
        {
          scale: 1,
          duration: 0.3
        }
      );
    };

    $scope.openPlayground = (city, pg) => {
      scrollToActivePlayground(city, pg);

      $scope.playground = pg;
      $scope.playgroundImageStatus = $scope.level === 1 && $scope.playground.status === 'closed' ? 'closed' : 'opened';
      $scope.playgroundImageFolder = $scope.playground.status === 'closed' && $scope.level > 1 ? $scope.level - 1 : $scope.level;

      const tl = gsap.timeline();

      tl.to(
        $mapContainer,
        {
          scale: SCALE_INDEX,
          duration: 0.3        
        }
      );
    };

    // buying playground function
    $scope.buyPlayground = (playground) => {
      $scope.preloaderBuy = true;

      betiniaCitiesServices.buy(playground).then((data) => {
        $scope.justBought = true;
        _account.info({ nocache: true });

        const cityStatus = data.result.city_status;
        $scope.playground.status = data.result.playground_status;
        $scope.isLastPlayground = cityStatus === 'reward_taken';
        $scope.preloaderBuy = false;
      });
    };

    $scope.openCityModal = () => {
      const nextCityIndex = $scope.collection.findIndex((city) => city.alias === $scope.currentCity.alias) + 1;
      const isAnyCityAvailable = nextCityIndex < $scope.collection.length;

      const prevLevel = $scope.level;
      const prevCity = $scope.collection[nextCityIndex - 1];
      const currentCity = isAnyCityAvailable ? $scope.collection[nextCityIndex] : $scope.collection[nextCityIndex - 1];

      $scope.closePlayground();
      betiniaCitiesServices.fetchCollection();

      $scope.isLastPlayground = false;

      if (isAnyCityAvailable) {
        _modal
          .open('playground-city-reward', {
            isLast: false,
            city: currentCity,
            prev_city: prevCity,
            level: prevLevel,
          })
          .then(() => {
            closeSlider();
            const $activeSlide = document.querySelector(`.cities-list__item.is-active`);
            if (!$activeSlide) { 
              return;
            }

            animateSlide(currentCity.alias);
          });
      } else {
        if (prevLevel === 3) {
          _modal
            .open('playground-city-reward', {
              isLast: true,
              city: currentCity,
              prev_city: currentCity,
              level: prevLevel,
            })
            .then(() => {
              closeSlider();
              const $activeSlide = document.querySelector(`.cities-list__item.is-active`);
              if (!$activeSlide) { 
                return;
              }

              animateSlide(currentCity.alias);
            });
        } else {
          _modal
            .open('playground-new-level', {
              city: currentCity,
              level: prevLevel < 3 ? prevLevel + 1 : prevLevel,
            })
            .then(() => {
              closeSlider();
              const $activeSlide = document.querySelector(`.cities-list__item.is-active`);
              if (!$activeSlide) { 
                return;
              }

              animateSlide(currentCity.alias);
            });
        }
      }
    }

    $scope.nextPlayground = () => {
      const minCostPlayground = $scope.currentCity.list
        .filter((c) => c.status === 'closed')
        .sort((a, b) => a.price - b.price)[0];

      $scope.justBought = false;
      $scope.playground = minCostPlayground;
      
      scrollToActivePlayground($scope.currentCity, $scope.playground);
    };

    $scope.toggleSlider = () => {
      $scope.isSliderVisible = !$scope.isSliderVisible;

      if ($scope.isSliderVisible) {
        openNewSlide($scope.currentCity.alias);
      }
    };

    $scope.$on('$stateChangeSuccess', () => {
      $scope.closePlayground();
    });

    
    function centerMap(alias) {
      const $mapElement = document.querySelector(`.map-${alias}`);
      $mapElement.scrollIntoView({block: 'center', inline: 'center'});
    }

    function scrollToActivePlayground(city, pg) {
      const $pinElement = document.querySelector(`.map-${city.alias} .label-${pg.alias}`);
      $pinElement.scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'});
    }

    function createArtsMap(collection, level) {
      artsMap.length = 0;

      collection.forEach((city) => {
        artsMap.push({
          alias: city.alias,
          arts: getCurrentCityArts(city, level)
        });
      });
    }
    
    function getCurrentCityArts(city, level) {
      const cityUrl = `${_config.cdn}/betinia/cities/l${level - 1}/${city.alias}/map.jpg`;
      const playgroundsUrls = city.list.map((p) => `${_config.cdn}/betinia/cities/l${level}/${city.alias}/img/${p.alias}.png`);
      
      return [cityUrl, ...playgroundsUrls];
    }

    function loadCitiesBackgrounds(imageUrls) {
      return new Promise((resolve, reject) => {
        let loadCount = 0;

        imageUrls.forEach((url) => {
          const img = new Image();

          img.onload = () => {
            loadCount++;

            if (loadCount === imageUrls.length) {
              resolve();
            }
          }

          img.onerror = () => {
            reject(new Error(`Failed to load image ${url}`));
          }

          img.src = url;
        });
      });
    }

    // toggle slide function
    function animateSlide(alias) {
      const $activeSlide = document.querySelector(`.cities-list__item.is-active`);

      if ($activeSlide?.classList.contains(`slide-${alias}`)) {
        closeActiveSlide($activeSlide);
        return;
      }

      if ($activeSlide) {
        closeActiveSlide($activeSlide);
      }

      openNewSlide(alias);
    }

    function closeActiveSlide(el) {
      if (!el) {
        return;
      }

      const cityGrounds = el.lastChild;

      el.classList.remove('is-active');

      const tl = gsap.timeline();

      tl.to(
        cityGrounds,
        {
          width: 0,
          opacity: 0,
          duration: 0.3
        }
      );
    }

    function openNewSlide(alias) {
      const $currentSlide = document.querySelector(`.slide-${alias}`);

      if (!$currentSlide) {
        return;
      }
      
      const cityGrounds = $currentSlide.lastChild;
      const targetWidth = cityGrounds.scrollWidth;

      $currentSlide.classList.add('is-active');

      const tl = gsap.timeline();

      tl.to(
        cityGrounds,
        {
          width: targetWidth,
          opacity: 1,
          duration: 0.3,
          onComplete: () => scrollToCurrentCity($currentSlide)
        }
      );
    }

    function recalcSlideWidth(alias) {
      const $currentSlide = document.querySelector(`.slide-${alias}`);

      if (!$currentSlide) {
        return;
      }
      
      const cityGrounds = $currentSlide.lastChild;
      cityGrounds.style.width = 'auto';
      const width = cityGrounds.scrollWidth;

      gsap.set(cityGrounds, { width });
    }

    // scroll to current city after opening slide
    function scrollToCurrentCity(currentSlide) {
      currentSlide.scrollIntoView({
        block: 'start', 
        inline: 'start',
        behavior: 'smooth'
      });
    };

    function closeSlider() {
      $scope.isSliderVisible = false;
    }

    function closeSliderHandler(e) {
      if (e.target.classList.contains('is-slider-close-excluded') || e.target.closest('.is-slider-close-excluded')) {
        return;
      }
      closeSlider();
    }

    function resizeHandler() {
      centerMap($scope.currentCity.alias);
      recalcSlideWidth($scope.currentCity.alias);
    }

    $container.addEventListener('click', closeSliderHandler);
    window.addEventListener('resize', resizeHandler);

    this.$onInit = () => {};

    this.$onDestroy = () => {
      subscription.unsubscribe();
      $container.removeEventListener('click', closeSliderHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      mode: '<',
    },
  });
})();
