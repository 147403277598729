(function () {
  'use strict';

  const directive = {
    name: 'parseRouteToScope',
  };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const achievementRoute = scope.$eval(attrs[directive.name]);
      scope.achievementRoute = JSON.parse(achievementRoute);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
