/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const kt = globalThis, le = kt.ShadowRoot && (kt.ShadyCSS === void 0 || kt.ShadyCSS.nativeShadow) && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype, de = Symbol(), ve = /* @__PURE__ */ new WeakMap();
class qe {
  constructor(t, e, n) {
    if (this._$cssResult$ = !0, n !== de)
      throw new Error("CSSResult is not constructable. Use `unsafeCSS` or `css` instead.");
    this.cssText = t, this._strings = e;
  }
  // This is a getter so that it's lazy. In practice, this means stylesheets
  // are not created until the first element instance is made.
  get styleSheet() {
    let t = this._styleSheet;
    const e = this._strings;
    if (le && t === void 0) {
      const n = e !== void 0 && e.length === 1;
      n && (t = ve.get(e)), t === void 0 && ((this._styleSheet = t = new CSSStyleSheet()).replaceSync(this.cssText), n && ve.set(e, t));
    }
    return t;
  }
  toString() {
    return this.cssText;
  }
}
const yi = (i) => {
  if (i._$cssResult$ === !0)
    return i.cssText;
  if (typeof i == "number")
    return i;
  throw new Error(`Value passed to 'css' function must be a 'css' function result: ${i}. Use 'unsafeCSS' to pass non-literal values, but take care to ensure page security.`);
}, _i = (i) => new qe(typeof i == "string" ? i : String(i), void 0, de), Bt = (i, ...t) => {
  const e = i.length === 1 ? i[0] : t.reduce((n, r, o) => n + yi(r) + i[o + 1], i[0]);
  return new qe(e, i, de);
}, wi = (i, t) => {
  if (le)
    i.adoptedStyleSheets = t.map((e) => e instanceof CSSStyleSheet ? e : e.styleSheet);
  else
    for (const e of t) {
      const n = document.createElement("style"), r = kt.litNonce;
      r !== void 0 && n.setAttribute("nonce", r), n.textContent = e.cssText, i.appendChild(n);
    }
}, vi = (i) => {
  let t = "";
  for (const e of i.cssRules)
    t += e.cssText;
  return _i(t);
}, Ee = le ? (i) => i : (i) => i instanceof CSSStyleSheet ? vi(i) : i;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const { is: Ei, defineProperty: Ci, getOwnPropertyDescriptor: Ce, getOwnPropertyNames: ki, getOwnPropertySymbols: Si, getPrototypeOf: ke } = Object, B = globalThis;
let U;
const Se = B.trustedTypes, Pi = Se ? Se.emptyScript : "", St = B.reactiveElementPolyfillSupportDevMode;
var De;
{
  const i = B.litIssuedWarnings ?? (B.litIssuedWarnings = /* @__PURE__ */ new Set());
  U = (t, e) => {
    e += ` See https://lit.dev/msg/${t} for more information.`, i.has(e) || (console.warn(e), i.add(e));
  }, U("dev-mode", "Lit is in dev mode. Not recommended for production!"), (De = B.ShadyDOM) != null && De.inUse && St === void 0 && U("polyfill-support-missing", "Shadow DOM is being polyfilled via `ShadyDOM` but the `polyfill-support` module has not been loaded.");
}
const qt = (i) => {
  B.emitLitDebugLogEvents && B.dispatchEvent(new CustomEvent("lit-debug", {
    detail: i
  }));
}, et = (i, t) => i, $t = {
  toAttribute(i, t) {
    switch (t) {
      case Boolean:
        i = i ? Pi : null;
        break;
      case Object:
      case Array:
        i = i == null ? i : JSON.stringify(i);
        break;
    }
    return i;
  },
  fromAttribute(i, t) {
    let e = i;
    switch (t) {
      case Boolean:
        e = i !== null;
        break;
      case Number:
        e = i === null ? null : Number(i);
        break;
      case Object:
      case Array:
        try {
          e = JSON.parse(i);
        } catch {
          e = null;
        }
        break;
    }
    return e;
  }
}, ce = (i, t) => !Ei(i, t), Pe = {
  attribute: !0,
  type: String,
  converter: $t,
  reflect: !1,
  hasChanged: ce
};
Symbol.metadata ?? (Symbol.metadata = Symbol("metadata"));
B.litPropertyMetadata ?? (B.litPropertyMetadata = /* @__PURE__ */ new WeakMap());
class q extends HTMLElement {
  /**
   * Adds an initializer function to the class that is called during instance
   * construction.
   *
   * This is useful for code that runs against a `ReactiveElement`
   * subclass, such as a decorator, that needs to do work for each
   * instance, such as setting up a `ReactiveController`.
   *
   * ```ts
   * const myDecorator = (target: typeof ReactiveElement, key: string) => {
   *   target.addInitializer((instance: ReactiveElement) => {
   *     // This is run during construction of the element
   *     new MyController(instance);
   *   });
   * }
   * ```
   *
   * Decorating a field will then cause each instance to run an initializer
   * that adds a controller:
   *
   * ```ts
   * class MyElement extends LitElement {
   *   @myDecorator foo;
   * }
   * ```
   *
   * Initializers are stored per-constructor. Adding an initializer to a
   * subclass does not add it to a superclass. Since initializers are run in
   * constructors, initializers will run in order of the class hierarchy,
   * starting with superclasses and progressing to the instance's class.
   *
   * @nocollapse
   */
  static addInitializer(t) {
    this.__prepare(), (this._initializers ?? (this._initializers = [])).push(t);
  }
  /**
   * Returns a list of attributes corresponding to the registered properties.
   * @nocollapse
   * @category attributes
   */
  static get observedAttributes() {
    return this.finalize(), this.__attributeToPropertyMap && [...this.__attributeToPropertyMap.keys()];
  }
  /**
   * Creates a property accessor on the element prototype if one does not exist
   * and stores a {@linkcode PropertyDeclaration} for the property with the
   * given options. The property setter calls the property's `hasChanged`
   * property option or uses a strict identity check to determine whether or not
   * to request an update.
   *
   * This method may be overridden to customize properties; however,
   * when doing so, it's important to call `super.createProperty` to ensure
   * the property is setup correctly. This method calls
   * `getPropertyDescriptor` internally to get a descriptor to install.
   * To customize what properties do when they are get or set, override
   * `getPropertyDescriptor`. To customize the options for a property,
   * implement `createProperty` like this:
   *
   * ```ts
   * static createProperty(name, options) {
   *   options = Object.assign(options, {myOption: true});
   *   super.createProperty(name, options);
   * }
   * ```
   *
   * @nocollapse
   * @category properties
   */
  static createProperty(t, e = Pe) {
    if (e.state && (e.attribute = !1), this.__prepare(), this.elementProperties.set(t, e), !e.noAccessor) {
      const n = (
        // Use Symbol.for in dev mode to make it easier to maintain state
        // when doing HMR.
        Symbol.for(`${String(t)} (@property() cache)`)
      ), r = this.getPropertyDescriptor(t, n, e);
      r !== void 0 && Ci(this.prototype, t, r);
    }
  }
  /**
   * Returns a property descriptor to be defined on the given named property.
   * If no descriptor is returned, the property will not become an accessor.
   * For example,
   *
   * ```ts
   * class MyElement extends LitElement {
   *   static getPropertyDescriptor(name, key, options) {
   *     const defaultDescriptor =
   *         super.getPropertyDescriptor(name, key, options);
   *     const setter = defaultDescriptor.set;
   *     return {
   *       get: defaultDescriptor.get,
   *       set(value) {
   *         setter.call(this, value);
   *         // custom action.
   *       },
   *       configurable: true,
   *       enumerable: true
   *     }
   *   }
   * }
   * ```
   *
   * @nocollapse
   * @category properties
   */
  static getPropertyDescriptor(t, e, n) {
    const { get: r, set: o } = Ce(this.prototype, t) ?? {
      get() {
        return this[e];
      },
      set(s) {
        this[e] = s;
      }
    };
    if (r == null) {
      if ("value" in (Ce(this.prototype, t) ?? {}))
        throw new Error(`Field ${JSON.stringify(String(t))} on ${this.name} was declared as a reactive property but it's actually declared as a value on the prototype. Usually this is due to using @property or @state on a method.`);
      U("reactive-property-without-getter", `Field ${JSON.stringify(String(t))} on ${this.name} was declared as a reactive property but it does not have a getter. This will be an error in a future version of Lit.`);
    }
    return {
      get() {
        return r == null ? void 0 : r.call(this);
      },
      set(s) {
        const l = r == null ? void 0 : r.call(this);
        o.call(this, s), this.requestUpdate(t, l, n);
      },
      configurable: !0,
      enumerable: !0
    };
  }
  /**
   * Returns the property options associated with the given property.
   * These options are defined with a `PropertyDeclaration` via the `properties`
   * object or the `@property` decorator and are registered in
   * `createProperty(...)`.
   *
   * Note, this method should be considered "final" and not overridden. To
   * customize the options for a given property, override
   * {@linkcode createProperty}.
   *
   * @nocollapse
   * @final
   * @category properties
   */
  static getPropertyOptions(t) {
    return this.elementProperties.get(t) ?? Pe;
  }
  /**
   * Initializes static own properties of the class used in bookkeeping
   * for element properties, initializers, etc.
   *
   * Can be called multiple times by code that needs to ensure these
   * properties exist before using them.
   *
   * This method ensures the superclass is finalized so that inherited
   * property metadata can be copied down.
   * @nocollapse
   */
  static __prepare() {
    if (this.hasOwnProperty(et("elementProperties")))
      return;
    const t = ke(this);
    t.finalize(), t._initializers !== void 0 && (this._initializers = [...t._initializers]), this.elementProperties = new Map(t.elementProperties);
  }
  /**
   * Finishes setting up the class so that it's ready to be registered
   * as a custom element and instantiated.
   *
   * This method is called by the ReactiveElement.observedAttributes getter.
   * If you override the observedAttributes getter, you must either call
   * super.observedAttributes to trigger finalization, or call finalize()
   * yourself.
   *
   * @nocollapse
   */
  static finalize() {
    if (this.hasOwnProperty(et("finalized")))
      return;
    if (this.finalized = !0, this.__prepare(), this.hasOwnProperty(et("properties"))) {
      const e = this.properties, n = [
        ...ki(e),
        ...Si(e)
      ];
      for (const r of n)
        this.createProperty(r, e[r]);
    }
    const t = this[Symbol.metadata];
    if (t !== null) {
      const e = litPropertyMetadata.get(t);
      if (e !== void 0)
        for (const [n, r] of e)
          this.elementProperties.set(n, r);
    }
    this.__attributeToPropertyMap = /* @__PURE__ */ new Map();
    for (const [e, n] of this.elementProperties) {
      const r = this.__attributeNameForProperty(e, n);
      r !== void 0 && this.__attributeToPropertyMap.set(r, e);
    }
    this.elementStyles = this.finalizeStyles(this.styles), this.hasOwnProperty("createProperty") && U("no-override-create-property", "Overriding ReactiveElement.createProperty() is deprecated. The override will not be called with standard decorators"), this.hasOwnProperty("getPropertyDescriptor") && U("no-override-get-property-descriptor", "Overriding ReactiveElement.getPropertyDescriptor() is deprecated. The override will not be called with standard decorators");
  }
  /**
   * Takes the styles the user supplied via the `static styles` property and
   * returns the array of styles to apply to the element.
   * Override this method to integrate into a style management system.
   *
   * Styles are deduplicated preserving the _last_ instance in the list. This
   * is a performance optimization to avoid duplicated styles that can occur
   * especially when composing via subclassing. The last item is kept to try
   * to preserve the cascade order with the assumption that it's most important
   * that last added styles override previous styles.
   *
   * @nocollapse
   * @category styles
   */
  static finalizeStyles(t) {
    const e = [];
    if (Array.isArray(t)) {
      const n = new Set(t.flat(1 / 0).reverse());
      for (const r of n)
        e.unshift(Ee(r));
    } else t !== void 0 && e.push(Ee(t));
    return e;
  }
  /**
   * Returns the property name for the given attribute `name`.
   * @nocollapse
   */
  static __attributeNameForProperty(t, e) {
    const n = e.attribute;
    return n === !1 ? void 0 : typeof n == "string" ? n : typeof t == "string" ? t.toLowerCase() : void 0;
  }
  constructor() {
    super(), this.__instanceProperties = void 0, this.isUpdatePending = !1, this.hasUpdated = !1, this.__reflectingProperty = null, this.__initialize();
  }
  /**
   * Internal only override point for customizing work done when elements
   * are constructed.
   */
  __initialize() {
    var t;
    this.__updatePromise = new Promise((e) => this.enableUpdating = e), this._$changedProperties = /* @__PURE__ */ new Map(), this.__saveInstanceProperties(), this.requestUpdate(), (t = this.constructor._initializers) == null || t.forEach((e) => e(this));
  }
  /**
   * Registers a `ReactiveController` to participate in the element's reactive
   * update cycle. The element automatically calls into any registered
   * controllers during its lifecycle callbacks.
   *
   * If the element is connected when `addController()` is called, the
   * controller's `hostConnected()` callback will be immediately called.
   * @category controllers
   */
  addController(t) {
    var e;
    (this.__controllers ?? (this.__controllers = /* @__PURE__ */ new Set())).add(t), this.renderRoot !== void 0 && this.isConnected && ((e = t.hostConnected) == null || e.call(t));
  }
  /**
   * Removes a `ReactiveController` from the element.
   * @category controllers
   */
  removeController(t) {
    var e;
    (e = this.__controllers) == null || e.delete(t);
  }
  /**
   * Fixes any properties set on the instance before upgrade time.
   * Otherwise these would shadow the accessor and break these properties.
   * The properties are stored in a Map which is played back after the
   * constructor runs. Note, on very old versions of Safari (<=9) or Chrome
   * (<=41), properties created for native platform properties like (`id` or
   * `name`) may not have default values set in the element constructor. On
   * these browsers native properties appear on instances and therefore their
   * default value will overwrite any element default (e.g. if the element sets
   * this.id = 'id' in the constructor, the 'id' will become '' since this is
   * the native platform default).
   */
  __saveInstanceProperties() {
    const t = /* @__PURE__ */ new Map(), e = this.constructor.elementProperties;
    for (const n of e.keys())
      this.hasOwnProperty(n) && (t.set(n, this[n]), delete this[n]);
    t.size > 0 && (this.__instanceProperties = t);
  }
  /**
   * Returns the node into which the element should render and by default
   * creates and returns an open shadowRoot. Implement to customize where the
   * element's DOM is rendered. For example, to render into the element's
   * childNodes, return `this`.
   *
   * @return Returns a node into which to render.
   * @category rendering
   */
  createRenderRoot() {
    const t = this.shadowRoot ?? this.attachShadow(this.constructor.shadowRootOptions);
    return wi(t, this.constructor.elementStyles), t;
  }
  /**
   * On first connection, creates the element's renderRoot, sets up
   * element styling, and enables updating.
   * @category lifecycle
   */
  connectedCallback() {
    var t;
    this.renderRoot ?? (this.renderRoot = this.createRenderRoot()), this.enableUpdating(!0), (t = this.__controllers) == null || t.forEach((e) => {
      var n;
      return (n = e.hostConnected) == null ? void 0 : n.call(e);
    });
  }
  /**
   * Note, this method should be considered final and not overridden. It is
   * overridden on the element instance with a function that triggers the first
   * update.
   * @category updates
   */
  enableUpdating(t) {
  }
  /**
   * Allows for `super.disconnectedCallback()` in extensions while
   * reserving the possibility of making non-breaking feature additions
   * when disconnecting at some point in the future.
   * @category lifecycle
   */
  disconnectedCallback() {
    var t;
    (t = this.__controllers) == null || t.forEach((e) => {
      var n;
      return (n = e.hostDisconnected) == null ? void 0 : n.call(e);
    });
  }
  /**
   * Synchronizes property values when attributes change.
   *
   * Specifically, when an attribute is set, the corresponding property is set.
   * You should rarely need to implement this callback. If this method is
   * overridden, `super.attributeChangedCallback(name, _old, value)` must be
   * called.
   *
   * See [using the lifecycle callbacks](https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks)
   * on MDN for more information about the `attributeChangedCallback`.
   * @category attributes
   */
  attributeChangedCallback(t, e, n) {
    this._$attributeToProperty(t, n);
  }
  __propertyToAttribute(t, e) {
    var s;
    const r = this.constructor.elementProperties.get(t), o = this.constructor.__attributeNameForProperty(t, r);
    if (o !== void 0 && r.reflect === !0) {
      const a = (((s = r.converter) == null ? void 0 : s.toAttribute) !== void 0 ? r.converter : $t).toAttribute(e, r.type);
      this.constructor.enabledWarnings.includes("migration") && a === void 0 && U("undefined-attribute-value", `The attribute value for the ${t} property is undefined on element ${this.localName}. The attribute will be removed, but in the previous version of \`ReactiveElement\`, the attribute would not have changed.`), this.__reflectingProperty = t, a == null ? this.removeAttribute(o) : this.setAttribute(o, a), this.__reflectingProperty = null;
    }
  }
  /** @internal */
  _$attributeToProperty(t, e) {
    var o;
    const n = this.constructor, r = n.__attributeToPropertyMap.get(t);
    if (r !== void 0 && this.__reflectingProperty !== r) {
      const s = n.getPropertyOptions(r), l = typeof s.converter == "function" ? { fromAttribute: s.converter } : ((o = s.converter) == null ? void 0 : o.fromAttribute) !== void 0 ? s.converter : $t;
      this.__reflectingProperty = r, this[r] = l.fromAttribute(
        e,
        s.type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ), this.__reflectingProperty = null;
    }
  }
  /**
   * Requests an update which is processed asynchronously. This should be called
   * when an element should update based on some state not triggered by setting
   * a reactive property. In this case, pass no arguments. It should also be
   * called when manually implementing a property setter. In this case, pass the
   * property `name` and `oldValue` to ensure that any configured property
   * options are honored.
   *
   * @param name name of requesting property
   * @param oldValue old value of requesting property
   * @param options property options to use instead of the previously
   *     configured options
   * @category updates
   */
  requestUpdate(t, e, n) {
    if (t !== void 0) {
      t instanceof Event && U("", "The requestUpdate() method was called with an Event as the property name. This is probably a mistake caused by binding this.requestUpdate as an event listener. Instead bind a function that will call it with no arguments: () => this.requestUpdate()"), n ?? (n = this.constructor.getPropertyOptions(t));
      const r = n.hasChanged ?? ce, o = this[t];
      if (r(o, e))
        this._$changeProperty(t, e, n);
      else
        return;
    }
    this.isUpdatePending === !1 && (this.__updatePromise = this.__enqueueUpdate());
  }
  /**
   * @internal
   */
  _$changeProperty(t, e, n) {
    this._$changedProperties.has(t) || this._$changedProperties.set(t, e), n.reflect === !0 && this.__reflectingProperty !== t && (this.__reflectingProperties ?? (this.__reflectingProperties = /* @__PURE__ */ new Set())).add(t);
  }
  /**
   * Sets up the element to asynchronously update.
   */
  async __enqueueUpdate() {
    this.isUpdatePending = !0;
    try {
      await this.__updatePromise;
    } catch (e) {
      Promise.reject(e);
    }
    const t = this.scheduleUpdate();
    return t != null && await t, !this.isUpdatePending;
  }
  /**
   * Schedules an element update. You can override this method to change the
   * timing of updates by returning a Promise. The update will await the
   * returned Promise, and you should resolve the Promise to allow the update
   * to proceed. If this method is overridden, `super.scheduleUpdate()`
   * must be called.
   *
   * For instance, to schedule updates to occur just before the next frame:
   *
   * ```ts
   * override protected async scheduleUpdate(): Promise<unknown> {
   *   await new Promise((resolve) => requestAnimationFrame(() => resolve()));
   *   super.scheduleUpdate();
   * }
   * ```
   * @category updates
   */
  scheduleUpdate() {
    const t = this.performUpdate();
    return this.constructor.enabledWarnings.includes("async-perform-update") && typeof (t == null ? void 0 : t.then) == "function" && U("async-perform-update", `Element ${this.localName} returned a Promise from performUpdate(). This behavior is deprecated and will be removed in a future version of ReactiveElement.`), t;
  }
  /**
   * Performs an element update. Note, if an exception is thrown during the
   * update, `firstUpdated` and `updated` will not be called.
   *
   * Call `performUpdate()` to immediately process a pending update. This should
   * generally not be needed, but it can be done in rare cases when you need to
   * update synchronously.
   *
   * @category updates
   */
  performUpdate() {
    var n;
    if (!this.isUpdatePending)
      return;
    if (qt == null || qt({ kind: "update" }), !this.hasUpdated) {
      this.renderRoot ?? (this.renderRoot = this.createRenderRoot());
      {
        const s = [...this.constructor.elementProperties.keys()].filter((l) => this.hasOwnProperty(l) && l in ke(this));
        if (s.length)
          throw new Error(`The following properties on element ${this.localName} will not trigger updates as expected because they are set using class fields: ${s.join(", ")}. Native class fields and some compiled output will overwrite accessors used for detecting changes. See https://lit.dev/msg/class-field-shadowing for more information.`);
      }
      if (this.__instanceProperties) {
        for (const [o, s] of this.__instanceProperties)
          this[o] = s;
        this.__instanceProperties = void 0;
      }
      const r = this.constructor.elementProperties;
      if (r.size > 0)
        for (const [o, s] of r)
          s.wrapped === !0 && !this._$changedProperties.has(o) && this[o] !== void 0 && this._$changeProperty(o, this[o], s);
    }
    let t = !1;
    const e = this._$changedProperties;
    try {
      t = this.shouldUpdate(e), t ? (this.willUpdate(e), (n = this.__controllers) == null || n.forEach((r) => {
        var o;
        return (o = r.hostUpdate) == null ? void 0 : o.call(r);
      }), this.update(e)) : this.__markUpdated();
    } catch (r) {
      throw t = !1, this.__markUpdated(), r;
    }
    t && this._$didUpdate(e);
  }
  /**
   * Invoked before `update()` to compute values needed during the update.
   *
   * Implement `willUpdate` to compute property values that depend on other
   * properties and are used in the rest of the update process.
   *
   * ```ts
   * willUpdate(changedProperties) {
   *   // only need to check changed properties for an expensive computation.
   *   if (changedProperties.has('firstName') || changedProperties.has('lastName')) {
   *     this.sha = computeSHA(`${this.firstName} ${this.lastName}`);
   *   }
   * }
   *
   * render() {
   *   return html`SHA: ${this.sha}`;
   * }
   * ```
   *
   * @category updates
   */
  willUpdate(t) {
  }
  // Note, this is an override point for polyfill-support.
  // @internal
  _$didUpdate(t) {
    var e;
    (e = this.__controllers) == null || e.forEach((n) => {
      var r;
      return (r = n.hostUpdated) == null ? void 0 : r.call(n);
    }), this.hasUpdated || (this.hasUpdated = !0, this.firstUpdated(t)), this.updated(t), this.isUpdatePending && this.constructor.enabledWarnings.includes("change-in-update") && U("change-in-update", `Element ${this.localName} scheduled an update (generally because a property was set) after an update completed, causing a new update to be scheduled. This is inefficient and should be avoided unless the next update can only be scheduled as a side effect of the previous update.`);
  }
  __markUpdated() {
    this._$changedProperties = /* @__PURE__ */ new Map(), this.isUpdatePending = !1;
  }
  /**
   * Returns a Promise that resolves when the element has completed updating.
   * The Promise value is a boolean that is `true` if the element completed the
   * update without triggering another update. The Promise result is `false` if
   * a property was set inside `updated()`. If the Promise is rejected, an
   * exception was thrown during the update.
   *
   * To await additional asynchronous work, override the `getUpdateComplete`
   * method. For example, it is sometimes useful to await a rendered element
   * before fulfilling this Promise. To do this, first await
   * `super.getUpdateComplete()`, then any subsequent state.
   *
   * @return A promise of a boolean that resolves to true if the update completed
   *     without triggering another update.
   * @category updates
   */
  get updateComplete() {
    return this.getUpdateComplete();
  }
  /**
   * Override point for the `updateComplete` promise.
   *
   * It is not safe to override the `updateComplete` getter directly due to a
   * limitation in TypeScript which means it is not possible to call a
   * superclass getter (e.g. `super.updateComplete.then(...)`) when the target
   * language is ES5 (https://github.com/microsoft/TypeScript/issues/338).
   * This method should be overridden instead. For example:
   *
   * ```ts
   * class MyElement extends LitElement {
   *   override async getUpdateComplete() {
   *     const result = await super.getUpdateComplete();
   *     await this._myChild.updateComplete;
   *     return result;
   *   }
   * }
   * ```
   *
   * @return A promise of a boolean that resolves to true if the update completed
   *     without triggering another update.
   * @category updates
   */
  getUpdateComplete() {
    return this.__updatePromise;
  }
  /**
   * Controls whether or not `update()` should be called when the element requests
   * an update. By default, this method always returns `true`, but this can be
   * customized to control when to update.
   *
   * @param _changedProperties Map of changed properties with old values
   * @category updates
   */
  shouldUpdate(t) {
    return !0;
  }
  /**
   * Updates the element. This method reflects property values to attributes.
   * It can be overridden to render and keep updated element DOM.
   * Setting properties inside this method will *not* trigger
   * another update.
   *
   * @param _changedProperties Map of changed properties with old values
   * @category updates
   */
  update(t) {
    this.__reflectingProperties && (this.__reflectingProperties = this.__reflectingProperties.forEach((e) => this.__propertyToAttribute(e, this[e]))), this.__markUpdated();
  }
  /**
   * Invoked whenever the element is updated. Implement to perform
   * post-updating tasks via DOM APIs, for example, focusing an element.
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   *
   * @param _changedProperties Map of changed properties with old values
   * @category updates
   */
  updated(t) {
  }
  /**
   * Invoked when the element is first updated. Implement to perform one time
   * work on the element after update.
   *
   * ```ts
   * firstUpdated() {
   *   this.renderRoot.getElementById('my-text-area').focus();
   * }
   * ```
   *
   * Setting properties inside this method will trigger the element to update
   * again after this update cycle completes.
   *
   * @param _changedProperties Map of changed properties with old values
   * @category updates
   */
  firstUpdated(t) {
  }
}
q.elementStyles = [];
q.shadowRootOptions = { mode: "open" };
q[et("elementProperties")] = /* @__PURE__ */ new Map();
q[et("finalized")] = /* @__PURE__ */ new Map();
St == null || St({ ReactiveElement: q });
{
  q.enabledWarnings = [
    "change-in-update",
    "async-perform-update"
  ];
  const i = function(t) {
    t.hasOwnProperty(et("enabledWarnings")) || (t.enabledWarnings = t.enabledWarnings.slice());
  };
  q.enableWarning = function(t) {
    i(this), this.enabledWarnings.includes(t) || this.enabledWarnings.push(t);
  }, q.disableWarning = function(t) {
    i(this);
    const e = this.enabledWarnings.indexOf(t);
    e >= 0 && this.enabledWarnings.splice(e, 1);
  };
}
(B.reactiveElementVersions ?? (B.reactiveElementVersions = [])).push("2.0.4");
B.reactiveElementVersions.length > 1 && U("multiple-versions", "Multiple versions of Lit loaded. Loading multiple versions is not recommended.");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const M = globalThis, v = (i) => {
  M.emitLitDebugLogEvents && M.dispatchEvent(new CustomEvent("lit-debug", {
    detail: i
  }));
};
let $i = 0, ft;
M.litIssuedWarnings ?? (M.litIssuedWarnings = /* @__PURE__ */ new Set()), ft = (i, t) => {
  t += i ? ` See https://lit.dev/msg/${i} for more information.` : "", M.litIssuedWarnings.has(t) || (console.warn(t), M.litIssuedWarnings.add(t));
}, ft("dev-mode", "Lit is in dev mode. Not recommended for production!");
var ze, Ve;
const L = (ze = M.ShadyDOM) != null && ze.inUse && ((Ve = M.ShadyDOM) == null ? void 0 : Ve.noPatch) === !0 ? M.ShadyDOM.wrap : (i) => i, Tt = M.trustedTypes, $e = Tt ? Tt.createPolicy("lit-html", {
  createHTML: (i) => i
}) : void 0, Ti = (i) => i, It = (i, t, e) => Ti, xi = (i) => {
  if (Z !== It)
    throw new Error("Attempted to overwrite existing lit-html security policy. setSanitizeDOMValueFactory should be called at most once.");
  Z = i;
}, Ni = () => {
  Z = It;
}, ee = (i, t, e) => Z(i, t, e), je = "$lit$", F = `lit$${Math.random().toFixed(9).slice(2)}$`, He = "?" + F, Ai = `<${He}>`, G = document, gt = () => G.createComment(""), pt = (i) => i === null || typeof i != "object" && typeof i != "function", ue = Array.isArray, Mi = (i) => ue(i) || // eslint-disable-next-line @typescript-eslint/no-explicit-any
typeof (i == null ? void 0 : i[Symbol.iterator]) == "function", jt = `[ 	
\f\r]`, Bi = `[^ 	
\f\r"'\`<>=]`, Ii = `[^\\s"'>=/]`, ct = /<(?:(!--|\/[^a-zA-Z])|(\/?[a-zA-Z][^>\s]*)|(\/?$))/g, Te = 1, Ht = 2, Ri = 3, xe = /-->/g, Ne = />/g, K = new RegExp(`>|${jt}(?:(${Ii}+)(${jt}*=${jt}*(?:${Bi}|("|')|))|$)`, "g"), Li = 0, Ae = 1, Ui = 2, Me = 3, Wt = /'/g, Jt = /"/g, We = /^(?:script|style|textarea|title)$/i, Oi = 1, xt = 2, ie = 3, he = 1, Nt = 2, Di = 3, zi = 4, Vi = 5, fe = 6, Fi = 7, Je = (i) => (t, ...e) => (t.some((n) => n === void 0) && console.warn(`Some template strings are undefined.
This is probably caused by illegal octal escape sequences.`), e.some((n) => n == null ? void 0 : n._$litStatic$) && ft("", `Static values 'literal' or 'unsafeStatic' cannot be used as values to non-static templates.
Please use the static 'html' tag function. See https://lit.dev/docs/templates/expressions/#static-expressions`), {
  // This property needs to remain unminified.
  _$litType$: i,
  strings: t,
  values: e
}), D = Je(Oi), Rt = Je(xt), Q = Symbol.for("lit-noChange"), P = Symbol.for("lit-nothing"), Be = /* @__PURE__ */ new WeakMap(), Y = G.createTreeWalker(
  G,
  129
  /* NodeFilter.SHOW_{ELEMENT|COMMENT} */
);
let Z = It;
function Ke(i, t) {
  if (!ue(i) || !i.hasOwnProperty("raw")) {
    let e = "invalid template strings array";
    throw e = `
          Internal Error: expected template strings to be an array
          with a 'raw' field. Faking a template strings array by
          calling html or svg like an ordinary function is effectively
          the same as calling unsafeHtml and can lead to major security
          issues, e.g. opening your code up to XSS attacks.
          If you're using the html or svg tagged template functions normally
          and still seeing this error, please file a bug at
          https://github.com/lit/lit/issues/new?template=bug_report.md
          and include information about your build tooling, if any.
        `.trim().replace(/\n */g, `
`), new Error(e);
  }
  return $e !== void 0 ? $e.createHTML(t) : t;
}
const qi = (i, t) => {
  const e = i.length - 1, n = [];
  let r = t === xt ? "<svg>" : t === ie ? "<math>" : "", o, s = ct;
  for (let a = 0; a < e; a++) {
    const d = i[a];
    let c = -1, f, h = 0, u;
    for (; h < d.length && (s.lastIndex = h, u = s.exec(d), u !== null); )
      if (h = s.lastIndex, s === ct) {
        if (u[Te] === "!--")
          s = xe;
        else if (u[Te] !== void 0)
          s = Ne;
        else if (u[Ht] !== void 0)
          We.test(u[Ht]) && (o = new RegExp(`</${u[Ht]}`, "g")), s = K;
        else if (u[Ri] !== void 0)
          throw new Error("Bindings in tag names are not supported. Please use static templates instead. See https://lit.dev/docs/templates/expressions/#static-expressions");
      } else s === K ? u[Li] === ">" ? (s = o ?? ct, c = -1) : u[Ae] === void 0 ? c = -2 : (c = s.lastIndex - u[Ui].length, f = u[Ae], s = u[Me] === void 0 ? K : u[Me] === '"' ? Jt : Wt) : s === Jt || s === Wt ? s = K : s === xe || s === Ne ? s = ct : (s = K, o = void 0);
    console.assert(c === -1 || s === K || s === Wt || s === Jt, "unexpected parse state B");
    const m = s === K && i[a + 1].startsWith("/>") ? " " : "";
    r += s === ct ? d + Ai : c >= 0 ? (n.push(f), d.slice(0, c) + je + d.slice(c) + F + m) : d + F + (c === -2 ? a : m);
  }
  const l = r + (i[e] || "<?>") + (t === xt ? "</svg>" : t === ie ? "</math>" : "");
  return [Ke(i, l), n];
};
class mt {
  constructor({ strings: t, ["_$litType$"]: e }, n) {
    this.parts = [];
    let r, o = 0, s = 0;
    const l = t.length - 1, a = this.parts, [d, c] = qi(t, e);
    if (this.el = mt.createElement(d, n), Y.currentNode = this.el.content, e === xt || e === ie) {
      const f = this.el.content.firstChild;
      f.replaceWith(...f.childNodes);
    }
    for (; (r = Y.nextNode()) !== null && a.length < l; ) {
      if (r.nodeType === 1) {
        {
          const f = r.localName;
          if (/^(?:textarea|template)$/i.test(f) && r.innerHTML.includes(F)) {
            const h = `Expressions are not supported inside \`${f}\` elements. See https://lit.dev/msg/expression-in-${f} for more information.`;
            if (f === "template")
              throw new Error(h);
            ft("", h);
          }
        }
        if (r.hasAttributes())
          for (const f of r.getAttributeNames())
            if (f.endsWith(je)) {
              const h = c[s++], m = r.getAttribute(f).split(F), y = /([.?@])?(.*)/.exec(h);
              a.push({
                type: he,
                index: o,
                name: y[2],
                strings: m,
                ctor: y[1] === "." ? Hi : y[1] === "?" ? Wi : y[1] === "@" ? Ji : Lt
              }), r.removeAttribute(f);
            } else f.startsWith(F) && (a.push({
              type: fe,
              index: o
            }), r.removeAttribute(f));
        if (We.test(r.tagName)) {
          const f = r.textContent.split(F), h = f.length - 1;
          if (h > 0) {
            r.textContent = Tt ? Tt.emptyScript : "";
            for (let u = 0; u < h; u++)
              r.append(f[u], gt()), Y.nextNode(), a.push({ type: Nt, index: ++o });
            r.append(f[h], gt());
          }
        }
      } else if (r.nodeType === 8)
        if (r.data === He)
          a.push({ type: Nt, index: o });
        else {
          let h = -1;
          for (; (h = r.data.indexOf(F, h + 1)) !== -1; )
            a.push({ type: Fi, index: o }), h += F.length - 1;
        }
      o++;
    }
    if (c.length !== s)
      throw new Error('Detected duplicate attribute bindings. This occurs if your template has duplicate attributes on an element tag. For example "<input ?disabled=${true} ?disabled=${false}>" contains a duplicate "disabled" attribute. The error was detected in the following template: \n`' + t.join("${...}") + "`");
    v && v({
      kind: "template prep",
      template: this,
      clonableTemplate: this.el,
      parts: this.parts,
      strings: t
    });
  }
  // Overridden via `litHtmlPolyfillSupport` to provide platform support.
  /** @nocollapse */
  static createElement(t, e) {
    const n = G.createElement("template");
    return n.innerHTML = t, n;
  }
}
function it(i, t, e = i, n) {
  var s, l;
  if (t === Q)
    return t;
  let r = n !== void 0 ? (s = e.__directives) == null ? void 0 : s[n] : e.__directive;
  const o = pt(t) ? void 0 : (
    // This property needs to remain unminified.
    t._$litDirective$
  );
  return (r == null ? void 0 : r.constructor) !== o && ((l = r == null ? void 0 : r._$notifyDirectiveConnectionChanged) == null || l.call(r, !1), o === void 0 ? r = void 0 : (r = new o(i), r._$initialize(i, e, n)), n !== void 0 ? (e.__directives ?? (e.__directives = []))[n] = r : e.__directive = r), r !== void 0 && (t = it(i, r._$resolve(i, t.values), r, n)), t;
}
class ji {
  constructor(t, e) {
    this._$parts = [], this._$disconnectableChildren = void 0, this._$template = t, this._$parent = e;
  }
  // Called by ChildPart parentNode getter
  get parentNode() {
    return this._$parent.parentNode;
  }
  // See comment in Disconnectable interface for why this is a getter
  get _$isConnected() {
    return this._$parent._$isConnected;
  }
  // This method is separate from the constructor because we need to return a
  // DocumentFragment and we don't want to hold onto it with an instance field.
  _clone(t) {
    const { el: { content: e }, parts: n } = this._$template, r = ((t == null ? void 0 : t.creationScope) ?? G).importNode(e, !0);
    Y.currentNode = r;
    let o = Y.nextNode(), s = 0, l = 0, a = n[0];
    for (; a !== void 0; ) {
      if (s === a.index) {
        let d;
        a.type === Nt ? d = new yt(o, o.nextSibling, this, t) : a.type === he ? d = new a.ctor(o, a.name, a.strings, this, t) : a.type === fe && (d = new Ki(o, this, t)), this._$parts.push(d), a = n[++l];
      }
      s !== (a == null ? void 0 : a.index) && (o = Y.nextNode(), s++);
    }
    return Y.currentNode = G, r;
  }
  _update(t) {
    let e = 0;
    for (const n of this._$parts)
      n !== void 0 && (v && v({
        kind: "set part",
        part: n,
        value: t[e],
        valueIndex: e,
        values: t,
        templateInstance: this
      }), n.strings !== void 0 ? (n._$setValue(t, n, e), e += n.strings.length - 2) : n._$setValue(t[e])), e++;
  }
}
class yt {
  // See comment in Disconnectable interface for why this is a getter
  get _$isConnected() {
    var t;
    return ((t = this._$parent) == null ? void 0 : t._$isConnected) ?? this.__isConnected;
  }
  constructor(t, e, n, r) {
    this.type = Nt, this._$committedValue = P, this._$disconnectableChildren = void 0, this._$startNode = t, this._$endNode = e, this._$parent = n, this.options = r, this.__isConnected = (r == null ? void 0 : r.isConnected) ?? !0, this._textSanitizer = void 0;
  }
  /**
   * The parent node into which the part renders its content.
   *
   * A ChildPart's content consists of a range of adjacent child nodes of
   * `.parentNode`, possibly bordered by 'marker nodes' (`.startNode` and
   * `.endNode`).
   *
   * - If both `.startNode` and `.endNode` are non-null, then the part's content
   * consists of all siblings between `.startNode` and `.endNode`, exclusively.
   *
   * - If `.startNode` is non-null but `.endNode` is null, then the part's
   * content consists of all siblings following `.startNode`, up to and
   * including the last child of `.parentNode`. If `.endNode` is non-null, then
   * `.startNode` will always be non-null.
   *
   * - If both `.endNode` and `.startNode` are null, then the part's content
   * consists of all child nodes of `.parentNode`.
   */
  get parentNode() {
    let t = L(this._$startNode).parentNode;
    const e = this._$parent;
    return e !== void 0 && (t == null ? void 0 : t.nodeType) === 11 && (t = e.parentNode), t;
  }
  /**
   * The part's leading marker node, if any. See `.parentNode` for more
   * information.
   */
  get startNode() {
    return this._$startNode;
  }
  /**
   * The part's trailing marker node, if any. See `.parentNode` for more
   * information.
   */
  get endNode() {
    return this._$endNode;
  }
  _$setValue(t, e = this) {
    var n;
    if (this.parentNode === null)
      throw new Error("This `ChildPart` has no `parentNode` and therefore cannot accept a value. This likely means the element containing the part was manipulated in an unsupported way outside of Lit's control such that the part's marker nodes were ejected from DOM. For example, setting the element's `innerHTML` or `textContent` can do this.");
    if (t = it(this, t, e), pt(t))
      t === P || t == null || t === "" ? (this._$committedValue !== P && (v && v({
        kind: "commit nothing to child",
        start: this._$startNode,
        end: this._$endNode,
        parent: this._$parent,
        options: this.options
      }), this._$clear()), this._$committedValue = P) : t !== this._$committedValue && t !== Q && this._commitText(t);
    else if (t._$litType$ !== void 0)
      this._commitTemplateResult(t);
    else if (t.nodeType !== void 0) {
      if (((n = this.options) == null ? void 0 : n.host) === t) {
        this._commitText("[probable mistake: rendered a template's host in itself (commonly caused by writing ${this} in a template]"), console.warn("Attempted to render the template host", t, "inside itself. This is almost always a mistake, and in dev mode ", "we render some warning text. In production however, we'll ", "render it, which will usually result in an error, and sometimes ", "in the element disappearing from the DOM.");
        return;
      }
      this._commitNode(t);
    } else Mi(t) ? this._commitIterable(t) : this._commitText(t);
  }
  _insert(t) {
    return L(L(this._$startNode).parentNode).insertBefore(t, this._$endNode);
  }
  _commitNode(t) {
    var e;
    if (this._$committedValue !== t) {
      if (this._$clear(), Z !== It) {
        const n = (e = this._$startNode.parentNode) == null ? void 0 : e.nodeName;
        if (n === "STYLE" || n === "SCRIPT") {
          let r = "Forbidden";
          throw n === "STYLE" ? r = "Lit does not support binding inside style nodes. This is a security risk, as style injection attacks can exfiltrate data and spoof UIs. Consider instead using css`...` literals to compose styles, and do dynamic styling with css custom properties, ::parts, <slot>s, and by mutating the DOM rather than stylesheets." : r = "Lit does not support binding inside script nodes. This is a security risk, as it could allow arbitrary code execution.", new Error(r);
        }
      }
      v && v({
        kind: "commit node",
        start: this._$startNode,
        parent: this._$parent,
        value: t,
        options: this.options
      }), this._$committedValue = this._insert(t);
    }
  }
  _commitText(t) {
    if (this._$committedValue !== P && pt(this._$committedValue)) {
      const e = L(this._$startNode).nextSibling;
      this._textSanitizer === void 0 && (this._textSanitizer = ee(e, "data", "property")), t = this._textSanitizer(t), v && v({
        kind: "commit text",
        node: e,
        value: t,
        options: this.options
      }), e.data = t;
    } else {
      const e = G.createTextNode("");
      this._commitNode(e), this._textSanitizer === void 0 && (this._textSanitizer = ee(e, "data", "property")), t = this._textSanitizer(t), v && v({
        kind: "commit text",
        node: e,
        value: t,
        options: this.options
      }), e.data = t;
    }
    this._$committedValue = t;
  }
  _commitTemplateResult(t) {
    var o;
    const { values: e, ["_$litType$"]: n } = t, r = typeof n == "number" ? this._$getTemplate(t) : (n.el === void 0 && (n.el = mt.createElement(Ke(n.h, n.h[0]), this.options)), n);
    if (((o = this._$committedValue) == null ? void 0 : o._$template) === r)
      v && v({
        kind: "template updating",
        template: r,
        instance: this._$committedValue,
        parts: this._$committedValue._$parts,
        options: this.options,
        values: e
      }), this._$committedValue._update(e);
    else {
      const s = new ji(r, this), l = s._clone(this.options);
      v && v({
        kind: "template instantiated",
        template: r,
        instance: s,
        parts: s._$parts,
        options: this.options,
        fragment: l,
        values: e
      }), s._update(e), v && v({
        kind: "template instantiated and updated",
        template: r,
        instance: s,
        parts: s._$parts,
        options: this.options,
        fragment: l,
        values: e
      }), this._commitNode(l), this._$committedValue = s;
    }
  }
  // Overridden via `litHtmlPolyfillSupport` to provide platform support.
  /** @internal */
  _$getTemplate(t) {
    let e = Be.get(t.strings);
    return e === void 0 && Be.set(t.strings, e = new mt(t)), e;
  }
  _commitIterable(t) {
    ue(this._$committedValue) || (this._$committedValue = [], this._$clear());
    const e = this._$committedValue;
    let n = 0, r;
    for (const o of t)
      n === e.length ? e.push(r = new yt(this._insert(gt()), this._insert(gt()), this, this.options)) : r = e[n], r._$setValue(o), n++;
    n < e.length && (this._$clear(r && L(r._$endNode).nextSibling, n), e.length = n);
  }
  /**
   * Removes the nodes contained within this Part from the DOM.
   *
   * @param start Start node to clear from, for clearing a subset of the part's
   *     DOM (used when truncating iterables)
   * @param from  When `start` is specified, the index within the iterable from
   *     which ChildParts are being removed, used for disconnecting directives in
   *     those Parts.
   *
   * @internal
   */
  _$clear(t = L(this._$startNode).nextSibling, e) {
    var n;
    for ((n = this._$notifyConnectionChanged) == null || n.call(this, !1, !0, e); t && t !== this._$endNode; ) {
      const r = L(t).nextSibling;
      L(t).remove(), t = r;
    }
  }
  /**
   * Implementation of RootPart's `isConnected`. Note that this method
   * should only be called on `RootPart`s (the `ChildPart` returned from a
   * top-level `render()` call). It has no effect on non-root ChildParts.
   * @param isConnected Whether to set
   * @internal
   */
  setConnected(t) {
    var e;
    if (this._$parent === void 0)
      this.__isConnected = t, (e = this._$notifyConnectionChanged) == null || e.call(this, t);
    else
      throw new Error("part.setConnected() may only be called on a RootPart returned from render().");
  }
}
class Lt {
  get tagName() {
    return this.element.tagName;
  }
  // See comment in Disconnectable interface for why this is a getter
  get _$isConnected() {
    return this._$parent._$isConnected;
  }
  constructor(t, e, n, r, o) {
    this.type = he, this._$committedValue = P, this._$disconnectableChildren = void 0, this.element = t, this.name = e, this._$parent = r, this.options = o, n.length > 2 || n[0] !== "" || n[1] !== "" ? (this._$committedValue = new Array(n.length - 1).fill(new String()), this.strings = n) : this._$committedValue = P, this._sanitizer = void 0;
  }
  /**
   * Sets the value of this part by resolving the value from possibly multiple
   * values and static strings and committing it to the DOM.
   * If this part is single-valued, `this._strings` will be undefined, and the
   * method will be called with a single value argument. If this part is
   * multi-value, `this._strings` will be defined, and the method is called
   * with the value array of the part's owning TemplateInstance, and an offset
   * into the value array from which the values should be read.
   * This method is overloaded this way to eliminate short-lived array slices
   * of the template instance values, and allow a fast-path for single-valued
   * parts.
   *
   * @param value The part value, or an array of values for multi-valued parts
   * @param valueIndex the index to start reading values from. `undefined` for
   *   single-valued parts
   * @param noCommit causes the part to not commit its value to the DOM. Used
   *   in hydration to prime attribute parts with their first-rendered value,
   *   but not set the attribute, and in SSR to no-op the DOM operation and
   *   capture the value for serialization.
   *
   * @internal
   */
  _$setValue(t, e = this, n, r) {
    const o = this.strings;
    let s = !1;
    if (o === void 0)
      t = it(this, t, e, 0), s = !pt(t) || t !== this._$committedValue && t !== Q, s && (this._$committedValue = t);
    else {
      const l = t;
      t = o[0];
      let a, d;
      for (a = 0; a < o.length - 1; a++)
        d = it(this, l[n + a], e, a), d === Q && (d = this._$committedValue[a]), s || (s = !pt(d) || d !== this._$committedValue[a]), d === P ? t = P : t !== P && (t += (d ?? "") + o[a + 1]), this._$committedValue[a] = d;
    }
    s && !r && this._commitValue(t);
  }
  /** @internal */
  _commitValue(t) {
    t === P ? L(this.element).removeAttribute(this.name) : (this._sanitizer === void 0 && (this._sanitizer = Z(this.element, this.name, "attribute")), t = this._sanitizer(t ?? ""), v && v({
      kind: "commit attribute",
      element: this.element,
      name: this.name,
      value: t,
      options: this.options
    }), L(this.element).setAttribute(this.name, t ?? ""));
  }
}
class Hi extends Lt {
  constructor() {
    super(...arguments), this.type = Di;
  }
  /** @internal */
  _commitValue(t) {
    this._sanitizer === void 0 && (this._sanitizer = Z(this.element, this.name, "property")), t = this._sanitizer(t), v && v({
      kind: "commit property",
      element: this.element,
      name: this.name,
      value: t,
      options: this.options
    }), this.element[this.name] = t === P ? void 0 : t;
  }
}
class Wi extends Lt {
  constructor() {
    super(...arguments), this.type = zi;
  }
  /** @internal */
  _commitValue(t) {
    v && v({
      kind: "commit boolean attribute",
      element: this.element,
      name: this.name,
      value: !!(t && t !== P),
      options: this.options
    }), L(this.element).toggleAttribute(this.name, !!t && t !== P);
  }
}
class Ji extends Lt {
  constructor(t, e, n, r, o) {
    if (super(t, e, n, r, o), this.type = Vi, this.strings !== void 0)
      throw new Error(`A \`<${t.localName}>\` has a \`@${e}=...\` listener with invalid content. Event listeners in templates must have exactly one expression and no surrounding text.`);
  }
  // EventPart does not use the base _$setValue/_resolveValue implementation
  // since the dirty checking is more complex
  /** @internal */
  _$setValue(t, e = this) {
    if (t = it(this, t, e, 0) ?? P, t === Q)
      return;
    const n = this._$committedValue, r = t === P && n !== P || t.capture !== n.capture || t.once !== n.once || t.passive !== n.passive, o = t !== P && (n === P || r);
    v && v({
      kind: "commit event listener",
      element: this.element,
      name: this.name,
      value: t,
      options: this.options,
      removeListener: r,
      addListener: o,
      oldListener: n
    }), r && this.element.removeEventListener(this.name, this, n), o && this.element.addEventListener(this.name, this, t), this._$committedValue = t;
  }
  handleEvent(t) {
    var e;
    typeof this._$committedValue == "function" ? this._$committedValue.call(((e = this.options) == null ? void 0 : e.host) ?? this.element, t) : this._$committedValue.handleEvent(t);
  }
}
class Ki {
  constructor(t, e, n) {
    this.element = t, this.type = fe, this._$disconnectableChildren = void 0, this._$parent = e, this.options = n;
  }
  // See comment in Disconnectable interface for why this is a getter
  get _$isConnected() {
    return this._$parent._$isConnected;
  }
  _$setValue(t) {
    v && v({
      kind: "commit to element binding",
      element: this.element,
      value: t,
      options: this.options
    }), it(this, t);
  }
}
const Kt = M.litHtmlPolyfillSupportDevMode;
Kt == null || Kt(mt, yt);
(M.litHtmlVersions ?? (M.litHtmlVersions = [])).push("3.2.0");
M.litHtmlVersions.length > 1 && ft("multiple-versions", "Multiple versions of Lit loaded. Loading multiple versions is not recommended.");
const Pt = (i, t, e) => {
  if (t == null)
    throw new TypeError(`The container to render into may not be ${t}`);
  const n = $i++, r = (e == null ? void 0 : e.renderBefore) ?? t;
  let o = r._$litPart$;
  if (v && v({
    kind: "begin render",
    id: n,
    value: i,
    container: t,
    options: e,
    part: o
  }), o === void 0) {
    const s = (e == null ? void 0 : e.renderBefore) ?? null;
    r._$litPart$ = o = new yt(t.insertBefore(gt(), s), s, void 0, e ?? {});
  }
  return o._$setValue(i), v && v({
    kind: "end render",
    id: n,
    value: i,
    container: t,
    options: e,
    part: o
  }), o;
};
Pt.setSanitizer = xi, Pt.createSanitizer = ee, Pt._testOnlyClearSanitizerFactoryDoNotCallOrElse = Ni;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Yi = (i, t) => i;
let Ye;
{
  const i = globalThis.litIssuedWarnings ?? (globalThis.litIssuedWarnings = /* @__PURE__ */ new Set());
  Ye = (t, e) => {
    e += ` See https://lit.dev/msg/${t} for more information.`, i.has(e) || (console.warn(e), i.add(e));
  };
}
class W extends q {
  constructor() {
    super(...arguments), this.renderOptions = { host: this }, this.__childPart = void 0;
  }
  /**
   * @category rendering
   */
  createRenderRoot() {
    var e;
    const t = super.createRenderRoot();
    return (e = this.renderOptions).renderBefore ?? (e.renderBefore = t.firstChild), t;
  }
  /**
   * Updates the element. This method reflects property values to attributes
   * and calls `render` to render DOM via lit-html. Setting properties inside
   * this method will *not* trigger another update.
   * @param changedProperties Map of changed properties with old values
   * @category updates
   */
  update(t) {
    const e = this.render();
    this.hasUpdated || (this.renderOptions.isConnected = this.isConnected), super.update(t), this.__childPart = Pt(e, this.renderRoot, this.renderOptions);
  }
  /**
   * Invoked when the component is added to the document's DOM.
   *
   * In `connectedCallback()` you should setup tasks that should only occur when
   * the element is connected to the document. The most common of these is
   * adding event listeners to nodes external to the element, like a keydown
   * event handler added to the window.
   *
   * ```ts
   * connectedCallback() {
   *   super.connectedCallback();
   *   addEventListener('keydown', this._handleKeydown);
   * }
   * ```
   *
   * Typically, anything done in `connectedCallback()` should be undone when the
   * element is disconnected, in `disconnectedCallback()`.
   *
   * @category lifecycle
   */
  connectedCallback() {
    var t;
    super.connectedCallback(), (t = this.__childPart) == null || t.setConnected(!0);
  }
  /**
   * Invoked when the component is removed from the document's DOM.
   *
   * This callback is the main signal to the element that it may no longer be
   * used. `disconnectedCallback()` should ensure that nothing is holding a
   * reference to the element (such as event listeners added to nodes external
   * to the element), so that it is free to be garbage collected.
   *
   * ```ts
   * disconnectedCallback() {
   *   super.disconnectedCallback();
   *   window.removeEventListener('keydown', this._handleKeydown);
   * }
   * ```
   *
   * An element may be re-connected after being disconnected.
   *
   * @category lifecycle
   */
  disconnectedCallback() {
    var t;
    super.disconnectedCallback(), (t = this.__childPart) == null || t.setConnected(!1);
  }
  /**
   * Invoked on each update to perform rendering tasks. This method may return
   * any value renderable by lit-html's `ChildPart` - typically a
   * `TemplateResult`. Setting properties inside this method will *not* trigger
   * the element to update.
   * @category rendering
   */
  render() {
    return Q;
  }
}
W._$litElement$ = !0;
W[Yi("finalized")] = !0;
var Fe;
(Fe = globalThis.litElementHydrateSupport) == null || Fe.call(globalThis, { LitElement: W });
const Yt = globalThis.litElementPolyfillSupportDevMode;
Yt == null || Yt({ LitElement: W });
(globalThis.litElementVersions ?? (globalThis.litElementVersions = [])).push("4.1.0");
globalThis.litElementVersions.length > 1 && Ye("multiple-versions", "Multiple versions of Lit loaded. Loading multiple versions is not recommended.");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Ut = (i) => (t, e) => {
  e !== void 0 ? e.addInitializer(() => {
    customElements.define(i, t);
  }) : customElements.define(i, t);
};
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
let Ge;
{
  const i = globalThis.litIssuedWarnings ?? (globalThis.litIssuedWarnings = /* @__PURE__ */ new Set());
  Ge = (t, e) => {
    e += ` See https://lit.dev/msg/${t} for more information.`, i.has(e) || (console.warn(e), i.add(e));
  };
}
const Gi = (i, t, e) => {
  const n = t.hasOwnProperty(e);
  return t.constructor.createProperty(e, n ? { ...i, wrapped: !0 } : i), n ? Object.getOwnPropertyDescriptor(t, e) : void 0;
}, Qi = {
  attribute: !0,
  type: String,
  converter: $t,
  reflect: !1,
  hasChanged: ce
}, Zi = (i = Qi, t, e) => {
  const { kind: n, metadata: r } = e;
  r == null && Ge("missing-class-metadata", `The class ${t} is missing decorator metadata. This could mean that you're using a compiler that supports decorators but doesn't support decorator metadata, such as TypeScript 5.1. Please update your compiler.`);
  let o = globalThis.litPropertyMetadata.get(r);
  if (o === void 0 && globalThis.litPropertyMetadata.set(r, o = /* @__PURE__ */ new Map()), o.set(e.name, i), n === "accessor") {
    const { name: s } = e;
    return {
      set(l) {
        const a = t.get.call(this);
        t.set.call(this, l), this.requestUpdate(s, a, i);
      },
      init(l) {
        return l !== void 0 && this._$changeProperty(s, void 0, i), l;
      }
    };
  } else if (n === "setter") {
    const { name: s } = e;
    return function(l) {
      const a = this[s];
      t.call(this, l), this.requestUpdate(s, a, i);
    };
  }
  throw new Error(`Unsupported decorator location: ${n}`);
};
function _(i) {
  return (t, e) => typeof e == "object" ? Zi(i, t, e) : Gi(i, t, e);
}
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function lt(i) {
  return _({
    ...i,
    // Add both `state` and `attribute` because we found a third party
    // controller that is keying off of PropertyOptions.state to determine
    // whether a field is a private internal property or not.
    state: !0,
    attribute: !1
  });
}
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Xi = (i, t, e) => (e.configurable = !0, e.enumerable = !0, // We check for Reflect.decorate each time, in case the zombiefill
// is applied via lazy loading some Angular code.
Reflect.decorate && typeof t != "object" && Object.defineProperty(i, t, e), e);
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
let Qe;
{
  const i = globalThis.litIssuedWarnings ?? (globalThis.litIssuedWarnings = /* @__PURE__ */ new Set());
  Qe = (t, e) => {
    e += t ? ` See https://lit.dev/msg/${t} for more information.` : "", i.has(e) || (console.warn(e), i.add(e));
  };
}
function Ze(i, t) {
  return (e, n, r) => {
    const o = (s) => {
      var a;
      const l = ((a = s.renderRoot) == null ? void 0 : a.querySelector(i)) ?? null;
      if (l === null && t && !s.hasUpdated) {
        const d = typeof n == "object" ? n.name : n;
        Qe("", `@query'd field ${JSON.stringify(String(d))} with the 'cache' flag set for selector '${i}' has been accessed before the first update and returned null. This is expected if the renderRoot tree has not been provided beforehand (e.g. via Declarative Shadow DOM). Therefore the value hasn't been cached.`);
      }
      return l;
    };
    return Xi(e, n, {
      get() {
        return o(this);
      }
    });
  };
}
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const tn = {
  ATTRIBUTE: 1,
  CHILD: 2,
  PROPERTY: 3,
  BOOLEAN_ATTRIBUTE: 4,
  EVENT: 5,
  ELEMENT: 6
}, en = (i) => (...t) => ({
  // This property needs to remain unminified.
  _$litDirective$: i,
  values: t
});
class nn {
  constructor(t) {
  }
  // See comment in Disconnectable interface for why this is a getter
  get _$isConnected() {
    return this._$parent._$isConnected;
  }
  /** @internal */
  _$initialize(t, e, n) {
    this.__part = t, this._$parent = e, this.__attributeIndex = n;
  }
  /** @internal */
  _$resolve(t, e) {
    return this.update(t, e);
  }
  update(t, e) {
    return this.render(...e);
  }
}
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
class rn extends nn {
  constructor(t) {
    var e;
    if (super(t), t.type !== tn.ATTRIBUTE || t.name !== "class" || ((e = t.strings) == null ? void 0 : e.length) > 2)
      throw new Error("`classMap()` can only be used in the `class` attribute and must be the only part in the attribute.");
  }
  render(t) {
    return " " + Object.keys(t).filter((e) => t[e]).join(" ") + " ";
  }
  update(t, [e]) {
    var r, o;
    if (this._previousClasses === void 0) {
      this._previousClasses = /* @__PURE__ */ new Set(), t.strings !== void 0 && (this._staticClasses = new Set(t.strings.join(" ").split(/\s/).filter((s) => s !== "")));
      for (const s in e)
        e[s] && !((r = this._staticClasses) != null && r.has(s)) && this._previousClasses.add(s);
      return this.render(e);
    }
    const n = t.element.classList;
    for (const s of this._previousClasses)
      s in e || (n.remove(s), this._previousClasses.delete(s));
    for (const s in e) {
      const l = !!e[s];
      l !== this._previousClasses.has(s) && !((o = this._staticClasses) != null && o.has(s)) && (l ? (n.add(s), this._previousClasses.add(s)) : (n.remove(s), this._previousClasses.delete(s)));
    }
    return Q;
  }
}
const Xe = en(rn), ne = "close", on = Rt`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.645 4.367a1.209 1.209 0 0 0-1.711 0L12 10.29 6.066 4.355a1.208 1.208 0 0 0-1.711 0 1.208 1.208 0 0 0 0 1.711L10.289 12l-5.934 5.934a1.209 1.209 0 0 0 0 1.711 1.209 1.209 0 0 0 1.711 0L12 13.711l5.934 5.934a1.209 1.209 0 0 0 1.711 0 1.209 1.209 0 0 0 0-1.711L13.711 12l5.934-5.934a1.216 1.216 0 0 0 0-1.699Z"/>
  </svg>
`, sn = Bt`
    :host {
        --default-dialog-overlay: rgba(0, 0, 0, 0.8);
        --default-dialog-z-index: 1000;
        --default-dialog-width: 400px;
        --default-dialog-height: 430px;
        --default-dialog-bg: #ffffff;
        --default-dialog-header-color: #0B522E;
        --default-dialog-header-height: 52px;
        --default-dialog-header-logo-width: 144px;
        --default-dialog-header-logo-height: 30px;
        --default-dialog-header-logo-margin: auto;
        --default-dialog-header-close-fill: #e7e7e7;
        --default-dialog-header-close-width: 24px;
        --default-dialog-header-close-height: 24px;
        --default-dialog-header-close-top: 14px;
        --default-dialog-header-close-right: 24px;
        --default-dialog-header-close-transition: fill 0.2s ease-in-out;
        --default-dialog-header-close-hover-transform: none;
        --default-dialog-header-close-hover-fill: #ffffff;
        --default-dialog-background: #ffffff;
        --default-dialog-padding: 20px 32px;
        --default-dialog-content-margin: 0 0 24px;
    }
    .wrapper {
        display: flex;
        position: fixed;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: scroll;
        z-index: var(--dialog-z-index, var(--default-dialog-z-index));
        background: var(--dialog-overlay, var(--default-dialog-overlay));
    }
    .dialog {
        width: var(--dialog-width, var(--default-dialog-width));
        display: flex;
        flex-direction: column;
        margin: auto 0;
        background-color: var(--dialog-bg, var(--default-dialog-bg));
    }
    .header {
        min-height: var(--dialog-header-height, var(--default-dialog-header-height));
        background-color: var(--dialog-header-color, var(--default-dialog-header-color));
        display: flex;
        justify-content: center;
        align-content: center;
        position: relative;
    }
    .header-logo {
        width: var(--dialog-header-logo-width, var(--default-dialog-header-logo-width));
        height: var(--dialog-header-logo-height, var(--default-dialog-header-logo-height));
        margin: var(--dialog-header-logo-margin, var(--default-dialog-header-logo-margin));
    }
    .close {
        position: absolute;
        cursor: pointer;
        width: var( --dialog-header-close-width, var( --default-dialog-header-close-width));
        height: var(--dialog-header-close-height, var(--default-dialog-header-close-height));
        top: var(--dialog-header-close-top, var(--default-dialog-header-close-top));
        right: var(--dialog-header-close-right, var(--default-dialog-header-close-right));
    }
    .close svg {
        fill: var(--dialog-header-close-fill, var(--default-dialog-header-close-fill));
        transition: var(--dialog-header-close-transition, var(--default-dialog-header-close-transition));
    }
    .close:hover svg {
        transform: var(--dialog-header-close-hover-transform, var(--default-dialog-header-close-hover-transform));
        fill: var(--dialog-header-close-hover-fill, var(--default-dialog-header-close-hover-fill));
    }
    .main {
        min-height: var(--dialog-height, var(--default-dialog-height));
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        text-align: center;
        padding: var(--dialog-padding, var(--default-dialog-padding));
        background-color: var(--dialog-background, var(--default-dialog-background));
    }
    .wrap {
        display: flex;
        flex-grow: 1;
    }
    .content {
        margin: var(--dialog-content-margin, var(--default-dialog-content-margin));
    }
    @media (max-width: 540px) {
        .wrapper {
            background-color: #ffffff;
        }
        .dialog {
            width: 100%;
            height: 100%;
            justify-content: space-between;
        }
    }
    @media (max-width: 1024px) and (orientation: landscape) {
        .wrapper {
            padding: 10px 0;
        }
    }
`, ge = Bt`
    @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap');
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Rubik', sans-serif;
    }
    button {
        cursor: pointer;
        border: none;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    }
    a {
        text-decoration: none;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    }
`, E = {
  ShowRegistration: "showregistration",
  SuccessLogin: "successlogin",
  DialogOpen: "dialogopen",
  DialogClosed: "dialogclosed",
  AuthFailed: "authfailed",
  SomethingWentWrong: "somethingwentwrong",
  InvalidParams: "invalidparams",
  UnknownError: "unknownerror",
  AccountNotFound: "accountnotfound",
  IdentNotFound: "identnotfound",
  NeedFieldsFill: "needfieldsfill",
  BankIdUnavailable: "bankidunavailable",
  NetworkError: "networkerror",
  AuthError: "autherror"
}, pe = (i, t) => (t == null ? void 0 : t[i]) || i, ut = {
  400: E.InvalidParams,
  500: E.UnknownError,
  5002: E.AccountNotFound,
  5051: E.ShowRegistration,
  // doesn't show an error, but registration flow instead
  5053: E.IdentNotFound,
  5054: E.NeedFieldsFill,
  6363: E.BankIdUnavailable
}, Ie = {
  [E.InvalidParams]: 400,
  [E.UnknownError]: 500,
  [E.NetworkError]: -1,
  [E.AccountNotFound]: 5002,
  [E.ShowRegistration]: 5051,
  // doesn't show an error, but registration flow instead
  [E.IdentNotFound]: 5053,
  [E.NeedFieldsFill]: 5054,
  [E.BankIdUnavailable]: 6363
}, an = {
  [-1]: {
    title: "auth_bankid.error.bad_request.400",
    text: "auth_bankid.body_error_sign_in"
  },
  400: {
    title: "auth_bankid.error.bad_request.400",
    text: "auth_bankid.error.invalid_params"
  },
  500: {
    title: "auth_bankid.error.internal_error.500",
    text: "auth_bankid.error.unknown_error"
  },
  5002: {
    title: "auth_bankid.error.account_not_found.5002",
    text: "auth_bankid.error.account_not_found"
  },
  5053: {
    title: "auth_bankid.error.ident_not_found.5053",
    text: "auth_bankid.error.ident_not_found"
  },
  5054: {
    title: "auth_bankid.error.ident_not_found.5054",
    text: "auth_bankid.error.ident_not_found"
  },
  6363: {
    title: "auth_bankid.error.internal_error.500",
    text: "auth_bankid.error.unknown_error"
  }
}, ln = "en", dn = /^\/(?<lang>[a-z]{2,2})\//, ti = () => {
  var i, t, e;
  return ((e = (t = (i = window.location.pathname) == null ? void 0 : i.match(dn)) == null ? void 0 : t.groups) == null ? void 0 : e.lang) || ln;
};
class tt extends Error {
}
const cn = () => window.navigator.userAgent.match(/CriOS/i) || window.chrome && !window.opr, ei = () => cn() ? "googlechrome://" : "null", Re = () => /android|iPad|iPhone|iPod/i.test(navigator.userAgent);
var un = Object.defineProperty, hn = Object.getOwnPropertyDescriptor, _t = (i, t, e, n) => {
  for (var r = n > 1 ? void 0 : n ? hn(t, e) : t, o = i.length - 1, s; o >= 0; o--)
    (s = i[o]) && (r = (n ? s(t, e, r) : s(r)) || r);
  return n && r && un(t, e, r), r;
};
let X = class extends W {
  constructor() {
    super(...arguments), this.logoUrl = "";
  }
  i(i) {
    return pe(i, this.translations);
  }
  render() {
    const i = this.dialog.config.closeButton !== !1 && D`
            <div class="close" @click="${this.dialog.close.bind(this, { closeEventName: ne })}">
                ${on}
            </div>
        `;
    return D`
            <div 
                class="${Xe({ wrapper: !0, [this.dialog.config.class || ""]: !0 })}"
                @click="${this.onBackdropClick.bind(this)}"
            >
                <div class="dialog">
                  <div class="header">
                    <img class="header-logo" src="${this.logoUrl}" alt="">
                    ${i}
                  </div>
                  <div class="main">
                    <div class="wrap">${this.dialog.config.body}</div>
                  </div>
                </div>
            </div>`;
  }
  onBackdropClick(i) {
    i.target === i.currentTarget && this.backdropclose && this.dialog.close({ closeEventName: ne });
  }
};
X.styles = [ge, sn];
_t([
  _({ type: Object })
], X.prototype, "dialog", 2);
_t([
  _()
], X.prototype, "logoUrl", 2);
_t([
  _({ type: Object })
], X.prototype, "translations", 2);
_t([
  _()
], X.prototype, "backdropclose", 2);
X = _t([
  Ut("bsc-dialog")
], X);
var fn = Object.defineProperty, gn = Object.getOwnPropertyDescriptor, wt = (i, t, e, n) => {
  for (var r = n > 1 ? void 0 : n ? gn(t, e) : t, o = i.length - 1, s; o >= 0; o--)
    (s = i[o]) && (r = (n ? s(t, e, r) : s(r)) || r);
  return n && r && fn(t, e, r), r;
};
let nt = class extends W {
  constructor() {
    super(...arguments), this.dialogs = {}, this.logoUrl = "", this.handleEscape = (i) => {
      const t = i || window.event, e = t.keyCode === 27;
      ("key" in t && ["Escape", "Esc"].includes(t.key) || e) && this.closeTop();
    };
  }
  connectedCallback() {
    super.connectedCallback(), window.addEventListener("keydown", this.handleEscape);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), window.removeEventListener("keydown", this.handleEscape);
  }
  render() {
    const i = Object.getOwnPropertySymbols(this.dialogs).map(
      (t) => D`
                <bsc-dialog 
                    .dialog="${this.dialogs[t]}"
                    logoUrl="${this.logoUrl}"
                    translations="${JSON.stringify(this.translations)}"
                    backdropclose="${this.backdropclose}"
                ></bsc-dialog>`
    );
    return D`
            <slot></slot>
            ${i}
        `;
  }
  openDialog(i) {
    const t = Symbol("dialog");
    let e;
    const n = new Promise((r) => {
      e = (o) => {
        const { [t]: s, ...l } = this.dialogs;
        s.isOpen = !1, this.dialogs = l, r(o);
      };
    });
    return this.dialogs = {
      ...this.dialogs,
      [t]: {
        id: t,
        isOpen: !0,
        config: i,
        closed: n,
        close: e
      }
    }, this.dialogs[t];
  }
  closeAll() {
    Object.getOwnPropertySymbols(this.dialogs).forEach((i) => this.dialogs[i].close());
  }
  closeTop() {
    const i = Object.getOwnPropertySymbols(this.dialogs);
    if (i != null && i.length) {
      const t = i[i.length - 1];
      this.dialogs[t].close({ closeEventName: ne });
    }
  }
};
wt([
  lt()
], nt.prototype, "dialogs", 2);
wt([
  _()
], nt.prototype, "logoUrl", 2);
wt([
  _()
], nt.prototype, "backdropclose", 2);
wt([
  _({ type: Object })
], nt.prototype, "translations", 2);
nt = wt([
  Ut("bsc-dialogs")
], nt);
const pn = Bt`
    :host {
        --default-bankid-call-button-bg: #FC8D0A;
        --default-bankid-call-button-bg-hover: #f26702;
        --default-bankid-call-button-height: 42px;
        --default-bankid-call-button-height-mobile: 36px;
        --default-bankid-call-button-padding: 0 16px;
        --default-bankid-call-button-radius: 5px;
        --default-bankid-call-button-radius-hover: 5px;
        --default-bankid-call-button-border: none;
        --default-bankid-call-button-border-hover: none;
        --default-bankid-call-button-transition: background-color .2s ease-in-out;
        --default-bankid-call-button-font-size: 16px;
        --default-bankid-call-button-font-size-mobile: 14px;
        --default-bankid-call-button-font-weight: 500;
        --default-bankid-call-button-color: #ffffff;
    }

    .bankid-btn {
        background-color: var(--bankid-call-button-bg, var(--default-bankid-call-button-bg));
        min-height: var(--bankid-call-button-height, var(--default-bankid-call-button-height));
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--bankid-call-button-padding, var(--default-bankid-call-button-padding));
        border-radius: var(--bankid-call-button-radius, var(--default-bankid-call-button-radius));
        transition: var(--bankid-call-button-transition, var(--default-bankid-call-button-transition));
        font-size: var(--bankid-call-button-font-size, var(--default-bankid-call-button-font-size));
        font-weight: var(--bankid-call-button-font-weight, var(--default-bankid-call-button-font-weight));
        color: var(--bankid-call-button-color, var(--default-bankid-call-button-color));
        border: var(--bankid-call-button-border, var(--default-bankid-call-button-border));
    }

    .bankid-btn:hover {
        background-color: var(--bankid-call-button-bg-hover, var(--default-bankid-call-button-bg-hover));
        border-radius: var(--bankid-call-button-radius-hover, var(--default-bankid-call-button-radius-hover));
        border: var(--bankid-call-button-border-hover, var(--default-bankid-call-button-border-hover));
    }
    @media (max-width: 540px) {
        .bankid-btn {
            min-height: var(--bankid-call-button-height-mobile, var(--default-bankid-call-button-height-mobile));
            font-size: var(--bankid-call-button-font-size-mobile, var(--default-bankid-call-button-font-size-mobile));
        }
    }
`;
var mn = Object.defineProperty, bn = Object.getOwnPropertyDescriptor, N = (i, t, e, n) => {
  for (var r = n > 1 ? void 0 : n ? bn(t, e) : t, o = i.length - 1, s; o >= 0; o--)
    (s = i[o]) && (r = (n ? s(t, e, r) : s(r)) || r);
  return n && r && mn(t, e, r), r;
};
let $ = class extends W {
  constructor() {
    super(...arguments), this.redirect = ei(), this.platform = "v2", this.api = `/${ti()}/api/v2`, this.qrbackground = "#0000", this.qrcolor = "#000", this.maxstatusrequests = 300, this.authclosetimeout = 0;
  }
  i(i) {
    return pe(i, this.translations);
  }
  getUrl(i) {
    return i.replace(/\/$/, "");
  }
  render() {
    return D`
            <div>
                <button class="bankid-btn" @click="${this.openDialog}">
                    ${this.i("auth_bankid.signin_btn")}
                </button>

                <bsc-dialogs 
                    id="dialogs"
                    logoUrl="${this.logoUrl}"
                    translations="${JSON.stringify(this.translations)}"
                    backdropclose="${this.backdropclose}"
                ></bsc-dialogs>
            </div>
        `;
  }
  openDialog() {
    const i = this.dialogs.openDialog({
      body: D`
                <bsc-auth-bank-id-content 
                    redirect="${this.redirect}" 
                    api="${this.api}"
                    translations="${this.translations && JSON.stringify(this.translations)}"
                    affiliate="${this.affiliate && JSON.stringify(this.affiliate)}"
                    amount="${this.amount}"
                    currency="${this.currency}"
                    locale="${this.locale}"
                    qa="${this.qa && JSON.stringify(this.qa)}"
                    adformcookieid="${this.adformcookieid}"
                    maxstatusrequests="${this.maxstatusrequests}"
                    qrbackground="${this.qrbackground}"
                    qrcolor="${this.qrcolor}"
                    @showregistration="${() => this.closeDialog(i, E.ShowRegistration)}"
                    @successlogin="${() => this.closeDialog(i, E.SuccessLogin)}"
                ></bsc-auth-bank-id-content>`
    });
    i.closed.then(() => {
      this.sendEvent(E.DialogClosed);
    }), this.sendEvent(E.DialogOpen, { dialog: i });
  }
  closeAll() {
    this.dialogs.closeAll();
  }
  closeDialog(i, t) {
    this.authclosetimeout ? setTimeout(() => i.close({ closeEventName: t }), Number(this.authclosetimeout)) : i.close({ closeEventName: t });
  }
  sendEvent(i, t) {
    this.dispatchEvent(new CustomEvent(i, {
      detail: t || { name: i },
      bubbles: !0,
      composed: !0
    }));
  }
};
$.styles = [ge, pn];
N([
  Ze("#dialogs")
], $.prototype, "dialogs", 2);
N([
  _({ type: Object })
], $.prototype, "translations", 2);
N([
  _()
], $.prototype, "redirect", 2);
N([
  _()
], $.prototype, "platform", 2);
N([
  _()
], $.prototype, "api", 2);
N([
  _()
], $.prototype, "logoUrl", 2);
N([
  _()
], $.prototype, "backdropclose", 2);
N([
  _({ type: Object })
], $.prototype, "affiliate", 2);
N([
  _()
], $.prototype, "amount", 2);
N([
  _()
], $.prototype, "currency", 2);
N([
  _()
], $.prototype, "locale", 2);
N([
  _({ type: Object })
], $.prototype, "qa", 2);
N([
  _()
], $.prototype, "qrbackground", 2);
N([
  _()
], $.prototype, "qrcolor", 2);
N([
  _()
], $.prototype, "adformcookieid", 2);
N([
  _()
], $.prototype, "maxstatusrequests", 2);
N([
  _()
], $.prototype, "authclosetimeout", 2);
$ = N([
  Ut("bsc-auth-bank-id")
], $);
var yn = function() {
  return typeof Promise == "function" && Promise.prototype && Promise.prototype.then;
}, ii = {}, I = {};
let me;
const _n = [
  0,
  // Not used
  26,
  44,
  70,
  100,
  134,
  172,
  196,
  242,
  292,
  346,
  404,
  466,
  532,
  581,
  655,
  733,
  815,
  901,
  991,
  1085,
  1156,
  1258,
  1364,
  1474,
  1588,
  1706,
  1828,
  1921,
  2051,
  2185,
  2323,
  2465,
  2611,
  2761,
  2876,
  3034,
  3196,
  3362,
  3532,
  3706
];
I.getSymbolSize = function(t) {
  if (!t) throw new Error('"version" cannot be null or undefined');
  if (t < 1 || t > 40) throw new Error('"version" should be in range from 1 to 40');
  return t * 4 + 17;
};
I.getSymbolTotalCodewords = function(t) {
  return _n[t];
};
I.getBCHDigit = function(i) {
  let t = 0;
  for (; i !== 0; )
    t++, i >>>= 1;
  return t;
};
I.setToSJISFunction = function(t) {
  if (typeof t != "function")
    throw new Error('"toSJISFunc" is not a valid function.');
  me = t;
};
I.isKanjiModeEnabled = function() {
  return typeof me < "u";
};
I.toSJIS = function(t) {
  return me(t);
};
var Ot = {};
(function(i) {
  i.L = { bit: 1 }, i.M = { bit: 0 }, i.Q = { bit: 3 }, i.H = { bit: 2 };
  function t(e) {
    if (typeof e != "string")
      throw new Error("Param is not a string");
    switch (e.toLowerCase()) {
      case "l":
      case "low":
        return i.L;
      case "m":
      case "medium":
        return i.M;
      case "q":
      case "quartile":
        return i.Q;
      case "h":
      case "high":
        return i.H;
      default:
        throw new Error("Unknown EC Level: " + e);
    }
  }
  i.isValid = function(n) {
    return n && typeof n.bit < "u" && n.bit >= 0 && n.bit < 4;
  }, i.from = function(n, r) {
    if (i.isValid(n))
      return n;
    try {
      return t(n);
    } catch {
      return r;
    }
  };
})(Ot);
function ni() {
  this.buffer = [], this.length = 0;
}
ni.prototype = {
  get: function(i) {
    const t = Math.floor(i / 8);
    return (this.buffer[t] >>> 7 - i % 8 & 1) === 1;
  },
  put: function(i, t) {
    for (let e = 0; e < t; e++)
      this.putBit((i >>> t - e - 1 & 1) === 1);
  },
  getLengthInBits: function() {
    return this.length;
  },
  putBit: function(i) {
    const t = Math.floor(this.length / 8);
    this.buffer.length <= t && this.buffer.push(0), i && (this.buffer[t] |= 128 >>> this.length % 8), this.length++;
  }
};
var wn = ni;
function vt(i) {
  if (!i || i < 1)
    throw new Error("BitMatrix size must be defined and greater than 0");
  this.size = i, this.data = new Uint8Array(i * i), this.reservedBit = new Uint8Array(i * i);
}
vt.prototype.set = function(i, t, e, n) {
  const r = i * this.size + t;
  this.data[r] = e, n && (this.reservedBit[r] = !0);
};
vt.prototype.get = function(i, t) {
  return this.data[i * this.size + t];
};
vt.prototype.xor = function(i, t, e) {
  this.data[i * this.size + t] ^= e;
};
vt.prototype.isReserved = function(i, t) {
  return this.reservedBit[i * this.size + t];
};
var vn = vt, ri = {};
(function(i) {
  const t = I.getSymbolSize;
  i.getRowColCoords = function(n) {
    if (n === 1) return [];
    const r = Math.floor(n / 7) + 2, o = t(n), s = o === 145 ? 26 : Math.ceil((o - 13) / (2 * r - 2)) * 2, l = [o - 7];
    for (let a = 1; a < r - 1; a++)
      l[a] = l[a - 1] - s;
    return l.push(6), l.reverse();
  }, i.getPositions = function(n) {
    const r = [], o = i.getRowColCoords(n), s = o.length;
    for (let l = 0; l < s; l++)
      for (let a = 0; a < s; a++)
        l === 0 && a === 0 || // top-left
        l === 0 && a === s - 1 || // bottom-left
        l === s - 1 && a === 0 || r.push([o[l], o[a]]);
    return r;
  };
})(ri);
var oi = {};
const En = I.getSymbolSize, Le = 7;
oi.getPositions = function(t) {
  const e = En(t);
  return [
    // top-left
    [0, 0],
    // top-right
    [e - Le, 0],
    // bottom-left
    [0, e - Le]
  ];
};
var si = {};
(function(i) {
  i.Patterns = {
    PATTERN000: 0,
    PATTERN001: 1,
    PATTERN010: 2,
    PATTERN011: 3,
    PATTERN100: 4,
    PATTERN101: 5,
    PATTERN110: 6,
    PATTERN111: 7
  };
  const t = {
    N1: 3,
    N2: 3,
    N3: 40,
    N4: 10
  };
  i.isValid = function(r) {
    return r != null && r !== "" && !isNaN(r) && r >= 0 && r <= 7;
  }, i.from = function(r) {
    return i.isValid(r) ? parseInt(r, 10) : void 0;
  }, i.getPenaltyN1 = function(r) {
    const o = r.size;
    let s = 0, l = 0, a = 0, d = null, c = null;
    for (let f = 0; f < o; f++) {
      l = a = 0, d = c = null;
      for (let h = 0; h < o; h++) {
        let u = r.get(f, h);
        u === d ? l++ : (l >= 5 && (s += t.N1 + (l - 5)), d = u, l = 1), u = r.get(h, f), u === c ? a++ : (a >= 5 && (s += t.N1 + (a - 5)), c = u, a = 1);
      }
      l >= 5 && (s += t.N1 + (l - 5)), a >= 5 && (s += t.N1 + (a - 5));
    }
    return s;
  }, i.getPenaltyN2 = function(r) {
    const o = r.size;
    let s = 0;
    for (let l = 0; l < o - 1; l++)
      for (let a = 0; a < o - 1; a++) {
        const d = r.get(l, a) + r.get(l, a + 1) + r.get(l + 1, a) + r.get(l + 1, a + 1);
        (d === 4 || d === 0) && s++;
      }
    return s * t.N2;
  }, i.getPenaltyN3 = function(r) {
    const o = r.size;
    let s = 0, l = 0, a = 0;
    for (let d = 0; d < o; d++) {
      l = a = 0;
      for (let c = 0; c < o; c++)
        l = l << 1 & 2047 | r.get(d, c), c >= 10 && (l === 1488 || l === 93) && s++, a = a << 1 & 2047 | r.get(c, d), c >= 10 && (a === 1488 || a === 93) && s++;
    }
    return s * t.N3;
  }, i.getPenaltyN4 = function(r) {
    let o = 0;
    const s = r.data.length;
    for (let a = 0; a < s; a++) o += r.data[a];
    return Math.abs(Math.ceil(o * 100 / s / 5) - 10) * t.N4;
  };
  function e(n, r, o) {
    switch (n) {
      case i.Patterns.PATTERN000:
        return (r + o) % 2 === 0;
      case i.Patterns.PATTERN001:
        return r % 2 === 0;
      case i.Patterns.PATTERN010:
        return o % 3 === 0;
      case i.Patterns.PATTERN011:
        return (r + o) % 3 === 0;
      case i.Patterns.PATTERN100:
        return (Math.floor(r / 2) + Math.floor(o / 3)) % 2 === 0;
      case i.Patterns.PATTERN101:
        return r * o % 2 + r * o % 3 === 0;
      case i.Patterns.PATTERN110:
        return (r * o % 2 + r * o % 3) % 2 === 0;
      case i.Patterns.PATTERN111:
        return (r * o % 3 + (r + o) % 2) % 2 === 0;
      default:
        throw new Error("bad maskPattern:" + n);
    }
  }
  i.applyMask = function(r, o) {
    const s = o.size;
    for (let l = 0; l < s; l++)
      for (let a = 0; a < s; a++)
        o.isReserved(a, l) || o.xor(a, l, e(r, a, l));
  }, i.getBestMask = function(r, o) {
    const s = Object.keys(i.Patterns).length;
    let l = 0, a = 1 / 0;
    for (let d = 0; d < s; d++) {
      o(d), i.applyMask(d, r);
      const c = i.getPenaltyN1(r) + i.getPenaltyN2(r) + i.getPenaltyN3(r) + i.getPenaltyN4(r);
      i.applyMask(d, r), c < a && (a = c, l = d);
    }
    return l;
  };
})(si);
var Dt = {};
const H = Ot, Et = [
  // L  M  Q  H
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  2,
  2,
  1,
  2,
  2,
  4,
  1,
  2,
  4,
  4,
  2,
  4,
  4,
  4,
  2,
  4,
  6,
  5,
  2,
  4,
  6,
  6,
  2,
  5,
  8,
  8,
  4,
  5,
  8,
  8,
  4,
  5,
  8,
  11,
  4,
  8,
  10,
  11,
  4,
  9,
  12,
  16,
  4,
  9,
  16,
  16,
  6,
  10,
  12,
  18,
  6,
  10,
  17,
  16,
  6,
  11,
  16,
  19,
  6,
  13,
  18,
  21,
  7,
  14,
  21,
  25,
  8,
  16,
  20,
  25,
  8,
  17,
  23,
  25,
  9,
  17,
  23,
  34,
  9,
  18,
  25,
  30,
  10,
  20,
  27,
  32,
  12,
  21,
  29,
  35,
  12,
  23,
  34,
  37,
  12,
  25,
  34,
  40,
  13,
  26,
  35,
  42,
  14,
  28,
  38,
  45,
  15,
  29,
  40,
  48,
  16,
  31,
  43,
  51,
  17,
  33,
  45,
  54,
  18,
  35,
  48,
  57,
  19,
  37,
  51,
  60,
  19,
  38,
  53,
  63,
  20,
  40,
  56,
  66,
  21,
  43,
  59,
  70,
  22,
  45,
  62,
  74,
  24,
  47,
  65,
  77,
  25,
  49,
  68,
  81
], Ct = [
  // L  M  Q  H
  7,
  10,
  13,
  17,
  10,
  16,
  22,
  28,
  15,
  26,
  36,
  44,
  20,
  36,
  52,
  64,
  26,
  48,
  72,
  88,
  36,
  64,
  96,
  112,
  40,
  72,
  108,
  130,
  48,
  88,
  132,
  156,
  60,
  110,
  160,
  192,
  72,
  130,
  192,
  224,
  80,
  150,
  224,
  264,
  96,
  176,
  260,
  308,
  104,
  198,
  288,
  352,
  120,
  216,
  320,
  384,
  132,
  240,
  360,
  432,
  144,
  280,
  408,
  480,
  168,
  308,
  448,
  532,
  180,
  338,
  504,
  588,
  196,
  364,
  546,
  650,
  224,
  416,
  600,
  700,
  224,
  442,
  644,
  750,
  252,
  476,
  690,
  816,
  270,
  504,
  750,
  900,
  300,
  560,
  810,
  960,
  312,
  588,
  870,
  1050,
  336,
  644,
  952,
  1110,
  360,
  700,
  1020,
  1200,
  390,
  728,
  1050,
  1260,
  420,
  784,
  1140,
  1350,
  450,
  812,
  1200,
  1440,
  480,
  868,
  1290,
  1530,
  510,
  924,
  1350,
  1620,
  540,
  980,
  1440,
  1710,
  570,
  1036,
  1530,
  1800,
  570,
  1064,
  1590,
  1890,
  600,
  1120,
  1680,
  1980,
  630,
  1204,
  1770,
  2100,
  660,
  1260,
  1860,
  2220,
  720,
  1316,
  1950,
  2310,
  750,
  1372,
  2040,
  2430
];
Dt.getBlocksCount = function(t, e) {
  switch (e) {
    case H.L:
      return Et[(t - 1) * 4 + 0];
    case H.M:
      return Et[(t - 1) * 4 + 1];
    case H.Q:
      return Et[(t - 1) * 4 + 2];
    case H.H:
      return Et[(t - 1) * 4 + 3];
    default:
      return;
  }
};
Dt.getTotalCodewordsCount = function(t, e) {
  switch (e) {
    case H.L:
      return Ct[(t - 1) * 4 + 0];
    case H.M:
      return Ct[(t - 1) * 4 + 1];
    case H.Q:
      return Ct[(t - 1) * 4 + 2];
    case H.H:
      return Ct[(t - 1) * 4 + 3];
    default:
      return;
  }
};
var ai = {}, zt = {};
const ht = new Uint8Array(512), At = new Uint8Array(256);
(function() {
  let t = 1;
  for (let e = 0; e < 255; e++)
    ht[e] = t, At[t] = e, t <<= 1, t & 256 && (t ^= 285);
  for (let e = 255; e < 512; e++)
    ht[e] = ht[e - 255];
})();
zt.log = function(t) {
  if (t < 1) throw new Error("log(" + t + ")");
  return At[t];
};
zt.exp = function(t) {
  return ht[t];
};
zt.mul = function(t, e) {
  return t === 0 || e === 0 ? 0 : ht[At[t] + At[e]];
};
(function(i) {
  const t = zt;
  i.mul = function(n, r) {
    const o = new Uint8Array(n.length + r.length - 1);
    for (let s = 0; s < n.length; s++)
      for (let l = 0; l < r.length; l++)
        o[s + l] ^= t.mul(n[s], r[l]);
    return o;
  }, i.mod = function(n, r) {
    let o = new Uint8Array(n);
    for (; o.length - r.length >= 0; ) {
      const s = o[0];
      for (let a = 0; a < r.length; a++)
        o[a] ^= t.mul(r[a], s);
      let l = 0;
      for (; l < o.length && o[l] === 0; ) l++;
      o = o.slice(l);
    }
    return o;
  }, i.generateECPolynomial = function(n) {
    let r = new Uint8Array([1]);
    for (let o = 0; o < n; o++)
      r = i.mul(r, new Uint8Array([1, t.exp(o)]));
    return r;
  };
})(ai);
const li = ai;
function be(i) {
  this.genPoly = void 0, this.degree = i, this.degree && this.initialize(this.degree);
}
be.prototype.initialize = function(t) {
  this.degree = t, this.genPoly = li.generateECPolynomial(this.degree);
};
be.prototype.encode = function(t) {
  if (!this.genPoly)
    throw new Error("Encoder not initialized");
  const e = new Uint8Array(t.length + this.degree);
  e.set(t);
  const n = li.mod(e, this.genPoly), r = this.degree - n.length;
  if (r > 0) {
    const o = new Uint8Array(this.degree);
    return o.set(n, r), o;
  }
  return n;
};
var Cn = be, di = {}, J = {}, ye = {};
ye.isValid = function(t) {
  return !isNaN(t) && t >= 1 && t <= 40;
};
var z = {};
const ci = "[0-9]+", kn = "[A-Z $%*+\\-./:]+";
let bt = "(?:[u3000-u303F]|[u3040-u309F]|[u30A0-u30FF]|[uFF00-uFFEF]|[u4E00-u9FAF]|[u2605-u2606]|[u2190-u2195]|u203B|[u2010u2015u2018u2019u2025u2026u201Cu201Du2225u2260]|[u0391-u0451]|[u00A7u00A8u00B1u00B4u00D7u00F7])+";
bt = bt.replace(/u/g, "\\u");
const Sn = "(?:(?![A-Z0-9 $%*+\\-./:]|" + bt + `)(?:.|[\r
]))+`;
z.KANJI = new RegExp(bt, "g");
z.BYTE_KANJI = new RegExp("[^A-Z0-9 $%*+\\-./:]+", "g");
z.BYTE = new RegExp(Sn, "g");
z.NUMERIC = new RegExp(ci, "g");
z.ALPHANUMERIC = new RegExp(kn, "g");
const Pn = new RegExp("^" + bt + "$"), $n = new RegExp("^" + ci + "$"), Tn = new RegExp("^[A-Z0-9 $%*+\\-./:]+$");
z.testKanji = function(t) {
  return Pn.test(t);
};
z.testNumeric = function(t) {
  return $n.test(t);
};
z.testAlphanumeric = function(t) {
  return Tn.test(t);
};
(function(i) {
  const t = ye, e = z;
  i.NUMERIC = {
    id: "Numeric",
    bit: 1,
    ccBits: [10, 12, 14]
  }, i.ALPHANUMERIC = {
    id: "Alphanumeric",
    bit: 2,
    ccBits: [9, 11, 13]
  }, i.BYTE = {
    id: "Byte",
    bit: 4,
    ccBits: [8, 16, 16]
  }, i.KANJI = {
    id: "Kanji",
    bit: 8,
    ccBits: [8, 10, 12]
  }, i.MIXED = {
    bit: -1
  }, i.getCharCountIndicator = function(o, s) {
    if (!o.ccBits) throw new Error("Invalid mode: " + o);
    if (!t.isValid(s))
      throw new Error("Invalid version: " + s);
    return s >= 1 && s < 10 ? o.ccBits[0] : s < 27 ? o.ccBits[1] : o.ccBits[2];
  }, i.getBestModeForData = function(o) {
    return e.testNumeric(o) ? i.NUMERIC : e.testAlphanumeric(o) ? i.ALPHANUMERIC : e.testKanji(o) ? i.KANJI : i.BYTE;
  }, i.toString = function(o) {
    if (o && o.id) return o.id;
    throw new Error("Invalid mode");
  }, i.isValid = function(o) {
    return o && o.bit && o.ccBits;
  };
  function n(r) {
    if (typeof r != "string")
      throw new Error("Param is not a string");
    switch (r.toLowerCase()) {
      case "numeric":
        return i.NUMERIC;
      case "alphanumeric":
        return i.ALPHANUMERIC;
      case "kanji":
        return i.KANJI;
      case "byte":
        return i.BYTE;
      default:
        throw new Error("Unknown mode: " + r);
    }
  }
  i.from = function(o, s) {
    if (i.isValid(o))
      return o;
    try {
      return n(o);
    } catch {
      return s;
    }
  };
})(J);
(function(i) {
  const t = I, e = Dt, n = Ot, r = J, o = ye, s = 7973, l = t.getBCHDigit(s);
  function a(h, u, m) {
    for (let y = 1; y <= 40; y++)
      if (u <= i.getCapacity(y, m, h))
        return y;
  }
  function d(h, u) {
    return r.getCharCountIndicator(h, u) + 4;
  }
  function c(h, u) {
    let m = 0;
    return h.forEach(function(y) {
      const A = d(y.mode, u);
      m += A + y.getBitsLength();
    }), m;
  }
  function f(h, u) {
    for (let m = 1; m <= 40; m++)
      if (c(h, m) <= i.getCapacity(m, u, r.MIXED))
        return m;
  }
  i.from = function(u, m) {
    return o.isValid(u) ? parseInt(u, 10) : m;
  }, i.getCapacity = function(u, m, y) {
    if (!o.isValid(u))
      throw new Error("Invalid QR Code version");
    typeof y > "u" && (y = r.BYTE);
    const A = t.getSymbolTotalCodewords(u), b = e.getTotalCodewordsCount(u, m), w = (A - b) * 8;
    if (y === r.MIXED) return w;
    const p = w - d(y, u);
    switch (y) {
      case r.NUMERIC:
        return Math.floor(p / 10 * 3);
      case r.ALPHANUMERIC:
        return Math.floor(p / 11 * 2);
      case r.KANJI:
        return Math.floor(p / 13);
      case r.BYTE:
      default:
        return Math.floor(p / 8);
    }
  }, i.getBestVersionForData = function(u, m) {
    let y;
    const A = n.from(m, n.M);
    if (Array.isArray(u)) {
      if (u.length > 1)
        return f(u, A);
      if (u.length === 0)
        return 1;
      y = u[0];
    } else
      y = u;
    return a(y.mode, y.getLength(), A);
  }, i.getEncodedBits = function(u) {
    if (!o.isValid(u) || u < 7)
      throw new Error("Invalid QR Code version");
    let m = u << 12;
    for (; t.getBCHDigit(m) - l >= 0; )
      m ^= s << t.getBCHDigit(m) - l;
    return u << 12 | m;
  };
})(di);
var ui = {};
const re = I, hi = 1335, xn = 21522, Ue = re.getBCHDigit(hi);
ui.getEncodedBits = function(t, e) {
  const n = t.bit << 3 | e;
  let r = n << 10;
  for (; re.getBCHDigit(r) - Ue >= 0; )
    r ^= hi << re.getBCHDigit(r) - Ue;
  return (n << 10 | r) ^ xn;
};
var fi = {};
const Nn = J;
function rt(i) {
  this.mode = Nn.NUMERIC, this.data = i.toString();
}
rt.getBitsLength = function(t) {
  return 10 * Math.floor(t / 3) + (t % 3 ? t % 3 * 3 + 1 : 0);
};
rt.prototype.getLength = function() {
  return this.data.length;
};
rt.prototype.getBitsLength = function() {
  return rt.getBitsLength(this.data.length);
};
rt.prototype.write = function(t) {
  let e, n, r;
  for (e = 0; e + 3 <= this.data.length; e += 3)
    n = this.data.substr(e, 3), r = parseInt(n, 10), t.put(r, 10);
  const o = this.data.length - e;
  o > 0 && (n = this.data.substr(e), r = parseInt(n, 10), t.put(r, o * 3 + 1));
};
var An = rt;
const Mn = J, Gt = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  " ",
  "$",
  "%",
  "*",
  "+",
  "-",
  ".",
  "/",
  ":"
];
function ot(i) {
  this.mode = Mn.ALPHANUMERIC, this.data = i;
}
ot.getBitsLength = function(t) {
  return 11 * Math.floor(t / 2) + 6 * (t % 2);
};
ot.prototype.getLength = function() {
  return this.data.length;
};
ot.prototype.getBitsLength = function() {
  return ot.getBitsLength(this.data.length);
};
ot.prototype.write = function(t) {
  let e;
  for (e = 0; e + 2 <= this.data.length; e += 2) {
    let n = Gt.indexOf(this.data[e]) * 45;
    n += Gt.indexOf(this.data[e + 1]), t.put(n, 11);
  }
  this.data.length % 2 && t.put(Gt.indexOf(this.data[e]), 6);
};
var Bn = ot;
const In = J;
function st(i) {
  this.mode = In.BYTE, typeof i == "string" ? this.data = new TextEncoder().encode(i) : this.data = new Uint8Array(i);
}
st.getBitsLength = function(t) {
  return t * 8;
};
st.prototype.getLength = function() {
  return this.data.length;
};
st.prototype.getBitsLength = function() {
  return st.getBitsLength(this.data.length);
};
st.prototype.write = function(i) {
  for (let t = 0, e = this.data.length; t < e; t++)
    i.put(this.data[t], 8);
};
var Rn = st;
const Ln = J, Un = I;
function at(i) {
  this.mode = Ln.KANJI, this.data = i;
}
at.getBitsLength = function(t) {
  return t * 13;
};
at.prototype.getLength = function() {
  return this.data.length;
};
at.prototype.getBitsLength = function() {
  return at.getBitsLength(this.data.length);
};
at.prototype.write = function(i) {
  let t;
  for (t = 0; t < this.data.length; t++) {
    let e = Un.toSJIS(this.data[t]);
    if (e >= 33088 && e <= 40956)
      e -= 33088;
    else if (e >= 57408 && e <= 60351)
      e -= 49472;
    else
      throw new Error(
        "Invalid SJIS character: " + this.data[t] + `
Make sure your charset is UTF-8`
      );
    e = (e >>> 8 & 255) * 192 + (e & 255), i.put(e, 13);
  }
};
var On = at, gi = { exports: {} };
(function(i) {
  var t = {
    single_source_shortest_paths: function(e, n, r) {
      var o = {}, s = {};
      s[n] = 0;
      var l = t.PriorityQueue.make();
      l.push(n, 0);
      for (var a, d, c, f, h, u, m, y, A; !l.empty(); ) {
        a = l.pop(), d = a.value, f = a.cost, h = e[d] || {};
        for (c in h)
          h.hasOwnProperty(c) && (u = h[c], m = f + u, y = s[c], A = typeof s[c] > "u", (A || y > m) && (s[c] = m, l.push(c, m), o[c] = d));
      }
      if (typeof r < "u" && typeof s[r] > "u") {
        var b = ["Could not find a path from ", n, " to ", r, "."].join("");
        throw new Error(b);
      }
      return o;
    },
    extract_shortest_path_from_predecessor_list: function(e, n) {
      for (var r = [], o = n; o; )
        r.push(o), e[o], o = e[o];
      return r.reverse(), r;
    },
    find_path: function(e, n, r) {
      var o = t.single_source_shortest_paths(e, n, r);
      return t.extract_shortest_path_from_predecessor_list(
        o,
        r
      );
    },
    /**
     * A very naive priority queue implementation.
     */
    PriorityQueue: {
      make: function(e) {
        var n = t.PriorityQueue, r = {}, o;
        e = e || {};
        for (o in n)
          n.hasOwnProperty(o) && (r[o] = n[o]);
        return r.queue = [], r.sorter = e.sorter || n.default_sorter, r;
      },
      default_sorter: function(e, n) {
        return e.cost - n.cost;
      },
      /**
       * Add a new item to the queue and ensure the highest priority element
       * is at the front of the queue.
       */
      push: function(e, n) {
        var r = { value: e, cost: n };
        this.queue.push(r), this.queue.sort(this.sorter);
      },
      /**
       * Return the highest priority element in the queue.
       */
      pop: function() {
        return this.queue.shift();
      },
      empty: function() {
        return this.queue.length === 0;
      }
    }
  };
  i.exports = t;
})(gi);
var Dn = gi.exports;
(function(i) {
  const t = J, e = An, n = Bn, r = Rn, o = On, s = z, l = I, a = Dn;
  function d(b) {
    return unescape(encodeURIComponent(b)).length;
  }
  function c(b, w, p) {
    const g = [];
    let C;
    for (; (C = b.exec(p)) !== null; )
      g.push({
        data: C[0],
        index: C.index,
        mode: w,
        length: C[0].length
      });
    return g;
  }
  function f(b) {
    const w = c(s.NUMERIC, t.NUMERIC, b), p = c(s.ALPHANUMERIC, t.ALPHANUMERIC, b);
    let g, C;
    return l.isKanjiModeEnabled() ? (g = c(s.BYTE, t.BYTE, b), C = c(s.KANJI, t.KANJI, b)) : (g = c(s.BYTE_KANJI, t.BYTE, b), C = []), w.concat(p, g, C).sort(function(x, R) {
      return x.index - R.index;
    }).map(function(x) {
      return {
        data: x.data,
        mode: x.mode,
        length: x.length
      };
    });
  }
  function h(b, w) {
    switch (w) {
      case t.NUMERIC:
        return e.getBitsLength(b);
      case t.ALPHANUMERIC:
        return n.getBitsLength(b);
      case t.KANJI:
        return o.getBitsLength(b);
      case t.BYTE:
        return r.getBitsLength(b);
    }
  }
  function u(b) {
    return b.reduce(function(w, p) {
      const g = w.length - 1 >= 0 ? w[w.length - 1] : null;
      return g && g.mode === p.mode ? (w[w.length - 1].data += p.data, w) : (w.push(p), w);
    }, []);
  }
  function m(b) {
    const w = [];
    for (let p = 0; p < b.length; p++) {
      const g = b[p];
      switch (g.mode) {
        case t.NUMERIC:
          w.push([
            g,
            { data: g.data, mode: t.ALPHANUMERIC, length: g.length },
            { data: g.data, mode: t.BYTE, length: g.length }
          ]);
          break;
        case t.ALPHANUMERIC:
          w.push([
            g,
            { data: g.data, mode: t.BYTE, length: g.length }
          ]);
          break;
        case t.KANJI:
          w.push([
            g,
            { data: g.data, mode: t.BYTE, length: d(g.data) }
          ]);
          break;
        case t.BYTE:
          w.push([
            { data: g.data, mode: t.BYTE, length: d(g.data) }
          ]);
      }
    }
    return w;
  }
  function y(b, w) {
    const p = {}, g = { start: {} };
    let C = ["start"];
    for (let k = 0; k < b.length; k++) {
      const x = b[k], R = [];
      for (let j = 0; j < x.length; j++) {
        const O = x[j], dt = "" + k + j;
        R.push(dt), p[dt] = { node: O, lastCount: 0 }, g[dt] = {};
        for (let Ft = 0; Ft < C.length; Ft++) {
          const V = C[Ft];
          p[V] && p[V].node.mode === O.mode ? (g[V][dt] = h(p[V].lastCount + O.length, O.mode) - h(p[V].lastCount, O.mode), p[V].lastCount += O.length) : (p[V] && (p[V].lastCount = O.length), g[V][dt] = h(O.length, O.mode) + 4 + t.getCharCountIndicator(O.mode, w));
        }
      }
      C = R;
    }
    for (let k = 0; k < C.length; k++)
      g[C[k]].end = 0;
    return { map: g, table: p };
  }
  function A(b, w) {
    let p;
    const g = t.getBestModeForData(b);
    if (p = t.from(w, g), p !== t.BYTE && p.bit < g.bit)
      throw new Error('"' + b + '" cannot be encoded with mode ' + t.toString(p) + `.
 Suggested mode is: ` + t.toString(g));
    switch (p === t.KANJI && !l.isKanjiModeEnabled() && (p = t.BYTE), p) {
      case t.NUMERIC:
        return new e(b);
      case t.ALPHANUMERIC:
        return new n(b);
      case t.KANJI:
        return new o(b);
      case t.BYTE:
        return new r(b);
    }
  }
  i.fromArray = function(w) {
    return w.reduce(function(p, g) {
      return typeof g == "string" ? p.push(A(g, null)) : g.data && p.push(A(g.data, g.mode)), p;
    }, []);
  }, i.fromString = function(w, p) {
    const g = f(w, l.isKanjiModeEnabled()), C = m(g), k = y(C, p), x = a.find_path(k.map, "start", "end"), R = [];
    for (let j = 1; j < x.length - 1; j++)
      R.push(k.table[x[j]].node);
    return i.fromArray(u(R));
  }, i.rawSplit = function(w) {
    return i.fromArray(
      f(w, l.isKanjiModeEnabled())
    );
  };
})(fi);
const Vt = I, Qt = Ot, zn = wn, Vn = vn, Fn = ri, qn = oi, oe = si, se = Dt, jn = Cn, Mt = di, Hn = ui, Wn = J, Zt = fi;
function Jn(i, t) {
  const e = i.size, n = qn.getPositions(t);
  for (let r = 0; r < n.length; r++) {
    const o = n[r][0], s = n[r][1];
    for (let l = -1; l <= 7; l++)
      if (!(o + l <= -1 || e <= o + l))
        for (let a = -1; a <= 7; a++)
          s + a <= -1 || e <= s + a || (l >= 0 && l <= 6 && (a === 0 || a === 6) || a >= 0 && a <= 6 && (l === 0 || l === 6) || l >= 2 && l <= 4 && a >= 2 && a <= 4 ? i.set(o + l, s + a, !0, !0) : i.set(o + l, s + a, !1, !0));
  }
}
function Kn(i) {
  const t = i.size;
  for (let e = 8; e < t - 8; e++) {
    const n = e % 2 === 0;
    i.set(e, 6, n, !0), i.set(6, e, n, !0);
  }
}
function Yn(i, t) {
  const e = Fn.getPositions(t);
  for (let n = 0; n < e.length; n++) {
    const r = e[n][0], o = e[n][1];
    for (let s = -2; s <= 2; s++)
      for (let l = -2; l <= 2; l++)
        s === -2 || s === 2 || l === -2 || l === 2 || s === 0 && l === 0 ? i.set(r + s, o + l, !0, !0) : i.set(r + s, o + l, !1, !0);
  }
}
function Gn(i, t) {
  const e = i.size, n = Mt.getEncodedBits(t);
  let r, o, s;
  for (let l = 0; l < 18; l++)
    r = Math.floor(l / 3), o = l % 3 + e - 8 - 3, s = (n >> l & 1) === 1, i.set(r, o, s, !0), i.set(o, r, s, !0);
}
function Xt(i, t, e) {
  const n = i.size, r = Hn.getEncodedBits(t, e);
  let o, s;
  for (o = 0; o < 15; o++)
    s = (r >> o & 1) === 1, o < 6 ? i.set(o, 8, s, !0) : o < 8 ? i.set(o + 1, 8, s, !0) : i.set(n - 15 + o, 8, s, !0), o < 8 ? i.set(8, n - o - 1, s, !0) : o < 9 ? i.set(8, 15 - o - 1 + 1, s, !0) : i.set(8, 15 - o - 1, s, !0);
  i.set(n - 8, 8, 1, !0);
}
function Qn(i, t) {
  const e = i.size;
  let n = -1, r = e - 1, o = 7, s = 0;
  for (let l = e - 1; l > 0; l -= 2)
    for (l === 6 && l--; ; ) {
      for (let a = 0; a < 2; a++)
        if (!i.isReserved(r, l - a)) {
          let d = !1;
          s < t.length && (d = (t[s] >>> o & 1) === 1), i.set(r, l - a, d), o--, o === -1 && (s++, o = 7);
        }
      if (r += n, r < 0 || e <= r) {
        r -= n, n = -n;
        break;
      }
    }
}
function Zn(i, t, e) {
  const n = new zn();
  e.forEach(function(a) {
    n.put(a.mode.bit, 4), n.put(a.getLength(), Wn.getCharCountIndicator(a.mode, i)), a.write(n);
  });
  const r = Vt.getSymbolTotalCodewords(i), o = se.getTotalCodewordsCount(i, t), s = (r - o) * 8;
  for (n.getLengthInBits() + 4 <= s && n.put(0, 4); n.getLengthInBits() % 8 !== 0; )
    n.putBit(0);
  const l = (s - n.getLengthInBits()) / 8;
  for (let a = 0; a < l; a++)
    n.put(a % 2 ? 17 : 236, 8);
  return Xn(n, i, t);
}
function Xn(i, t, e) {
  const n = Vt.getSymbolTotalCodewords(t), r = se.getTotalCodewordsCount(t, e), o = n - r, s = se.getBlocksCount(t, e), l = n % s, a = s - l, d = Math.floor(n / s), c = Math.floor(o / s), f = c + 1, h = d - c, u = new jn(h);
  let m = 0;
  const y = new Array(s), A = new Array(s);
  let b = 0;
  const w = new Uint8Array(i.buffer);
  for (let x = 0; x < s; x++) {
    const R = x < a ? c : f;
    y[x] = w.slice(m, m + R), A[x] = u.encode(y[x]), m += R, b = Math.max(b, R);
  }
  const p = new Uint8Array(n);
  let g = 0, C, k;
  for (C = 0; C < b; C++)
    for (k = 0; k < s; k++)
      C < y[k].length && (p[g++] = y[k][C]);
  for (C = 0; C < h; C++)
    for (k = 0; k < s; k++)
      p[g++] = A[k][C];
  return p;
}
function tr(i, t, e, n) {
  let r;
  if (Array.isArray(i))
    r = Zt.fromArray(i);
  else if (typeof i == "string") {
    let d = t;
    if (!d) {
      const c = Zt.rawSplit(i);
      d = Mt.getBestVersionForData(c, e);
    }
    r = Zt.fromString(i, d || 40);
  } else
    throw new Error("Invalid data");
  const o = Mt.getBestVersionForData(r, e);
  if (!o)
    throw new Error("The amount of data is too big to be stored in a QR Code");
  if (!t)
    t = o;
  else if (t < o)
    throw new Error(
      `
The chosen QR Code version cannot contain this amount of data.
Minimum version required to store current data is: ` + o + `.
`
    );
  const s = Zn(t, e, r), l = Vt.getSymbolSize(t), a = new Vn(l);
  return Jn(a, t), Kn(a), Yn(a, t), Xt(a, e, 0), t >= 7 && Gn(a, t), Qn(a, s), isNaN(n) && (n = oe.getBestMask(
    a,
    Xt.bind(null, a, e)
  )), oe.applyMask(n, a), Xt(a, e, n), {
    modules: a,
    version: t,
    errorCorrectionLevel: e,
    maskPattern: n,
    segments: r
  };
}
ii.create = function(t, e) {
  if (typeof t > "u" || t === "")
    throw new Error("No input text");
  let n = Qt.M, r, o;
  return typeof e < "u" && (n = Qt.from(e.errorCorrectionLevel, Qt.M), r = Mt.from(e.version), o = oe.from(e.maskPattern), e.toSJISFunc && Vt.setToSJISFunction(e.toSJISFunc)), tr(t, r, n, o);
};
var pi = {}, _e = {};
(function(i) {
  function t(e) {
    if (typeof e == "number" && (e = e.toString()), typeof e != "string")
      throw new Error("Color should be defined as hex string");
    let n = e.slice().replace("#", "").split("");
    if (n.length < 3 || n.length === 5 || n.length > 8)
      throw new Error("Invalid hex color: " + e);
    (n.length === 3 || n.length === 4) && (n = Array.prototype.concat.apply([], n.map(function(o) {
      return [o, o];
    }))), n.length === 6 && n.push("F", "F");
    const r = parseInt(n.join(""), 16);
    return {
      r: r >> 24 & 255,
      g: r >> 16 & 255,
      b: r >> 8 & 255,
      a: r & 255,
      hex: "#" + n.slice(0, 6).join("")
    };
  }
  i.getOptions = function(n) {
    n || (n = {}), n.color || (n.color = {});
    const r = typeof n.margin > "u" || n.margin === null || n.margin < 0 ? 4 : n.margin, o = n.width && n.width >= 21 ? n.width : void 0, s = n.scale || 4;
    return {
      width: o,
      scale: o ? 4 : s,
      margin: r,
      color: {
        dark: t(n.color.dark || "#000000ff"),
        light: t(n.color.light || "#ffffffff")
      },
      type: n.type,
      rendererOpts: n.rendererOpts || {}
    };
  }, i.getScale = function(n, r) {
    return r.width && r.width >= n + r.margin * 2 ? r.width / (n + r.margin * 2) : r.scale;
  }, i.getImageWidth = function(n, r) {
    const o = i.getScale(n, r);
    return Math.floor((n + r.margin * 2) * o);
  }, i.qrToImageData = function(n, r, o) {
    const s = r.modules.size, l = r.modules.data, a = i.getScale(s, o), d = Math.floor((s + o.margin * 2) * a), c = o.margin * a, f = [o.color.light, o.color.dark];
    for (let h = 0; h < d; h++)
      for (let u = 0; u < d; u++) {
        let m = (h * d + u) * 4, y = o.color.light;
        if (h >= c && u >= c && h < d - c && u < d - c) {
          const A = Math.floor((h - c) / a), b = Math.floor((u - c) / a);
          y = f[l[A * s + b] ? 1 : 0];
        }
        n[m++] = y.r, n[m++] = y.g, n[m++] = y.b, n[m] = y.a;
      }
  };
})(_e);
(function(i) {
  const t = _e;
  function e(r, o, s) {
    r.clearRect(0, 0, o.width, o.height), o.style || (o.style = {}), o.height = s, o.width = s, o.style.height = s + "px", o.style.width = s + "px";
  }
  function n() {
    try {
      return document.createElement("canvas");
    } catch {
      throw new Error("You need to specify a canvas element");
    }
  }
  i.render = function(o, s, l) {
    let a = l, d = s;
    typeof a > "u" && (!s || !s.getContext) && (a = s, s = void 0), s || (d = n()), a = t.getOptions(a);
    const c = t.getImageWidth(o.modules.size, a), f = d.getContext("2d"), h = f.createImageData(c, c);
    return t.qrToImageData(h.data, o, a), e(f, d, c), f.putImageData(h, 0, 0), d;
  }, i.renderToDataURL = function(o, s, l) {
    let a = l;
    typeof a > "u" && (!s || !s.getContext) && (a = s, s = void 0), a || (a = {});
    const d = i.render(o, s, a), c = a.type || "image/png", f = a.rendererOpts || {};
    return d.toDataURL(c, f.quality);
  };
})(pi);
var mi = {};
const er = _e;
function Oe(i, t) {
  const e = i.a / 255, n = t + '="' + i.hex + '"';
  return e < 1 ? n + " " + t + '-opacity="' + e.toFixed(2).slice(1) + '"' : n;
}
function te(i, t, e) {
  let n = i + t;
  return typeof e < "u" && (n += " " + e), n;
}
function ir(i, t, e) {
  let n = "", r = 0, o = !1, s = 0;
  for (let l = 0; l < i.length; l++) {
    const a = Math.floor(l % t), d = Math.floor(l / t);
    !a && !o && (o = !0), i[l] ? (s++, l > 0 && a > 0 && i[l - 1] || (n += o ? te("M", a + e, 0.5 + d + e) : te("m", r, 0), r = 0, o = !1), a + 1 < t && i[l + 1] || (n += te("h", s), s = 0)) : r++;
  }
  return n;
}
mi.render = function(t, e, n) {
  const r = er.getOptions(e), o = t.modules.size, s = t.modules.data, l = o + r.margin * 2, a = r.color.light.a ? "<path " + Oe(r.color.light, "fill") + ' d="M0 0h' + l + "v" + l + 'H0z"/>' : "", d = "<path " + Oe(r.color.dark, "stroke") + ' d="' + ir(s, o, r.margin) + '"/>', c = 'viewBox="0 0 ' + l + " " + l + '"', h = '<svg xmlns="http://www.w3.org/2000/svg" ' + (r.width ? 'width="' + r.width + '" height="' + r.width + '" ' : "") + c + ' shape-rendering="crispEdges">' + a + d + `</svg>
`;
  return typeof n == "function" && n(null, h), h;
};
const nr = yn, ae = ii, bi = pi, rr = mi;
function we(i, t, e, n, r) {
  const o = [].slice.call(arguments, 1), s = o.length, l = typeof o[s - 1] == "function";
  if (!l && !nr())
    throw new Error("Callback required as last argument");
  if (l) {
    if (s < 2)
      throw new Error("Too few arguments provided");
    s === 2 ? (r = e, e = t, t = n = void 0) : s === 3 && (t.getContext && typeof r > "u" ? (r = n, n = void 0) : (r = n, n = e, e = t, t = void 0));
  } else {
    if (s < 1)
      throw new Error("Too few arguments provided");
    return s === 1 ? (e = t, t = n = void 0) : s === 2 && !t.getContext && (n = e, e = t, t = void 0), new Promise(function(a, d) {
      try {
        const c = ae.create(e, n);
        a(i(c, t, n));
      } catch (c) {
        d(c);
      }
    });
  }
  try {
    const a = ae.create(e, n);
    r(null, i(a, t, n));
  } catch (a) {
    r(a);
  }
}
ae.create;
var or = we.bind(null, bi.render);
we.bind(null, bi.renderToDataURL);
we.bind(null, function(i, t, e) {
  return rr.render(i, e);
});
const sr = Rt`
  <svg class="preloader-icon" width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0085 0.166504H21.0827V10.6203C20.4178 10.445 19.7197 10.3517 18.9998 10.3517C18.3129 10.3517 17.6458 10.4367 17.0085 10.5967V0.166504ZM14.8895 11.4628L7.66711 4.24051L4.78632 7.1213L11.9969 14.3319C12.7027 13.1486 13.6999 12.1592 14.8895 11.4628ZM11.0305 16.794H0.666504V20.8681H11.2012C10.9739 20.1186 10.8517 19.3235 10.8517 18.4998C10.8517 17.9147 10.9133 17.3441 11.0305 16.794ZM12.1069 22.847L4.78624 30.1677L7.66703 33.0485L15.074 25.6416C13.8645 24.9753 12.8424 24.0108 12.1069 22.847ZM17.0085 26.4029V36.8331H21.0827V26.3792C20.4178 26.5546 19.7197 26.6479 18.9998 26.6479C18.3129 26.6479 17.6458 26.563 17.0085 26.4029ZM23.1678 25.5027L30.7136 33.0485L33.5943 30.1677L26.0367 22.6101C25.3405 23.7998 24.3511 24.7969 23.1678 25.5027ZM26.7984 20.8681H37.3331V16.794H26.9691C27.0862 17.3441 27.1479 17.9147 27.1479 18.4998C27.1479 19.3235 27.0257 20.1186 26.7984 20.8681ZM26.1416 14.574L33.5943 7.1213L30.7134 4.24051L23.347 11.6069C24.5108 12.3424 25.4753 13.3645 26.1416 14.574Z" fill="#01B93C"/>
  </svg>
`, ar = Rt`
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 1.46927L13.335 3.20167L13.3721 3.20459C11.9834 1.55307 9.91034 0.5 7.57023 0.5C3.3829 0.5 0 3.85744 0 8.00073C0 12.1428 3.39947 15.5 7.58596 15.5C10.8269 15.5 13.5962 13.4868 14.6773 10.6536L11.8955 9.50384C11.2682 11.259 9.57406 12.519 7.58973 12.519C5.06974 12.519 3.02377 10.4919 3.02377 8.00073C3.02377 5.5075 5.07142 3.47932 7.59099 3.47932C9.09942 3.47932 10.4351 4.20888 11.2684 5.32762L9.44743 7.01353L15 7.01103V1.46927Z" fill="white"/>
  </svg>
`, lr = Rt`
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75955 0.0308444C6.91657 -0.0102815 7.08327 -0.0102815 7.2403 0.0308444L13.3653 1.63077C13.741 1.72896 14 2.04289 14 2.40008V9.21445C14 11.224 12.8223 13.0796 10.9139 14.0766L7.43402 15.8945C7.16511 16.0352 6.83492 16.0352 6.56598 15.8945L3.08612 14.0766C1.17772 13.0795 0 11.2241 0 9.21445V2.40008C0 2.04289 0.258973 1.72894 0.63472 1.63077L6.75955 0.0308444ZM11.1079 6.18723C11.4016 5.86544 11.3676 5.37634 11.0319 5.09478C10.6962 4.81323 10.1859 4.84583 9.89215 5.16761L6.62491 8.74674L4.80357 7.38889C4.45146 7.12639 3.944 7.18719 3.67014 7.5247C3.39627 7.8622 3.45971 8.3486 3.81182 8.61111L6.2349 10.4176C6.5727 10.6694 7.05681 10.625 7.33862 10.3163L11.1079 6.18723Z" fill="#089726"/>
  </svg>
`, dr = Bt`
    :host {
        --default-dialog-title-size: 24px;
        --default-dialog-title-weight: 500;
        --default-dialog-title-color: #2E3233;
        --default-dialog-title-margin: 0 0 12px;
        --default-description-text-color: #686B6C;
        --default-description-text-size: 16px;
        --default-divider-width: 224px;
        --default-divider-margin: 0 auto 24px;
        --default-divider-bg: #E7E7E7;
        --default-divider-text-color: #686B6C;
        --default-divider-text-size: 12px;
        --default-divider-text-padding: 0 10px;
        --default-divider-text-bg: #ffffff;
        --default-btn-height: 56px;
        --default-btn-bg: #01B93C;
        --default-btn-bg-hover: #82b435;
        --default-btn-color: #ffffff;
        --default-btn-size: 22px;
        --default-btn-weight: 500;
        --default-btn-padding: 0 24px;
        --default-btn-radius: 5px;
        --default-btn-radius-hover: 5px;
        --default-btn-border: none;
        --default-btn-border-hover: none;
        --default-dialog-secure-text-color: #3e3e3e;
        --default-dialog-secure-text-size: 14px;
        --default-qrcode-blur-image: url("/joxi/bank-id/blur-qr.png");
        --default-bankid-failed-text-color: #686B6C;
        --default-bankid-failed-text-size: 12px;
        --default-bankid-failed-text-margin: 0 0 2px;
        --default-bankid-failed-icon-background: #01B93C;
        --default-bankid-failed-icon-background-hover: #82b435;
        --default-bankid-failed-icon-border: none;
        --default-bankid-failed-icon-border-hover: none;
        --default-bankid-failed-icon-radius: 4px;
        --default-bankid-failed-icon-radius-hover: 4px;
        --default-bankid-failed-icon-width: 48px;
        --default-bankid-failed-icon-height: 48px;

        width: 100%;
    }
    .title {
        font-size: var(--dialog-title-size, var(--default-dialog-title-size));
        font-weight: var(--dialog-title-weight, var(--default-dialog-title-weight));
        color: var(--dialog-title-color, var(--default-dialog-title-color));
        margin: var(--dialog-title-margin, var(--default-dialog-title-margin));
    }
    .text {
        color: var(--description-text-color, var(--default-description-text-color));
        font-size: var(--description-text-size, var(--default-description-text-size));
        line-height: 140%;
    }
    .divider {
        max-width: var(--divider-width, var(--default-divider-width));
        width: 100%;
        position: relative;
        margin: var(--divider-margin, var(--default-divider-margin));
    }
    .divider:before, .divider:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: var(--divider-bg, var(--default-divider-bg));
        top: 60%;
        left: 0;
        z-index: 1;
    }
    .divider-text {
        position: relative;
        z-index: 2;
        font-size: var(--divider-text-size, var(--default-divider-text-size));
        color: var(--divider-text-color, var(--default-divider-text-color));
        padding: var(--divider-text-padding, var(--default-divider-text-padding));
        background-color: var(--divider-text-bg, var(--default-divider-text-bg));
    }
    .btn-primary {
        min-height: var(--btn-height, var(--default-btn-height));
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--btn-bg, var(--default-btn-bg));
        margin: 0 auto;
        color: var(--btn-color, var(--default-btn-color));
        font-size: var(--btn-size, var(--default-btn-size));
        font-weight: var(--btn-weight, var(--default-btn-weight));
        padding: var(--btn-padding, var(--default-btn-padding));
        border-radius: var(--btn-radius, var(--default-btn-radius));
        border: var(--btn-border, var(--default-btn-border));
    }
    .btn-primary:hover {
        background-color: var(--btn-bg-hover, var(--default-btn-bg-hover));
        border: var(--btn-border-hover, var(--default-btn-border-hover));
        border-radius: var(--btn-radius-hover, var(--default-btn-radius-hover));
    }
    .preloader-icon {
        margin: 12px auto 0;
        animation: rotatePreloader 3s linear infinite;
    }
    .qrCode {
        position: relative;
        margin: 12px auto 8px;
    }
    .qrCode.isFailed canvas {
        filter: blur(5px);
        opacity: 0.4;
    }
    .qrCode.isFailed .failed {
        display: flex;
    }
    .failed {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        position: absolute;
        top: calc(50% - 34px);
    }
    .failed-text {
        font-size: var(--bankid-failed-text-size, var(--default-bankid-failed-text-size));
        line-height: 140%;
        color: var(--bankid-failed-text-color, var(--default-bankid-failed-text-color));
        margin-bottom: var(--bankid-failed-text-margin, var(--default-bankid-failed-text-margin));
    }
    .failed-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--bankid-failed-icon-width, var(--default-bankid-failed-icon-width));
        height: var(--bankid-failed-icon-height, var(--default-bankid-failed-icon-height));
        background-color: var(--bankid-failed-icon-background, var(--default-bankid-failed-icon-background));
        border-radius: var(--bankid-failed-icon-radius, var(--default-bankid-failed-icon-radius));
        cursor: pointer;
        border: var(--bankid-failed-icon-border, var(--default-bankid-failed-icon-border));
    }
    .failed-icon:hover {
        background-color: var(--bankid-failed-icon-background-hover, var(--default-bankid-failed-icon-background-hover));
        border: var(--bankid-failed-icon-border-hover, var(--default-bankid-failed-icon-border-hover));
        border-radius: var(--bankid-failed-icon-radius-hover, var(--default-bankid-failed-icon-radius-hover));
    }
    .content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        height: 100%;
    }
    .middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
    .secure {
        display: flex;
        width: 100%;
        max-width: 224px;
        margin: 28px auto 0;
    }
    .secure-icon {
        width: 14px;
        height: 16px;
        margin-right: 8px;
        margin-top: 2px;
    }
    .secure-texts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
    .secure-text {
        font-size: var(--dialog-secure-text-size, var(--default-dialog-secure-text-size));
        color: var(--dialog-secure-text-color, var(--default-dialog-secure-text-color));
        line-height: 140%;
    }
    .secure-text.fw500 {
        font-weight: 500;
    }
    @keyframes rotatePreloader {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
class cr {
  constructor(t) {
    this.api = t;
  }
  init(t) {
    return this.fetch(`${this.api}/ident/init`, {
      method: "POST",
      body: JSON.stringify(t)
    });
  }
  status(t) {
    const e = new URLSearchParams(t).toString();
    return this.fetch(`${this.api}/ident/status?${e}`, {
      method: "GET"
    });
  }
  auth(t) {
    return this.fetch(`${this.api}/ident/auth`, {
      method: "POST",
      body: JSON.stringify(t)
    });
  }
  fetch(t, e) {
    if (e != null && e.method && !e.headers && ["POST", "PUT"].includes(e.method)) {
      const n = new Headers();
      n.append("Content-Type", "application/json"), e.headers = n;
    }
    return fetch(t, e).then(async (n) => ({
      status: n.status,
      ...await n.json()
    }));
  }
}
var ur = Object.defineProperty, hr = Object.getOwnPropertyDescriptor, T = (i, t, e, n) => {
  for (var r = n > 1 ? void 0 : n ? hr(t, e) : t, o = i.length - 1, s; o >= 0; o--)
    (s = i[o]) && (r = (n ? s(t, e, r) : s(r)) || r);
  return n && r && ur(t, e, r), r;
};
let S = class extends W {
  constructor() {
    super(...arguments), this.qrcode = "", this.orderId = "", this.autoStartToken = "", this.isAuthExpired = !1, this.redirect = ei(), this.api = `/${ti()}/api/v2`, this.qrbackground = "#0000", this.qrcolor = "#000", this.maxstatusrequests = 300, this.isDestroyed = !1;
  }
  i(i) {
    return pe(i, this.translations);
  }
  firstUpdated() {
    this.apiService = new cr(this.api), this.initAuth();
  }
  disconnectedCallback() {
    this.isDestroyed = !0, super.disconnectedCallback();
  }
  updated() {
    this.canvas && this.qrcode && or(this.canvas, this.qrcode, { width: 180, color: { light: this.qrbackground, dark: this.qrcolor } });
  }
  render() {
    const i = D`
          <div class="${Xe({ qrCode: !0, isFailed: this.error || this.isAuthExpired })}">
              <canvas class="canvas" id="auth-bank-id-canvas"></canvas>
              <div class="failed">
                  <p class="failed-text">${this.i("auth_bankid.status_loading__reload_qr")}</p>
                  <button @click="${this.initAuth}" class="failed-icon">
                      ${ar}
                  </button>
              </div>
          </div>
        `, t = Re() ? "https://app.bankid.com/?" : "bankid:///?", e = Re() ? "_blank" : P, n = an[Number(this.error)], r = "auth_bankid.subtitle_sign_in", o = "auth_bankid.body_sign_in";
    return !this.qrcode && !this.isAuthExpired && !this.error ? D`
                <div class="content">
                    <div class="middle">
                        <h2 class="title">${this.i("auth_bankid.status_loading_qr")}</h2>
                        ${sr}
                    </div>
                    ${this.getSecureFooter()}
                </div>
            ` : D`
            <div class="content">
                <div class="middle">
                    <h2 class="title">${this.i((n == null ? void 0 : n.title) || r)}</h2>
                    <p class="text">${this.i((n == null ? void 0 : n.text) || o)}</p>
                    ${i}
                    <div class="divider">
                        <span class="divider-text">${this.i("auth_bankid.or")}</span>
                    </div>
                    <a href="${t}${this.getQueryParams()}" class="btn-primary" target="${e}">
                        ${this.i("auth_bankid.button_sign_in")}
                    </a>
                </div>
                ${this.getSecureFooter()}
            </div>
        `;
  }
  getSecureFooter() {
    return D`
            <div class="secure">
                <div class="secure-icon">${lr}</div>
                <div class="secure-texts">
                    <p class="secure-text fw500">${this.i("auth_bankid.secure_1")}</p>
                    <p class="secure-text">${this.i("auth_bankid.secure_2")}</p>
                </div>
            </div>`;
  }
  initAuth() {
    this.isAuthExpired = !1, this.error = void 0, this.qrcode = "";
    const i = {
      amount: 0,
      currency: "SEK",
      locale: "sv_SE"
    };
    this.initPromise = this.apiService.init({
      ...this.getAffiliate(),
      paymethod: "bankid",
      amount: Number(this.amount) || i.amount,
      currency: this.currency || i.currency,
      locale: this.locale || i.locale
    }).then((t) => this.verifiedAuth(t)).catch((t) => {
      t && !(t instanceof tt) && (this.error = Ie[E.NetworkError], this.sendEvent(E.NetworkError, { order_id: void 0 })), this.qrcode = E.UnknownError;
    });
  }
  verifiedAuth(i) {
    var r;
    if (this.errorCodeHandler((r = i == null ? void 0 : i.result) == null ? void 0 : r.order_id, i))
      return Promise.reject(new tt("Init error"));
    const t = i.result;
    this.qrcode = t.qrCode, this.autoStartToken = t.autoStartToken;
    const e = t.order_id, n = "bankid";
    return this.status({ order_id: e, paymethod: n }).then(() => this.apiService.auth({
      ...this.getAffiliate(),
      ...t,
      order_id: t.order_id,
      paymethod: n
    })).then((o) => this.errorCodeHandler(t == null ? void 0 : t.order_id, o) ? Promise.reject(new tt("Auth error")) : o.result ? this.sendEvent(E.SuccessLogin, o.result) : Promise.reject("Unknown auth status"));
  }
  async status(i) {
    var n, r, o;
    let t, e = 0;
    for (; ((n = t == null ? void 0 : t.result) == null ? void 0 : n.status) !== "complete"; ) {
      if (((r = t == null ? void 0 : t.result) == null ? void 0 : r.status) === "failed")
        return this.sendEvent(E.AuthFailed), this.isAuthExpired = !0, Promise.reject(new tt("Auth failed error"));
      if (this.errorCodeHandler(i.order_id, t))
        return Promise.reject(new tt("Status error"));
      if (e++, e > Number(this.maxstatusrequests))
        return Promise.reject("Max status requests reached");
      if (await this.wait(1e3), this.isDestroyed)
        return Promise.reject(new tt("`auth-bank-id-content` was destroyed"));
      t = await this.apiService.status(i), this.qrcode = (o = t == null ? void 0 : t.result) == null ? void 0 : o.qrCode;
    }
    return t;
  }
  sendEvent(i, t) {
    this.dispatchEvent(new CustomEvent(i, {
      detail: t || { name: i },
      bubbles: !0,
      composed: !0
    }));
  }
  errorCodeHandler(i, t) {
    if (!t)
      return !1;
    const e = t.messages || [];
    e.length && this.sendEvent(E.AuthError, e);
    for (const r of e) {
      const o = r.code;
      if (o === Ie[E.ShowRegistration])
        return this.sendEvent(E.ShowRegistration, {
          order_id: i,
          profile: r.data.person,
          is_registration_bonuses_shown: r.data.is_registration_bonuses_shown
        }), !0;
      if (ut[o])
        return this.error = r.code, this.sendEvent(ut[r.code], { order_id: i }), !0;
    }
    let n = t.status;
    return n > 400 && !ut[n] && (n = 500), ut[n] ? (this.error = n, this.sendEvent(ut[n], { order_id: i }), !0) : !1;
  }
  wait(i) {
    return new Promise((t) => setTimeout(t, i));
  }
  getQueryParams() {
    const i = {
      autostarttoken: this.autoStartToken,
      redirect: this.redirect
    };
    return new URLSearchParams(i).toString();
  }
  getAffiliate() {
    var t;
    const i = this.affiliate || {};
    return {
      ...i,
      // @ts-ignore
      affiliate: ((t = this.affiliate) == null ? void 0 : t.affiliate) || {},
      qa: this.qa || i.qa || {},
      adformcookieid: this.adformcookieid || i.adformcookieid
    };
  }
};
S.styles = [ge, dr];
T([
  lt()
], S.prototype, "qrcode", 2);
T([
  lt()
], S.prototype, "orderId", 2);
T([
  lt()
], S.prototype, "autoStartToken", 2);
T([
  lt()
], S.prototype, "isAuthExpired", 2);
T([
  lt()
], S.prototype, "error", 2);
T([
  _()
], S.prototype, "redirect", 2);
T([
  _()
], S.prototype, "api", 2);
T([
  _({ type: Object })
], S.prototype, "affiliate", 2);
T([
  _()
], S.prototype, "amount", 2);
T([
  _()
], S.prototype, "currency", 2);
T([
  _()
], S.prototype, "locale", 2);
T([
  _({ type: Object })
], S.prototype, "qa", 2);
T([
  _()
], S.prototype, "qrbackground", 2);
T([
  _()
], S.prototype, "qrcolor", 2);
T([
  _()
], S.prototype, "adformcookieid", 2);
T([
  _()
], S.prototype, "maxstatusrequests", 2);
T([
  _({ type: Object })
], S.prototype, "translations", 2);
T([
  Ze("#auth-bank-id-canvas")
], S.prototype, "canvas", 2);
S = T([
  Ut("bsc-auth-bank-id-content")
], S);
export {
  $ as AuthBankId,
  S as AuthBankIdContent,
  E as AuthBankIdEventNames,
  tt as AuthBankIdProcessedError,
  ut as AuthErrorCodeToNames,
  an as AuthErrorCodeToTranslations,
  Ie as AuthErrorNameToCodes,
  ne as DIALOG_CLOSE,
  X as Dialog,
  nt as Dialogs,
  ei as getDefaultRedirect,
  ti as getLangFromUrl,
  cn as isChrome,
  Re as isMobile,
  pe as translate
};
