import playgrounds from '../../../app/service/gamification/playgrounds.js';

import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

(function () {
  controller.$inject = ['playgrounds', 'system$'];

  const SERVICE_NAME = 'betiniaCitiesServices';

  const collection = [];
  const collectionObject = {};

  const collection$ = new BehaviorSubject({});

  function controller(playgrounds, system$) {

    function buy(playground) {
      const id = playground.id;
      
      return playgrounds.buy({ id });
    }

    function setCollection(newCollection) {
      collection.length = 0;
      collection.push(...newCollection.map((city) => ({...city})));
    }

    function fetchCollection() {
      return playgrounds.collection({ segmentation: true }).then(({ result: { current_level, list } }) => {
        collection.length = 0;
        setCollection(list);
        
        Object.assign(collectionObject, {
          current_level,
          collection
        });

        collection$.next(collectionObject);
      });
    }

    const systemSubscription = system$
      .pipe(filter(({ action }) => ['login', 'logout'].includes(action)))
      .subscribe(() => {
        fetchCollection();
      });

    fetchCollection();

    return {
      collection$,
      fetchCollection,
      buy,
    };
  }

  app.service(SERVICE_NAME, controller);
})();
