(function () {
    'use strict';

    const directive = { name: 'openPlayground' };

    function controller(){
        function link( scope, element, attrs ){
            const item = scope.$eval( attrs[directive.name] );
            element[0].addEventListener( 'click' , () => {
                scope.$parent.playground = {};
                scope.$parent.open(item.alias);
            })
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
