(function () {
    'use strict';

    controller.$inject = ['$scope', '$element'];

    const component = { name: 'betiniaImg' };
    const classes = {
      loading: 'lazy-image__loading',
      loaded: 'lazy-image__loaded',
      error: 'lazy-image__error'
    }

    function controller(scope, element) {
      this.$onChanges = () => {
        scope.source = this.source;
        scope.text = this.text || '';
        scope.height = this.height || 400;
        scope.lazy = this.lazy ?? true;
        scope.loading = scope.lazy ? 'lazy' : 'eager';
        scope.type = scope.source?.split('.').pop();
      };
      const rootElement = element[0];
      const imgElement = rootElement.querySelector('img');
      const unsubscribe = () => {
        imgElement.removeEventListener('load', loadHandler, false);
        imgElement.removeEventListener('error', errorHandler, false);
      };
      const loadHandler = () => {
        rootElement.classList.remove(classes.loading);
        rootElement.classList.add(classes.loaded);
        setTimeout(() => {
          unsubscribe();
        }, 0);
      };
      const errorHandler = () => {
        rootElement.classList.remove(classes.loading);
        rootElement.classList.remove(classes.loaded);
        rootElement.classList.add(classes.error);
        setTimeout(() => {
          unsubscribe();
        }, 0);
      }

      imgElement.addEventListener('load', loadHandler, false);
      imgElement.addEventListener('error', errorHandler, false);

      rootElement.classList.add(classes.loading);

      scope.$on('$destroy', unsubscribe);
    }

    app.component(component.name, {
      controller: controller,
      template: app.getTU(component.name),
      bindings: {
        height: '<',
        source: '<',
        text: '<',
        lazy: '<'
      }
    });
  })();

