import { $_PGW } from "./pgw.mjs";

var $_CONFIG = $_CONFIG || {};

$_CONFIG.cdn = '/joxi';
$_CONFIG.jurisdiction = 'sweden';

$_CONFIG.gsapVersion = '3';
$_CONFIG.gsap3Plugins = [
    'PixiPlugin.min.js',
    'ScrollTrigger.min.js'
];

$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'betiniase';

$_CONFIG.useSvgSprite = false;
$_CONFIG.scripts = $_CONFIG.scripts || [];

$_CONFIG.domain = 'https://betinia-se.devv2.space';

// $_CONFIG.domain = 'https://campobet-se-rc-2-1054-0-0-v2-30057.fbarax.tech';

$_CONFIG.siteDomain = 'https://betinia.se';
$_CONFIG.siteName = 'betinia';

$_CONFIG.pwa = true;
$_CONFIG.appName = 'Betinia';
$_CONFIG.themeColor = '#1E2737';

$_CONFIG.redirectToNotFound = false;

$_CONFIG.symplify = true;
$_CONFIG.jquery = false;

$_CONFIG.feature = {
  initCookieScriptViaGtag: true
}

$_CONFIG.googleAnalytics = {
    appId: 'UA-213830434-1',
    trackAs: 'betinia.se'
}

$_CONFIG.pgw = $_PGW;

$_CONFIG.p161 = {
    //TRIGGER ON URL: Trigger on landing page for Sweden and always on campaign pages if you create campaigns that we should run
    view: '3161861',
    //TRIGGER ON URL: Trigger on page after registration
    registration: '3161858',
    //TRIGGER ON URL: Trigger on page after First deposit
    firstDeposit: '3161859',
    //TRIGGER ON URL: Trigger on page after returning/all deposits
    deposit: '3161860'
}

//$_CONFIG.customScripts = [ 'scripts/pixi.min.js' ];
//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.
$_CONFIG.places = {
    banner: {
        sportgrounds: `'home_sportgrounds'`,
        liveCasino: `'live_casino'`,
        casino: `'casino_banner'`,
        casinoPromo: `'casino_promo_banner'`,
        sportPromo: `'sport_promo_banner'`,
        sportWidgets: `'altenar_widgets'`
    },
    slider: {
    },
    promo: {
        registration1: `'welcome_bonus'`,
        promoUser: ``,
        promoGuest: ``,
        home: ``

    },
    icons: {
        footer1: `'footer_pay'`,
        footer2: `'footer_icons'`,
    }
};

$_CONFIG.banners = {};

$_CONFIG.analytics = {
  category: {
    achievements: 'Achievements',
    burger: 'Burger',
    cashier: 'Cashier',
    casino: 'Casino',
    footer: 'Footer',
    gamePage: 'Game_Page',
    header: 'Header',
    infoPages: 'Info_Pages',
    liveCasino: 'Live_Casino',
    loginForm: 'Login_Form',
    luckyWinner: 'Lucky_Winner',
    mainPage: 'Main_Page',
    profile: 'Profile',
    promotion: 'Promotion',
    providers: 'Providers',
    registrationForm: 'Registration_Form',
    search: 'Search_Popup',
    selfExclusion: 'Self_Exclusion',
    spinRally: 'Spin_Rally',
    tournaments: 'Tournaments',
    weeklyChallenges: 'Weekly_Challenges',
  },
  action: {
    achievements: 'Achievements',
    active: 'Active',
    //-arcade: 'Arcade',
    addictionTest: 'Addiction_test',
    balanceHistory: 'Balance_History',
    bankId: 'Bank_ID',
    banner: 'Banner',
    betiniaPromotions: 'Betinia_Promotions',
    bonusCrab: 'Bonus_Crab',
    burger: 'Burger',
    casino: 'Casino',
    category: 'Category',
    chooseBonus: 'Choose_Bonus',
    colossus: 'Colossus',
    coolOff: 'Cool_off',
    deposit: 'Deposit',
    details: 'Details',
    forgotPassword: 'Forgot_Password',
    gameClick: 'Game_Click',
    generalInfo: 'General_Info',
    getBonus: 'Get_Bonus',
    goStep2: 'Go_Step2',
    goStep3: 'Go_Step3',
    goStep4: 'Go_Step4',
    guide: 'Guide',
    header: 'Header',
    language: 'Language',
    liveBetting: 'Live_Betting',
    liveCasino: 'Live_Casino',
    liveChat: 'Live_Chat',
    login: 'Login',
    logo: 'Logo',
    luckyWinnerBanner: 'Lucky_Winner_Banner',
    missions: 'Missions',
    myBonuses: 'My_Bonuses',
    noBonus: 'No_Bonus',
    partners: 'Partners',
    past: 'Past',
    payments: 'Payments',
    playLimit: 'Play_limit',
    profile: 'Profile',
    promoBanner: 'Promo_Banner',
    promotions: 'Promotions',
    providers: 'Providers',
    quickDeposit: 'Quick_Deposit',
    register: 'Register',
    registerNow: 'Register_Now',
    registration: 'Registration',
    resumePlay: 'Resume_Play',
    securityAndPrivacy: 'Security_and_Privacy',
    seeAll: 'See_All',
    sideBar: 'Side_Bar',
    sitemap: 'Sitemap',
    spinRally: 'Spin_Rally',
    sport: 'Sport',
    sports: 'Sports',
    sportsbook: 'Sportsbook',
    sportsgrounds: 'Sportsgrounds',
    sportToto: 'SportToto',
    sportTournament: 'Sport_Tournament',
    support: 'Support',
    termsAndConditions: 'Terms_and_Conditions',
    tournaments: 'Tournaments',
    vipLevels: 'VIP_Levels',
    virtuals: 'Virtuals',
    virtualSports: 'Virtual_Sports',
    weeklyChallenges: 'Weekly_Challenges',
    welcomeBack: 'Welcome_Back',
    welcomeBanner: 'Welcome_Banner',
    withdrawal: 'Withdrawal',
    application: 'Application',
  },
  label: {
    // optIn: 'Opt_in',
    aboutUs: 'About_Us',
    achievements: 'Achievements',
    addictionTest: 'Addiction_test',
    allGames: 'All_Games',
    allLiveCasino: 'All_Live_Casino',
    //-arcade: 'Arcade',
    baccaratAndDice: 'Baccarat_and_Dice',
    backToRegistration: 'Back_to_Registration',
    balance: 'Balance',
    balanceHistory: 'Balance_History',
    basketball: 'Basketball',
    blackjack: 'Blackjack',
    blog: 'Blog',
    bonusCrab: 'Bonus_Crab',
    burger: 'Burger',
    cancel: 'Cancel',
    cancelWithdrawal: 'Cancel_Withdrawal',
    casinoBonus: 'Casino_Bonus',
    casino: 'Casino',
    cities: 'Cities',
    clickHere: 'Click_Here',
    clubRoyal: 'Club_Royal',
    colossus: 'Colossus',
    complaintProcedure: 'Complaint_Procedure',
    contactUs: 'Contact_Us',
    cookiePolicy: 'Cookie_Policy',
    coolOff: 'Cool_off',
    createAccount: 'Create_Account',
    deposit: 'Deposit',
    depositNow: 'Deposit_Now',
    enterAmount: 'Enter_Amount',
    faq: 'F.A.Q.',
    favourites: 'Favourites',
    football: 'Football',
    forgotPassword: 'Forgot_Password',
    gameClick: 'Game_Click',
    gameShows: 'Game_Shows',
    getBonus: 'Get_Bonus',
    guide: 'Guide',
    iceHockey: 'Ice_Hockey',
    jackpots: 'Jackpots',
    language: 'Language',
    lastPlayed: 'Last_Played',
    liveBetting: 'Live_Betting',
    liveCasino: 'Live_Casino',
    liveChat: 'Live_Chat',
    liveGames: 'Live_Games',
    login: 'Login',
    loginWithAnotherAccount: 'Login_With_Another_Account',
    logo: 'Logo',
    logOut: 'Log out',
    luckyWinnerJackpot: 'Lucky_Winner_Jackpot',
    mainBoard: 'Mainboard',
    missions: 'Missions',
    myBonuses: 'My_Bonuses',
    myInfo: 'My_Info',
    new: 'New',
    nextStep: 'Next_Step',
    noBonus: 'No_Bonus',
    optIn: 'Opt_In',
    partners: 'Partners',
    payments: 'Payments',
    placeBets: 'Place_Bets',
    playLimit: 'Play_limit',
    playNow: 'Play_Now',
    playDemo: 'Play_Demo',
    poker: 'Poker',
    popular: 'Popular',
    privacyPolicy: 'Privacy_Policy',
    profile: 'Profile',
    promocode: 'Promocode',
    promotions: 'Promotions',
    promoBannerPicture: 'Promo_Banner_Picture',
    provider: 'Provider',
    quickDeposit: 'Quick_Deposit',
    register: 'Register',
    registerNow: 'Register_Now',
    registration: 'Registration',
    repeatPayment: 'Repeat_Payment',
    responsibleGaming: 'Responsible_Gaming',
    resumePlay: 'Resume_Play',
    roulette: 'Roulette',
    search: 'Search',
    seeAllPromotions: 'See_All_Promotions',
    signIn: 'Sign_In',
    sitemap: 'Sitemap',
    slots: 'Slots',
    spinRally: 'Spin_Rally',
    sportCategory: 'SportCategory',
    sportBonus: 'Sport_Bonus',
    sportsbook: 'Sportsbook',
    sportsgrounds: 'Sportsgrounds',
    sportToto: 'SportToto',
    startPlay: 'Start_Play',
    support: 'Support',
    tableGames: 'Table_Games',
    tableTennis: 'Table_Tennis',
    tennis: 'Tennis',
    termsAndConditions: 'Terms_and_Conditions',
    top: 'Top',
    topRated: 'Top_Rated',
    tournaments: 'Tournaments',
    vipLevels: 'VIP_Levels',
    virtuals: 'Virtuals',
    virtualSports: 'Virtual_Sports',
    volleyball: 'Volleyball',
    weeklyChallenges: 'Weekly_Challenges',
    withdrawal: 'Withdrawal',
    application: 'Application',
  }
}


export {$_CONFIG};
