import '@bsc/auth-bank-id';

import '../../service/domain/modal.js';
import '../../service/domain/popup.js'
import '../../service/domain/user.js';
import '../../service/rx/notification$.js';
import '../../service/domain/state.js';
import '../../service/translations.js';
import '../../service/configs/bankid.js';
import '../../service/configs/config.js';
import '../../service/rx/bankidBtn.js';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import '../../service/localStorage.js';

class AuthBankidDirective {
  static $inject = ['$document', '$translate', 'modal', 'popup', 'user', 'notification$', 'state', 'translations', 'bankid.config', 'config', 'bankidBtn', 'localStorage'];
  constructor($document, $translate, modal, popup, user, notification$, state, translations, bankid_config, config, bankidBtn$, localStorage) {
    this.$document = $document;
    this.$translate = $translate;
    this.modal = modal;
    this.popup = popup;
    this.user = user;
    this.notification$ = notification$;
    this.state = state;
    this.translations = translations;
    this.bankid_config = bankid_config;
    this.config = config;
    this.bankidBtn$ = bankidBtn$;
    this.localStorage = localStorage;
  }
  link($scope, element) {
    const translationsPrefix = 'auth_bankid.';
    const htmlDocument = this.$document[0];
    const translations$ = new ReplaySubject(1);
    const destroy$ = new Subject();

    const openErrorModal = (errors) => {
      const error = errors?.filter(data => this.bankid_config.errorCodes.includes(data.code));
      if(!error.length) return;

      bankIdElement.closeAll();
      this.modal.open('error', { code: error[0].code });
    }

    $scope.locale = this.config.currentLang === 'en' ? 'en_SE' : this.bankid_config.locale;

    combineLatest([this.bankidBtn$, translations$])
      .pipe(
        takeUntil(destroy$)
      )
      .subscribe(()=>{
        bankIdElement.openDialog();
      })

    this.translations.getTranslationsByPrefix(translationsPrefix).then(translations => {
      element[0].setAttribute("translations", JSON.stringify(translations));
      element[0].setAttribute("affiliate", this.localStorage.get('params') || '{}');

      translations$.next(translations);
    });

    htmlDocument.addEventListener('dialogopen', ()=> {
      htmlDocument.documentElement.classList.add('bsc-has-popup');
    });
    htmlDocument.addEventListener('dialogclosed', ()=> {
      htmlDocument.documentElement.classList.remove('bsc-has-popup');
      this.popup.close();
    });
    htmlDocument.addEventListener('successlogin', ({detail})=> {
      this.user.profile = detail;
      this.notification$.next({
        type: 'success',
        code: 0,
        text: this.$translate.instant('login_successful'),
      });
    });
    htmlDocument.addEventListener('showregistration', ({detail})=> {
        this.modal.open('bankid-registration', {
          profile: detail.profile,
          is_registration_bonuses_shown: detail.is_registration_bonuses_shown,
          token: detail.order_id,
        }).then(() => {
          this.state.goto('home');
        })
    });
    htmlDocument.addEventListener('autherror', ({ detail }) => {
      openErrorModal(detail);
    });

    $scope.$on('$destroy', () => {
      translations$.unsubscribe();
      destroy$.unsubscribe();
    });
  }
}

app.directive('authBankid', () => {
  return {
    restrict: 'A',
    controller: AuthBankidDirective,
    link: function (scope, element, attrs, ctrl) {
      ctrl.link(scope, element, attrs, ctrl);
    },
  };
});
