import '../../../service/domain/documentVerification.js';
import '../../../service/domain/documentVerification.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaDocumentVerificationPopup' };

  controller.$inject = ['$scope', 'documentVerification', 'verificationInfo$'];

  function controller($scope, _documentVerification, _verificationInfo$) {
    $scope.files = [];
    $scope.preloader = false;
    $scope.errorData = false;
    $scope.disabledForm = false;
    $scope.success = false;
    $scope.errorMessage = null;

    const fileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    const maxSize = 5242880;

    $scope.addFiles = (files) => {
      if ($scope.success) {
        return;
      }
      if (files?.length) {
        for (let i = 0; i < files.length; i++) {
          checkFile(files[i]);
        }
      }
      return;
    };

    const checkErrorsInFiles = () => {
      checkFileListSize();

      return $scope.files.some((arrayFile) => {
        if (Object.keys(arrayFile.errors).length !== 0) {
          return true;
        }
        return false;
      });
    };

    const checkFile = (file) => {
      if (!file || file?.length === 0) {
        return;
      }
      file.errors = {};
      if (checkExtension(file)) {
        Object.assign(file.errors, { extension: true });
      }

      if (checkSize(file)) {
        Object.assign(file.errors, { size: true });
      }

      if ($scope.files.length > 0 && checkDuplicate(file)) {
        Object.assign(file.errors, { duplicate: true });
      }

      $scope.files.push(file);
      $scope.disabledForm = checkErrorsInFiles();
    };

    const checkFileListSize = () => {
      $scope.files.forEach((item) => {
        delete item.errors.filelistsize;
      });
      if ($scope.files.length > 5) {
        for (let i = 5; i < $scope.files.length; i++) {
          Object.assign($scope.files[i].errors, { filelistsize: true });
        }
      }
    };

    const checkDuplicate = (file) => {
      return $scope.files.some((arrayFile) => {
        if (file.size === arrayFile.size && file.name === arrayFile.name) {
          return true;
        }
        return false;
      });
    };

    const checkExtension = (file) => {
      return fileTypes.indexOf(file.type) === -1;
    };

    const checkSize = (file) => {
      return file.size > maxSize;
    };

    const subscribe = _verificationInfo$
      .pipe(filter((item) => item.event === 'removeItem'))
      .subscribe((item) => {
        removeItem(item.data);
      });

    const removeItem = (item) => {
      $scope.files.splice(item, 1);
      $scope.disabledForm = checkErrorsInFiles();
    };

    $scope.sendData = (id) => {
      $scope.preloader = true;

      _documentVerification.setDocuments.upload
        .file($scope.files, id)
        .then((answer) => {
          if (answer === 'true') {
            $scope.success = true;
            _verificationInfo$.next({
              event: 'moveStatus',
              data: id,
            });
            return;
          }
          $scope.errorData = true;
          return;
        })
        .catch((error) => {
          $scope.errorData = true;
          $scope.errorMessage = error;
          return;
        })
        .finally(function () {
          $scope.preloader = false;
        });
    };

    $scope.$on('updateFile', function (event, data) {
      $scope.addFiles(data);
    });

    this.$onChanges = () => {
      $scope.addFiles(this.files);
    };

    this.$onDestroy = () => {
      subscribe.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      files: '<',
    },
  });
})();
