import '../../../service/domain/documentVerification.js';
(function () {
  const component = { name: 'lindaDocumentVerificationItem' };

  controller.$inject = ['$scope', 'verificationInfo$', '$timeout'];

  function controller($scope, _verificationInfo$, $timeout) {
    $scope.item = {};
    $scope.itemid = null;
    $scope.itemError = false;

    $scope.remove = () => {
      _verificationInfo$.next({
        event: 'removeItem',
        data: $scope.itemid,
      });
    };

    this.$onChanges = () => {
      $scope.item = this.item;
      $scope.itemid = this.itemid;
      if (Object.keys(this.item.errors).length !== 0) {
        $scope.itemError = true;
      }
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      item: '<',
      itemid: '<',
    },
  });
})();
