import contentBuffer from '../../app/service/domain/content-buffer.js';

(function(){

    'use strict';

    controller.$inject = [ 'contentBuffer', '$state' ];

    function controller( _contentBuffer, $state ){

        function link( scope, element, attrs ) {

            const { tab = '0' } = scope.$eval(attrs.betiniaSetGuideFilter);

            const handleClick = () => {
                if ($state.current.name === 'app.root.l1.guide') {
                    return;
                }

                _contentBuffer.write( 'guideFilter', { tab } );
            }

            element[0].addEventListener( 'click' , handleClick );

            scope.$onDestroy = () => {
                element[0].removeEventListener( 'click', handleClick);
            };
        }

        return {
            restrict: 'A',
            link:link
        };

    }

    app.directive( 'betiniaSetGuideFilter', controller );

})();

