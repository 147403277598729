import user from '../../app/service/domain/user.js';
import state from '../../app/service/domain/state.js';

(function () {
    'use strict';

    const directive = { name: 'betiniaRedirectFromPromo' };

    controller.$inject = ['user', 'state'];

    function controller(_user, _state){
        function link(){
            if( (_user.profile.profile.is_fdb_made === true && _user.profile.active_bonus === 0) || (_user.profile.is_registration_bonuses_shown && !_user.profile.active_bonus) ) {
                _state.goto('home')
            }
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
