(function () {
    'use strict';

    const directive = { name: 'inputBankId' };

    controller.$inject = ['$compile'];

    function controller(compile){
        function link( scope, element, attrs, ngModel ){
            const addSeparator = value => {
                if (typeof(value) == typeof(undefined)) {
                    return value;
                }

                return value.toString()
                            .replace(/\D+/g, '')
                            .replace(/(.{8})/g, '$1-')
                            .substring(0,13);
            }
      
            const clearModel = value => {
                if (typeof(value) == typeof(undefined)) {
                    return value;
                }

                return value.toString()
                            .replace(/\s/g, '')
                            .replace(/-/g, '');
            }
      
            const parseViewValue = value => {
                const viewValue = addSeparator(value);

                ngModel.$viewValue = viewValue;
                ngModel.$render();

                updateShadowInput(value);

                return clearModel(viewValue);
            }
      
            const formatModelValue = value => {
                const modelValue = clearModel(value);

                ngModel.$modelValue = modelValue;

                return addSeparator(modelValue);
            }

            const changeInputStyles = (transparent = false) => {
                if (transparent) {
                    element[0].style.position = 'relative';
                    element[0].style.zIndex = 2;
                    element[0].style.background = 'transparent';
                    element[0].classList.add('is-placeholder-hidden');
                } else {
                    element[0].classList.remove('is-placeholder-hidden');
                    element[0].style = scope.originalStyles;
                }
            }

            const createShadowInput = () => {
                if (!scope.shadowInput || !scope.shadowInput.length) {
                    changeInputStyles(true);

                    scope.shadowInput = angular.element('<input class="form-input ng-touched shadow-input">')
                    scope.shadowInput[0].style.position = 'absolute';
                    scope.shadowInput[0].style.zIndex = '1';
                    scope.shadowInput[0].style.color = 'lightgray';
                    scope.shadowInput[0].value = setShadowInputValue();

                    compile(scope.shadowInput)(scope);

                    element.before(scope.shadowInput);
                }
            }

            const removeShadowInput = () => {
                changeInputStyles();

                scope.shadowInput[0].remove();
                scope.shadowInput = null;
            }

            const updateShadowInput = value => {
                const l = value.length;
                scope.shadowInput[0].value = l < 13
                    ? value + element[0].placeholder.substring(l, element[0].placeholder.length)
                    : value + scope.shadowInput[0].value.substring(l, scope.shadowInput[0].value.length);
            }

            const setShadowInputValue = () => {
                const l = element[0].value.length;
                const pl = element[0].placeholder.length;

                return l < 13
                    ? element[0].value + element[0].placeholder.substring(l, pl) 
                    : element[0].value || element[0].placeholder;
            }

            element.bind('focus', createShadowInput);
            element.bind('blur', removeShadowInput);
            
            scope.originalStyles = element[0].style;
            scope.originalPlaceholder = element[0].placeholder;

            ngModel.$parsers.push(parseViewValue);
            ngModel.$formatters.push(formatModelValue);

            const bankIdValidation = value => {
                const pattern = /^\d+$/;

                if (value.length === 12 && pattern.test(value)) {
                    ngModel.$setValidity('bankId', true);
                    scope.shadowInput[0].style.color = 'transparent';
                } else {
                    ngModel.$setValidity('bankId', false);
                    scope.shadowInput[0].style.color = 'lightgray';
                }
                return value;
              }
            ngModel.$parsers.push(bankIdValidation);
        }

        return {
            restrict: 'EA',
            require: 'ngModel',
            link
        };
    }

    app.directive( directive.name, controller );

})();