import {gsap} from 'gsap';
import ScrollTrigger from '../../app/libs/gsap/ScrollTrigger.min.js';

(function () {

    'use strict';

    const directive = { name: 'betiniaToggleLoginBtnColor' };

    function controller(){

        function link( scope, element, attrs ){

            gsap.registerPlugin(ScrollTrigger);

            let header = document.querySelector('.header');

            header.classList.add('has-btn-outline');

            scope.scr = ScrollTrigger.create({
                trigger: element,
                start: "top top+=62px",
                end: "bottom top+=62px",
                onLeave: () => {header.classList.remove('has-btn-outline')},
                onLeaveBack:  () => {header.classList.add('has-btn-outline')}
            });
            scope.$on('$destroy', () => {
                scope.scr.kill(false);
                header.classList.remove('has-btn-outline')
            });

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
