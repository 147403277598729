import user from '../../app/service/domain/user.js';
import state from '../../app/service/domain/state.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = {
    name: 'goToHomepageAfterLogin'
  };

  controller.$inject = ['system$', 'state'];

  function controller(_system, _state){
    function link(scope){
      const subscription = _system
        .pipe(
          filter(({ action }) => action === 'login')
        )
        .subscribe(() => {
          _state.goto('home')
        });

      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(
    directive.name,
    controller
  );

})();
