
(function () {
    'use strict';

    const directive = { name: 'startFireworks' };

    function controller(){
        function link( scope, element ){
            const show = () => {
                element.attr('style', 'display: block');
            };

            const hide = () => {
                element.removeAttr('style');
                scope.playground?.lightVideo?.removeEventListener('ended', () => show());
                scope.playground.openVideo?.removeEventListener('ended', () => show());
                scope.playground.closeVideo?.removeEventListener('play', () => hide());
            }

            scope.$watch( 'playground', val => {
                if (val.status === 'opened' && val.hasOwnProperty('openVideo') && val.hasOwnProperty('closeVideo')) {
                    scope.playground.openVideo.addEventListener('ended', () => show());
                    scope.playground.closeVideo.addEventListener('play', () => hide());
                } else {
                    hide();
                }
            } );

            scope.$watch( 'playground.lightVideo', val => {
                if (val) {
                    scope.playground.lightVideo.addEventListener('ended', () => show());
                    scope.playground.closeVideo.addEventListener('play', () => hide());
                }
            });
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
