(function () {
  'use strict';

  // the directive is used as an example on old guide page
  // we need to implement it into new html/css developing new guide page
  const directive = { name: 'betiniaLazyBg' };

  controller.$inject = [];

  function controller() {
      function link(scope, element) {

          const callBack = ([entry]) => {
              if (entry.isIntersecting) {
                  const imgPath = element[0].getAttribute('data-style');
                  element[0].style = imgPath;
                  unobserveIntersection();
              }
          };

          const options = {
              //-should leave 0% bacause there is an error in console (DOMException: Failed to construct 'IntersectionObserver': rootMargin must be specified in pixels or percent.)
              rootMargin: '0% 0% 20% 0%',
          }

          const intersectionObserver = new IntersectionObserver(callBack, options);

          intersectionObserver.observe(element[0]);

          function unobserveIntersection() {
              intersectionObserver.disconnect();
          }
      }

      return {
          restrict: 'A',
          link,
      };
  }

  app.directive(directive.name, controller);
})();
